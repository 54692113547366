import React, { useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  IFreeItem,
  IPrProduct,
  IPrProductStatus,
  IPrProductStatusType,
  IPurchaseRequisition,
  TActionsAdminPr,
  TActionsPr,
} from "src/types/pr.types";
import { cloneDeep } from "lodash";
import * as FDN from "src/core";
import InputNumber from "./InputNumber";
import useServiceCore from "src/services/CoreService";
import PrApi from "src/api/PrApi";
import StatusCode from "src/config/statuscodes";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type AvailableDropdownMenuItems = "NOT_IN_STOCK" | "QUALITY" | "SHORTAGE";

interface IPrProductDropdownMenuProps {
  pr: IPurchaseRequisition;
  product: IPrProduct | IFreeItem;
  productName: string;
  location: "client" | "admin";
  menuItems: AvailableDropdownMenuItems[];
  children: React.ReactNode;
}

const PrProductDropdownMenu = ({
  pr,
  product,
  productName,
  location,
  menuItems,
  children,
}: IPrProductDropdownMenuProps) => {
  const [editProduct, setEditProduct] = React.useState<IPrProduct | IFreeItem>(cloneDeep(product));
  const [prProductStatus, setPrProductStatus] = React.useState<IPrProductStatusType | undefined>();

  const { NOTIFICATIONS, api } = useServiceCore();

  const maxAmounts = {
    NOT_IN_STOCK: product.amount,
    QUALITY: product.perfectAmount,
    SHORTAGE: product.perfectAmount,
  };

  useEffect(() => {
    if (product)
      setEditProduct({
        ...editProduct,
        status: product.status ? product.status : {},
      });
  }, [product]);

  const onProductStatusAmountChange = (amount: number | undefined | null) => {
    if (!editProduct || !prProductStatus) return;

    if (!amount) amount = 0;

    const prStatus = editProduct.status || ({} as IPrProductStatus);
    prStatus[prProductStatus] = amount;

    setEditProduct({
      ...editProduct,
      status: prStatus,
    });
  };

  const onCancel = () => {
    setEditProduct(cloneDeep(product));
    setPrProductStatus(undefined);
  };

  const onSave = () => {
    if (!editProduct || !prProductStatus) return;

    const doSave = () => {
      if (!editProduct || !editProduct.status) return;

      NOTIFICATIONS.showSaving({ type: "save" });
      NOTIFICATIONS.hideDialog();

      PrApi.prUpdateProductStatus(
        api,
        pr.clientIdentifier,
        pr.identifier,
        editProduct.identifier,
        prProductStatus,
        editProduct.status[prProductStatus] || 0
      ).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          window.location.reload();
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t(`pr.product.dropdownmenu.items.${prProductStatus}.confirm`),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            onCancel();
          },
        },
        {
          type: "ok",
          onClick: () => {
            doSave();
          },
        },
      ],
    });
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center">{children}</Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {menuItems.includes("NOT_IN_STOCK") && (
              <Menu.Item>
                {({ active }) => {
                  const menuItemClasses = classNames(
                    active
                      ? "block w-full cursor-pointer text-left bg-gray-100 text-gray-900"
                      : "text-gray-700",
                    "block w-full cursor-pointer text-left px-4 py-2 text-sm"
                  );

                  return (
                    <button
                      className={menuItemClasses}
                      onClick={() => setPrProductStatus("NOT_IN_STOCK")}
                    >
                      {FDN.I18n.t("pr.product.dropdownmenu.items.NOT_IN_STOCK.label")}
                    </button>
                  );
                }}
              </Menu.Item>
            )}
            {menuItems.includes("SHORTAGE") && (
              <Menu.Item>
                {({ active }) => {
                  const menuItemClasses = classNames(
                    active
                      ? "block w-full cursor-pointer text-left bg-gray-100 text-gray-900"
                      : "text-gray-700",
                    "block w-full cursor-pointer text-left px-4 py-2 text-sm"
                  );

                  return (
                    <button
                      className={menuItemClasses}
                      onClick={() => setPrProductStatus("SHORTAGE")}
                    >
                      {FDN.I18n.t("pr.product.dropdownmenu.items.SHORTAGE.label")}
                    </button>
                  );
                }}
              </Menu.Item>
            )}
            {menuItems.includes("QUALITY") && (
              <Menu.Item>
                {({ active }) => {
                  const menuItemClasses = classNames(
                    active
                      ? "block w-full cursor-pointer text-left bg-gray-100 text-gray-900"
                      : "text-gray-700",
                    "block w-full cursor-pointer text-left px-4 py-2 text-sm"
                  );

                  return (
                    <button
                      className={menuItemClasses}
                      onClick={() => setPrProductStatus("QUALITY")}
                    >
                      {FDN.I18n.t("pr.product.dropdownmenu.items.QUALITY.label")}
                    </button>
                  );
                }}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
      {prProductStatus && (
        <FDN.Popup
          size="small"
          title={`${FDN.I18n.t(
            `pr.product.dropdownmenu.items.${prProductStatus}.label`
          )}: ${productName}`}
        >
          <p>{FDN.I18n.t(`pr.product.dropdownmenu.items.${prProductStatus}.text`)}</p>

          <div className="w-full md:w-1/3 md:mx-auto">
            <InputNumber
              value={editProduct.status ? editProduct.status[prProductStatus] || 0 : 0}
              min={0}
              max={maxAmounts[prProductStatus]}
              onUpdate={(value) => onProductStatusAmountChange(value)}
            />
          </div>
          <div className="mt-4">
            <FDN.FormButtons smallButtons onSave={onSave} onCancel={onCancel} />
          </div>
        </FDN.Popup>
      )}
    </>
  );
};

export default PrProductDropdownMenu;
