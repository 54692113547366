import * as React from "react";
import * as FDN from "src/core";
import {
  IPrProductStatusType,
  IPurchaseRequisition,
  PrFormVisibleColumns,
  TActionsPr,
} from "src/types/pr.types";
import PrPdfFormFreeItemsList from "./PrPdfFormFreeItemsList";

interface IPrPdfFreeItemsProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrPdfFreeItems: React.FunctionComponent<IPrPdfFreeItemsProps> = ({
  showOnlyStatus,
  pr,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-freeitems">
      <FDN.Box noPadding>
        <PrPdfFormFreeItemsList
          showOnlyStatus={showOnlyStatus}
          freeItems={actions.getFreeItemsForList(showOnlyStatus)}
          pr={pr}
          hideHead={true}
          showSupplier={showSupplier}
        />
      </FDN.Box>
    </div>
  );
};

export default PrPdfFreeItems;
