import React from "react";
import { IFreeItem } from "src/types/pr.types";
import FreeItemImagesPopup from "./FreeItemImagesPopup";
import { Icon } from "@tschirpke/ptfdn-frontend-components";

interface FreeItemHasImageIconProps {
  freeItem: IFreeItem;
}

const FreeItemHasImageIcon = ({ freeItem }: FreeItemHasImageIconProps) => {
  const [popupIsVisible, setPopupIsVisible] = React.useState<boolean>(false);

  if (!freeItem.files || freeItem.files.length === 0) return null;

  return (
    <>
      <button
        className="inline-block pl-1 cursor-pointer duration-200 hover:scale-110"
        onClick={() => setPopupIsVisible(true)}
      >
        <Icon icon="picture-o" />
      </button>
      {popupIsVisible && <FreeItemImagesPopup freeItem={freeItem} onClose={() => setPopupIsVisible(false)} />}
    </>
  );
};

export default FreeItemHasImageIcon;
