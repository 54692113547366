import React, { useEffect, useState } from "react";
import * as FDN from "src/core";
import { TActions, IUserSettingsState } from "src/types/types";
import useServiceCore from "./CoreService";
import StatusCode from "src/config/statuscodes";
import UserApi from "src/api/UserApi";
import { IClients } from "src/types/clients.types";
import { cloneDeep } from "lodash";

const initTabs: { [key: string]: FDN.ITabsTab } = {
  // general: {
  //   label: FDN.I18n.t("usersettings.tabs.general.label"),
  //   icon: "user",
  // },
  // privacy: {
  //   label: FDN.I18n.t("usersettings.tabs.privacy.label"),
  //   icon: "key",
  // },
  security: {
    label: FDN.I18n.t("usersettings.tabs.security.label"),
    icon: "shield",
  },
};

const defaultTab = "security";

const useServiceMySettings = () => {
  const [userSettings, setUserSettings] = React.useState<IUserSettingsState>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const [tabs, setTabs] = useState<FDN.ITabs>(initTabs);
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const { APP, api } = useServiceCore();
  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!user || !user.clients || !user.identifier) return;

    const userClients = user.clients as IClients;

    const updatedTabs = cloneDeep(initTabs);

    // for (const userClient of userClients) {
    //   if (userClient.managerIdentifiers?.includes(user.identifier))
    //     updatedTabs[`client_${userClient.identifier}`] = {
    //       label: userClient.name,
    //       icon: "cog",
    //     };
    // }

    setTabs(updatedTabs);
  }, [user?.clients]);

  const init = () => {
    UserApi.myAccount(api).then((response) => {
      APP.setPageTitle(FDN.I18n.t("usersettings.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setUserSettings(response?.body?.userSettings as IUserSettingsState);
      }
    });
  };

  const onEdit = (property: string, value: any): void => {
    setUserSettings({ ...userSettings, [property]: value });
    setUnsavedChanges(true);
  };

  const actions: TActions = {
    onEdit,
    setSelectedTab,
  };

  return { actions, userSettings, unsavedChanges, tabs, selectedTab };
};

export default useServiceMySettings;
