import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { SearchSort } from "src/types/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { TActionsAdminProducts } from "src/services/admin/AdminProductsService";
import Config from "src/core/Config";
import { IProducts, ISuppliers } from "src/types/products.types";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 4 },
    textAlign: "right",
    content: FDN.I18n.t("adminProducts.list.head.productId.title"),
  },
  {
    size: { md: 3 },
    textAlign: "right",
    content: FDN.I18n.t("adminProducts.list.head.unit.title"),
  },
  {
    size: {
      md: 7,
    },
    content: FDN.I18n.t("adminProducts.list.head.name.title"),
  },
  {
    size: {
      md: 7,
    },
    content: FDN.I18n.t("adminProducts.list.head.category.title"),
  },
  {
    size: { md: 3 },
    textAlign: "right",
    content: FDN.I18n.t("adminProducts.list.head.priceUnit.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (products: IProducts, actions: TActionsAdminProducts) => {
  if (!products) return;

  const currency = Config.get("currency") as string;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const product of products) {
    if (!product) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => actions.showPopupEditProduct(product);

    row.columns[0].content = <>{product.productId}</>;

    row.columns[1].content = (
      <>
        {product.amount} {product.unit}
      </>
    );

    row.columns[2].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">{product.name}</div>
          {product.description && (
            <div className="__adminlist-name-subtitle">{product.description}</div>
          )}
          {product.suppliers && product.suppliers[0] ? (
            <div className="__adminlist-name-subtitle">
              <FDN.Icon icon="truck" /> {product.suppliers[0].name}
            </div>
          ) : null}
        </div>
      </>
    );

    row.columns[3].content = (
      <>
        <div className="__adminlist-category">{product.categoryName}</div>
      </>
    );

    row.columns[4].content = (
      <div style={{ whiteSpace: "nowrap" }}>
        {product.priceIsVariable ? (
          <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
            <span style={{ cursor: "help" }}>
              {currency}
              {FDN.formatNumber(product.priceUnit || 0)} *
            </span>
          </FDN.ToolTip>
        ) : (
          <>
            {currency}
            {FDN.formatNumber(product.priceUnit || 0)}
          </>
        )}
      </div>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IProductsListProps {
  products: IProducts;
  actions: TActionsAdminProducts;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const ProductsList: React.FunctionComponent<IProductsListProps> = ({
  products,
  actions,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (products) {
      setAdminListSchema(buildAdminList(products as IProducts, actions));
      setListLoading(false);
    }
  }, [products]);

  if (!products) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default ProductsList;
