import * as React from "react";
import * as FDN from "src/core";
import MyAccountHeader from "./myaccount/MyAccountHeader";
import TabSecurity from "./usersettings/TabSecurity";
import useServiceMySettings from "src/services/MySettingsService";
import { useNavigate } from "react-router-dom";

const MySettingsPage: React.FunctionComponent = () => {
  const { actions, userSettings, tabs, selectedTab } = useServiceMySettings();

  const navigate = useNavigate();

  if (!userSettings) return <FDN.Loading />;

  return (
    <FDN.LayoutWithTopbar page="myaccount">
      <FDN.PageContent>
        <MyAccountHeader page="settings" />

        <FDN.Tabs
          tabs={tabs}
          editMode={true}
          selected={selectedTab}
          onSelect={(tabKey) => actions.setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
        <FDN.TabsContent>
          {selectedTab === "security" && (
            <TabSecurity userSettings={userSettings} actions={actions} />
          )}
        </FDN.TabsContent>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default MySettingsPage;
