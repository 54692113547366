import React, { useCallback } from "react";
import * as FDN from "src/core";
import { useDropzone, Accept } from "react-dropzone";
import { FileUploadFile } from "./types";
import { TActions } from "src/types/types";

interface IImageDropArea {
  type: "single" | "multi" | "freeitems";
  maxFiles?: number;
  accept?: Accept;
  actions: TActions;
}

const ImageDropArea: React.FunctionComponent<IImageDropArea> = ({ type, maxFiles, accept, actions }) => {
  if (!maxFiles || type === "single") maxFiles = 1;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const selectedFiles: FileUploadFile[] = [];

    for (const acceptedFile of acceptedFiles) {
      const selectedFile: FileUploadFile = {
        name: acceptedFile.name,
        size: acceptedFile.size,
        mime: acceptedFile.type,
        original: acceptedFile,
      };
      selectedFiles.push(selectedFile);
    }

    actions.onSelectFiles(selectedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    accept,
  });

  if (type === "freeitems")
    return (
      <div
        className="h-full mb-4 flex justify-center items-center border border-dashed border-gray-400 text-gray-400 text-xl rounded-lg cursor-pointer hover:border-primary hover:text-primary"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <FDN.Icon icon="plus-circle" />
      </div>
    );

  return (
    <div className="__imageupload-droparea" {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="__imageupload-droparea-icon">
        <FDN.Icon icon="upload" />
      </div>
      <div className="__imageupload-droparea-text">{FDN.I18n.t(`imageupload.droparea.${type}.text`)}</div>
    </div>
  );
};

export default ImageDropArea;
