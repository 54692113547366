import * as React from "react";
import * as FDN from "src/core";
import { getAddressInMultipleLine } from "src/core/helpers/helpers";
import { IClient } from "src/types/clients.types";

interface IPrClientAddressProps {
  client: IClient;
}

const PrClientAddress: React.FunctionComponent<IPrClientAddressProps> = ({ client }) => {
  return (
    <div className="__prs-clientaddress">
      <strong>{client.name}</strong>
      <br />
      <span dangerouslySetInnerHTML={{ __html: getAddressInMultipleLine(client) }} />
      {client.clientNr ? (
        <>
          <br />
          <br />
          {FDN.I18n.t("pr.clientNr")}:{" "}
          <strong className="__prs-clientaddress-clientnr">{client.clientNr}</strong>
        </>
      ) : null}
    </div>
  );
};

export default PrClientAddress;
