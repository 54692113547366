import * as React from "react";
import * as FDN from "src/core";

interface IPrLayoutProps {
  children: React.ReactNode;
}

const PrLayout: React.FunctionComponent<IPrLayoutProps> = ({ children }) => {
  return <div className="__prs">{children}</div>;
};

export default PrLayout;
