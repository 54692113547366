import * as React from "react";
import { useDropzone } from "react-dropzone";
import Api from "src/api/Api";
import * as FDN from "src/core";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import useServiceAdminProductsCsv, {
  TActionsAdminProductsCsv,
} from "src/services/admin/AdminProductsCsvService";
import { IProductCategories, IProducts } from "src/types/products.types";

const columnLengths: { [key: string]: number } = {
  Name: 4,
  Beschreibung: 6,
  Kategorie: 6,
  Menge: 2,
  Einheit: 2,
  Einzelpreis: 2,
};

interface ICsvPopupProps {
  categories: IProductCategories;
  onClose: () => void;
}

const CsvPopup: React.FunctionComponent<ICsvPopupProps> = ({ categories, onClose }) => {
  const { view, csvData, actions } = useServiceAdminProductsCsv(categories);

  if (!actions) return <FDN.Loading box />;

  return (
    <div className="__products-csv-import">
      <FDN.Popup
        size="medium"
        title={FDN.I18n.t("adminProducts.csv.upload.title")}
        onClose={onClose}
      >
        {view === "finished" ? <ViewFinished actions={actions} /> : null}
        {view === "upload" ? <ViewUpload actions={actions} /> : null}
        {view === "list" ? (
          <ViewList csvData={csvData} categories={categories} actions={actions} />
        ) : null}
      </FDN.Popup>
    </div>
  );
};

interface IViewFinishedProps {
  actions: TActionsAdminProductsCsv;
}

const ViewFinished: React.FunctionComponent<IViewFinishedProps> = ({ actions }) => {
  return (
    <div className="view-finished">
      <FDN.CalloutBox type="success">
        <div>
          <strong>{FDN.I18n.t("adminProducts.css.list.onImport.success.title")}</strong>
        </div>
        <p>{FDN.I18n.t("adminProducts.css.list.onImport.success.text")}</p>
        <div className="text-center">
          <a href={UrlService.url("admin.products.index")} className="small primary hollow button">
            <FDN.Icon icon="refresh" left />{" "}
            {FDN.I18n.t("adminProducts.css.list.onImport.success.button.label")}
          </a>
        </div>
      </FDN.CalloutBox>
    </div>
  );
};

interface IViewUploadProps {
  actions: TActionsAdminProductsCsv;
}

const ViewUpload: React.FunctionComponent<IViewUploadProps> = ({ actions }) => {
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];

    if (selectedFile) {
      actions.onSelectFile(selectedFile);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "text/csv": ["csv"] },
  });

  return (
    <div className="view-upload">
      <div className="__imageupload-droparea" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="__imageupload-droparea-icon">
          <FDN.Icon icon="upload" />
        </div>
        <div className="__imageupload-droparea-text">
          {FDN.I18n.t(`adminProducts.csv.upload.droparea.text`)}
        </div>
      </div>
      <div className="text-right" style={{ marginTop: "20px" }}>
        <a
          href={"https://smabuy.app/MusterCSV.csv"}
          rel="noopener noreferrer"
          target="_blank"
          className="small primary button"
        >
          <FDN.Icon icon="download" left /> {FDN.I18n.t("adminProducts.csv.upload.downloadSample")}
        </a>
      </div>
    </div>
  );
};

interface IViewListProps {
  csvData?: IProducts;
  categories: IProductCategories;
  actions: TActionsAdminProductsCsv;
}

const ViewList: React.FunctionComponent<IViewListProps> = ({ csvData, categories, actions }) => {
  if (!csvData || csvData.length === 0) return <FDN.Loading box />;

  return (
    <div className="view-list">
      <p className="lead">{FDN.I18n.t("adminProducts.csv.list.intro")}</p>
      <div className="list-head hide-for-small-only">
        <FDN.Grid full>
          <FDN.Row>
            <FDN.Cell md={4}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.name.label")}
              </div>
            </FDN.Cell>
            <FDN.Cell md={6}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.description.label")}
              </div>
            </FDN.Cell>
            <FDN.Cell md={6}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.category.label")}
              </div>
            </FDN.Cell>
            <FDN.Cell md={2}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.amount.label")}
              </div>
            </FDN.Cell>
            <FDN.Cell md={2}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.unit.label")}
              </div>
            </FDN.Cell>
            <FDN.Cell md={2}>
              <div className="list-body-entry">
                {FDN.I18n.t("adminProducts.csv.list.head.priceUnit.label")}
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </div>
      <div className="list-body">
        <FDN.Grid full>
          {csvData.map((product, index) => {
            const category = categories.find((c) => c.identifier === product.categoryIdentifier);

            return (
              <FDN.Row key={index}>
                <FDN.Cell sm={24} md={4}>
                  <div className="list-body-entry">{product.name}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={6}>
                  <div className="list-body-entry">{product.description}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={6}>
                  <div className="list-body-entry">
                    <select
                      value={category ? category.identifier : "NONE"}
                      onChange={(e) => {
                        actions.updateCategory(index, e.target.value);
                      }}
                    >
                      <option value="NONE"></option>
                      {categories.map((cat) => {
                        return (
                          <option value={cat.identifier} key={cat.identifier}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={2} className="text-right">
                  <div className="list-body-entry">{product.amount}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={2}>
                  <div className="list-body-entry">{product.unit}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={2} className="text-right">
                  <div className="list-body-entry">
                    {Config.get("currency") as string}
                    {FDN.formatNumber(product.priceUnit as number, 2, 2)}
                  </div>
                </FDN.Cell>
              </FDN.Row>
            );
          })}
        </FDN.Grid>
      </div>
      <div>&nbsp;</div>
      <FDN.FormButtons
        onCancel={actions.reset}
        cancelLabel={FDN.I18n.t("adminProducts.csv.list.buttons.onCancel.label")}
        cancelIcon="angle-left"
        saveDisabled={!actions.importIsReady()}
        onSave={actions.onImport}
        saveLabel={FDN.I18n.t("adminProducts.csv.list.buttons.onSave.label", {
          number: csvData.length,
        })}
      />
    </div>
  );
};

export default CsvPopup;
