import React from "react";
import { TActions, IUserroleState, IUserrolesState } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { cloneDeep } from "lodash";

const useServiceAdminUserrolesService = () => {
  const [virginUserrole, setVirginUserrole] = React.useState<IUserroleState>();
  const [userroles, setUserroles] = React.useState<IUserrolesState>();

  const { APP, api } = useServiceCore();

  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminUserroles(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setUserroles(response?.body?.userroles as IUserrolesState);
        setVirginUserrole(response?.body?.virginUserRole as IUserroleState);
      }
    });
  };

  const refreshUserroles = (userroles: IUserrolesState | null) => {
    setUserroles(cloneDeep(userroles));
  };

  const actions: TActions = {
    refreshUserroles,
  };

  return { actions, user, userroles, virginUserrole };
};

export default useServiceAdminUserrolesService;
