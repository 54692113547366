import { Avatar } from "@tschirpke/ptfdn-frontend-components";
import * as React from "react";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { TActionsAdminClients } from "src/services/admin/AdminClientsService";
import { IClient, IClientCategorySettingOptions, IClients } from "src/types/clients.types";
import EmailValidator from "email-validator";
import { cloneDeep } from "lodash";
import { IProductCategories, IProductCategory } from "src/types/products.types";
import Config from "src/core/Config";

const initTabs: FDN.ITabs = {
  info: {
    label: FDN.I18n.t("adminClients.popup.client.tabs.info.label"),
    icon: "id-card-o",
  },
  users: {
    label: FDN.I18n.t("adminClients.popup.client.tabs.users.label"),
    icon: "users",
  },
  products: {
    label: FDN.I18n.t("adminClients.popup.client.tabs.products.label"),
    icon: "cubes",
  },
};

interface IPopupClientEditProps {
  client: IClient;
  clients?: IClients;
  missingFields?: string[];
  location: "admin" | "client";
  categories?: IProductCategories;
  selectedCategory?: IProductCategory;
  actions: TActionsAdminClients;
}

const defaultTab = "info";

const PopupClientEdit: React.FunctionComponent<IPopupClientEditProps> = ({
  client,
  clients,
  missingFields,
  location,
  categories,
  selectedCategory,
  actions,
}) => {
  const [tabs, setTabs] = React.useState<FDN.ITabs>();
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const [view, setView] = React.useState<"default" | "adduser">("default");

  React.useEffect(() => {
    const updatedTabs = cloneDeep(initTabs);

    if (location === "client") {
      delete updatedTabs.products;

      setTabs(updatedTabs);
      setSelectedTab(defaultTab);
    } else if (location === "admin") {
      setTabs(updatedTabs);
      setSelectedTab(defaultTab);
    }
  }, [location]);

  if (!tabs) return null;

  return (
    <div className="__admin-clients-popup-edit-client">
      <FDN.Popup
        noPadding={view === "adduser"}
        size={view === "adduser" ? "small" : "medium"}
        title={
          client.identifier === "new"
            ? FDN.I18n.t("adminClients.popup.client.title.new")
            : client.name
        }
        onClose={actions.hidePopupEdit}
      >
        {view === "adduser" ? (
          <ViewAddUser client={client} setView={setView} actions={actions} />
        ) : (
          <>
            <FDN.Tabs
              selected={selectedTab}
              tabs={tabs}
              isNew={client.identifier === "new"}
              onSelect={(tabKey) => setSelectedTab(tabKey)}
            >
              {selectedTab === "info" && (
                <TabClientInfo
                  client={client}
                  location={location}
                  missingFields={missingFields}
                  actions={actions}
                />
              )}
              {selectedTab === "users" && (
                <TabClientUsers
                  client={client}
                  location={location}
                  missingFields={missingFields}
                  view={view}
                  setView={setView}
                  actions={actions}
                />
              )}
              {selectedTab === "products" && (
                <TabClientProducts
                  client={client}
                  clients={clients}
                  location={location}
                  missingFields={missingFields}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  actions={actions}
                />
              )}
            </FDN.Tabs>
            <div>&nbsp;</div>
            <FDN.FormButtons onSave={actions.onSave} onCancel={actions.hidePopupEdit} />
          </>
        )}
      </FDN.Popup>
    </div>
  );
};

interface IViewAddUserProps {
  client: IClient;
  setView: React.Dispatch<React.SetStateAction<"default" | "adduser">>;
  actions: TActionsAdminClients;
}

const ViewAddUser: React.FunctionComponent<IViewAddUserProps> = ({ client, setView, actions }) => {
  const [foundAccount, setFoundAccount] = React.useState<FDN.IAccount | undefined>();
  const [checkDone, setCheckDone] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [type, setType] = React.useState("employee");

  const onEdit = (property: "email" | "firstname" | "lastname", value: string) => {
    if (property === "email") setEmail(value);
    if (property === "firstname") setFirstname(value);
    if (property === "lastname") setLastname(value);

    if (property === "email") {
      setCheckDone(false);
      setFoundAccount(undefined);
      setType("employee");
    }
  };

  const checkEmail = () => {
    if (!EmailValidator.validate(email)) return;

    actions.userCheckEmail(email).then((response) => {
      if (response?.statusCode === StatusCode.USER_NOT_FOUND) {
        setCheckDone(true);
      } else if (response?.statusCode === StatusCode.SUCCESS) {
        const account = response?.body?.account as FDN.IAccount;
        setFoundAccount(account);
        setCheckDone(true);
      }
    });
  };

  const addUser = async () => {
    let addResult: boolean | undefined = undefined;

    if (foundAccount) {
      addResult = await actions.userAddUser(
        {
          email: foundAccount.email,
          firstname: foundAccount.firstname,
          lastname: foundAccount.lastname,
        },
        type
      );
    } else {
      addResult = await actions.userAddUser(
        {
          email,
          firstname,
          lastname,
        },
        type
      );
    }

    if (addResult === true) {
      setCheckDone(false);
      setFoundAccount(undefined);
      setEmail("");
      setFirstname("");
      setLastname("");
      setType("employee");
      setView("default");
    }
  };

  const isSaveButtonDisabled = () => {
    if (foundAccount) {
      if (foundAccount.email && foundAccount.firstname) return false;
    } else {
      if (email && firstname) return false;
    }

    return true;
  };

  const TypeSelector = () => (
    <select value={type} onChange={(e) => setType(e.target.value as string)}>
      <option value="manager">
        {FDN.I18n.t("adminClients.users.add.form.type.manager.label")}
      </option>
      <option value="employee">
        {FDN.I18n.t("adminClients.users.add.form.type.employee.label")}
      </option>
    </select>
  );

  return (
    <div className="admin-clients-popup-view-adduser">
      <div className="__box-padding" style={{ paddingTop: "0", paddingBottom: "0" }}>
        <button className="clickable-button" onClick={() => setView("default")}>
          <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.back")}
        </button>
      </div>
      <hr />
      <div className="__box-padding" style={{ paddingTop: "0", paddingBottom: "0" }}>
        <h3>{FDN.I18n.t("adminClients.users.add.title")}</h3>
        <p>{FDN.I18n.t("adminClients.users.add.text")}</p>
        <FDN.Box>
          <div className="admin-clients-popup-view-adduser-email">
            <input
              type="text"
              value={email}
              placeholder={FDN.I18n.t("adminClients.users.add.form.email.placeholder")}
              onChange={(e) => onEdit("email", e.target.value)}
            />
            <button
              disabled={!EmailValidator.validate(email)}
              className="small primary hollow button"
              onClick={checkEmail}
            >
              <FDN.Icon icon="search" left />{" "}
              {FDN.I18n.t("adminClients.users.add.form.email.search.label")}
            </button>
          </div>
        </FDN.Box>
      </div>
      {checkDone ? (
        <>
          {foundAccount ? (
            <div className="__box-padding">
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={24} md={16} mdo={4}>
                    <h3 className="success-text text-center">
                      <FDN.Icon icon="check" left />{" "}
                      {FDN.I18n.t("adminClients.users.add.form.onCheck.success.title")}
                    </h3>
                    <FDN.Box>
                      <div className="admin-clients-popup-view-adduser-foundaccount">
                        <Avatar user={foundAccount} size={60} />
                        <div className="admin-clients-popup-view-adduser-foundaccount-content">
                          <div className="admin-clients-popup-view-adduser-foundaccount-content-name">
                            {foundAccount.displayname}
                          </div>
                          <div className="admin-clients-popup-view-adduser-foundaccount-content-email">
                            {foundAccount.email}
                          </div>
                        </div>
                      </div>
                    </FDN.Box>
                    <TypeSelector />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </div>
          ) : (
            <div className="__box-padding">
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={24} md={16} mdo={4}>
                    <h3 className="warning-text text-center">
                      <FDN.Icon icon="user-plus" left />{" "}
                      {FDN.I18n.t("adminClients.users.add.form.onCheck.notfound.title")}
                    </h3>
                    <FDN.Box>
                      <div className="admin-clients-popup-view-adduser-newaccount">
                        <FDN.Grid full>
                          <FDN.Row margin="xy">
                            <FDN.Cell sm={24}>
                              <FDN.Input
                                type="email"
                                value={email}
                                editMode={false}
                                label={FDN.I18n.t("adminClients.users.add.form.email.label")}
                              />
                            </FDN.Cell>
                          </FDN.Row>
                          <FDN.Row margin="xy">
                            <FDN.Cell sm={24}>
                              <FDN.Input
                                type="text"
                                value={firstname}
                                editMode={true}
                                label={FDN.I18n.t("adminClients.users.add.form.firstname.label")}
                                onUpdate={(value) => onEdit("firstname", value)}
                              />
                            </FDN.Cell>
                          </FDN.Row>
                          <FDN.Row margin="xy">
                            <FDN.Cell sm={24}>
                              <FDN.Input
                                type="text"
                                value={lastname}
                                editMode={true}
                                label={FDN.I18n.t("adminClients.users.add.form.lastname.label")}
                                onUpdate={(value) => onEdit("lastname", value)}
                              />
                            </FDN.Cell>
                          </FDN.Row>
                        </FDN.Grid>
                      </div>
                    </FDN.Box>
                    <TypeSelector />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </div>
          )}{" "}
          <div className="__box-padding">
            <FDN.FormButtons
              saveDisabled={isSaveButtonDisabled()}
              onCancel={() => setView("default")}
              onSave={addUser}
              saveLabel={FDN.I18n.t("adminClients.users.add.form.onSave.label")}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

const TabClientInfo: React.FunctionComponent<IPopupClientEditProps> = ({
  client,
  missingFields,
  location,
  actions,
}) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={16}>
          <FDN.Box>
            <h3>{FDN.I18n.t("adminClients.popup.group.info.title")}</h3>
            <ClientInfo
              client={client}
              missingFields={missingFields}
              location={location}
              actions={actions}
            />
          </FDN.Box>
          <FDN.Box>
            <FDN.Grid>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24} md={24}>
                  <FDN.Input
                    type="email"
                    value={client.email}
                    label={FDN.I18n.t("adminClients.popup.client.form.email.label")}
                    isMissing={missingFields?.includes("email")}
                    editMode={true}
                    onUpdate={(value) => {
                      actions.onEdit("email", value);
                    }}
                  />
                </FDN.Cell>
              </FDN.Row>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24} md={24}>
                  {location === "admin" && (
                    <div className="text-center">
                      <FDN.Switch
                        value={client.sendPrMail || false}
                        editMode={true}
                        label={FDN.I18n.t("adminClients.popup.client.form.sendPrMail.label")}
                        labelPosition="right"
                        size="medium"
                        onUpdate={(value) => actions.onEdit("sendPrMail", value)}
                      />
                    </div>
                  )}
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.Box>
          {location === "admin" && (
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={24} md={24}>
                    <div className="text-center">
                      <FDN.Switch
                        value={client.showSuppliersForNewEmployees || false}
                        editMode={true}
                        label={FDN.I18n.t(
                          "adminClients.popup.client.form.showSuppliersForNewEmployees.label"
                        )}
                        labelPosition="right"
                        size="medium"
                        onUpdate={(value) => actions.onEdit("showSuppliersForNewEmployees", value)}
                      />
                    </div>
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
          )}
        </FDN.Cell>
        <FDN.Cell sm={24} md={8}>
          <FDN.Box>
            <h3>{FDN.I18n.t("adminClients.popup.group.logo.title")}</h3>
            <FDN.SingleImageUploadWithImageWrapper
              vertical
              editMode={true}
              imageUrl={client.logoUrl}
              onUpdate={(imageUrl) => actions.onEdit("logoUrl", imageUrl)}
              onDelete={() => actions.onEdit("logoUrl", undefined)}
            />
          </FDN.Box>
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

interface ITabClientUsersProps extends IPopupClientEditProps {
  view: "default" | "adduser";
  setView: React.Dispatch<React.SetStateAction<"default" | "adduser">>;
}

const TabClientUsers: React.FunctionComponent<ITabClientUsersProps> = ({
  client,
  view,
  setView,
  actions,
}) => {
  return (
    <>
      {client.identifier === "new" ? (
        <p className="text-center" style={{ padding: "100px 0" }}>
          <em>{FDN.I18n.t("adminClients.popup.client.saveBeforUsers.text")}</em>
        </p>
      ) : (
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={16}>
              {(!client.managers || client.managers.length === 0) &&
              (!client.employees || client.employees.length === 0) ? (
                <p className="text-center">
                  <em>{FDN.I18n.t("adminClients.noUsers")}</em>
                </p>
              ) : (
                <div className="__admin-clients-popup-edit-client-userlist">
                  {client.managers?.map((manager) => {
                    return (
                      <FDN.Box key={manager.identifier}>
                        <div className="admin-clients-popup-view-adduser-foundaccount">
                          <Avatar user={manager} size={60} />
                          <div className="admin-clients-popup-view-adduser-foundaccount-content">
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-name">
                              {manager.displayname}{" "}
                              <span className="success label">
                                {FDN.I18n.t("adminClients.users.manager.label")}
                              </span>
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-email">
                              {manager.email}
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-email">
                              <FDN.Input
                                type="checkbox"
                                editMode={true}
                                value={
                                  client.showSuppliersFor?.includes(manager.identifier) || false
                                }
                                label={FDN.I18n.t(
                                  "adminClients.users.add.form.showSuppliersFor.label"
                                )}
                                onUpdate={() => {
                                  actions.onEdit(
                                    "showSuppliersFor",
                                    FDN.toggleArrayItem(manager.identifier, client.showSuppliersFor)
                                  );
                                }}
                              />
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-buttons">
                              <button
                                onClick={() => actions.downgradeToEmployee(manager.identifier)}
                                className="button-switch"
                              >
                                <FDN.Icon icon="chevron-circle-down" />{" "}
                                {FDN.I18n.t(
                                  "adminClients.users.add.form.onSwitchPermissions.toEmployee.label"
                                )}
                              </button>
                              <button
                                onClick={() => actions.userRemoveUser(manager.identifier)}
                                className="button-remove"
                              >
                                <FDN.Icon icon="times" />{" "}
                                {FDN.I18n.t("adminClients.users.add.form.onRemove.label")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </FDN.Box>
                    );
                  })}
                  {client.employees?.map((employee) => {
                    return (
                      <FDN.Box key={employee.identifier}>
                        <div className="admin-clients-popup-view-adduser-foundaccount">
                          <Avatar user={employee} size={60} />
                          <div className="admin-clients-popup-view-adduser-foundaccount-content">
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-name">
                              {employee.displayname}
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-email">
                              {employee.email}
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-email">
                              <FDN.Input
                                type="checkbox"
                                editMode={true}
                                value={
                                  client.showSuppliersFor?.includes(employee.identifier) || false
                                }
                                label={FDN.I18n.t(
                                  "adminClients.users.add.form.showSuppliersFor.label"
                                )}
                                onUpdate={() => {
                                  actions.onEdit(
                                    "showSuppliersFor",
                                    FDN.toggleArrayItem(
                                      employee.identifier,
                                      client.showSuppliersFor
                                    )
                                  );
                                }}
                              />
                            </div>
                            <div className="admin-clients-popup-view-adduser-foundaccount-content-buttons">
                              <button
                                onClick={() => actions.upgradeToManager(employee.identifier)}
                                className="button-switch"
                              >
                                <FDN.Icon icon="chevron-circle-up" />{" "}
                                {FDN.I18n.t(
                                  "adminClients.users.add.form.onSwitchPermissions.toManager.label"
                                )}
                              </button>
                              <button
                                onClick={() => actions.userRemoveUser(employee.identifier)}
                                className="button-remove"
                              >
                                <FDN.Icon icon="times" />{" "}
                                {FDN.I18n.t("adminClients.users.add.form.onRemove.label")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </FDN.Box>
                    );
                  })}
                </div>
              )}
              <div className="__admin-default-add-text-button">
                <button onClick={() => setView("adduser")}>
                  <FDN.Icon icon="plus-circle" left />{" "}
                  {FDN.I18n.t("adminClients.users.buttons.add.label")}
                </button>
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      )}
    </>
  );
};

const ClientInfo: React.FunctionComponent<IPopupClientEditProps> = ({
  client,
  missingFields,
  location,
  actions,
}) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={8}>
          <FDN.Input
            type="text"
            value={
              client.identifier === "new"
                ? FDN.I18n.t("adminClients.popup.client.form.clientNr.automatic")
                : client.clientNr
            }
            label={FDN.I18n.t("adminClients.popup.client.form.clientNr.label")}
            isMissing={missingFields?.includes("clientNr")}
            editMode={false}
            onUpdate={(value) => {
              actions.onEdit("clientNr", value);
            }}
          />
        </FDN.Cell>
        <FDN.Cell sm={24} md={16}>
          {location === "admin" ? (
            <div className="text-center" style={{ lineHeight: "52px" }}>
              <FDN.Switch
                value={client.locked || false}
                editMode={true}
                label={FDN.I18n.t("adminClients.popup.client.form.locked.label")}
                labelPosition="right"
                size="medium"
                onUpdate={(value) => actions.onEdit("locked", value)}
              />
            </div>
          ) : null}
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={24}>
          <FDN.Input
            type="text"
            value={client.name}
            label={FDN.I18n.t("adminClients.popup.client.form.name.label")}
            isMissing={missingFields?.includes("name")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("name", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={24}>
          <FDN.Input
            type="text"
            value={client.street}
            label={FDN.I18n.t("adminClients.popup.client.form.street.label")}
            isMissing={missingFields?.includes("street")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("street", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={24}>
          <FDN.Input
            type="text"
            value={client.streetAdditional}
            label={FDN.I18n.t("adminClients.popup.client.form.streetAdditional.label")}
            isMissing={missingFields?.includes("streetAdditional")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("streetAdditional", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={8}>
          <FDN.Input
            type="text"
            value={client.zip}
            label={FDN.I18n.t("adminClients.popup.client.form.zip.label")}
            isMissing={missingFields?.includes("zip")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("zip", value);
            }}
          />
        </FDN.Cell>
        <FDN.Cell sm={24} md={16}>
          <FDN.Input
            type="text"
            value={client.city}
            label={FDN.I18n.t("adminClients.popup.client.form.city.label")}
            isMissing={missingFields?.includes("city")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("city", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={24}>
          <FDN.Input
            type="country"
            value={client.country}
            label={FDN.I18n.t("adminClients.popup.client.form.country.label")}
            isMissing={missingFields?.includes("country")}
            editMode={location === "admin"}
            onUpdate={(value) => {
              actions.onEdit("country", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const TabClientProducts: React.FunctionComponent<IPopupClientEditProps> = ({
  client,
  clients,
  missingFields,
  location,
  categories,
  selectedCategory,
  actions,
}) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={24}>
          <h3>{FDN.I18n.t("adminClients.popup.group.products.title")}</h3>
          <p>{FDN.I18n.t("adminClients.popup.products.intro")}</p>
          {clients ? (
            <div className="admin-clients-popup-products-copyfrom">
              <FDN.Box small>
                <FDN.Grid full>
                  <FDN.Row margin="xy">
                    <FDN.Cell sm={24} md={24}>
                      <select
                        value={"NONE"}
                        onChange={(e) => actions.copyProductsFromClient(e.target.value)}
                      >
                        <option value="NONE">
                          {FDN.I18n.t("adminClients.popup.products.category.copy.label")}
                        </option>
                        {clients.map((clientsClient) => {
                          return (
                            <option key={clientsClient.identifier} value={clientsClient.identifier}>
                              {clientsClient.clientNr} | {clientsClient.name}
                            </option>
                          );
                        })}
                      </select>
                    </FDN.Cell>
                  </FDN.Row>
                </FDN.Grid>
              </FDN.Box>
            </div>
          ) : null}
          <ClientProducts
            client={client}
            missingFields={missingFields}
            location={location}
            categories={categories}
            selectedCategory={selectedCategory}
            actions={actions}
          />
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const ClientProducts: React.FunctionComponent<IPopupClientEditProps> = ({
  client,
  missingFields,
  location,
  categories,
  selectedCategory,
  actions,
}) => {
  if (!categories) return <FDN.Loading box />;

  return (
    <div className="admin-clients-popup-products">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={8}>
            <div className="admin-clients-popup-products-categories">
              <ul>
                {categories.map((category) => {
                  return (
                    <li
                      key={category.identifier}
                      className={`admin-clients-popup-products-category ${
                        actions.isCategoryEnabled(category.identifier) ? `selected` : `not-selected`
                      }`}
                    >
                      <button
                        className="admin-clients-popup-products-button-select"
                        onClick={() => actions.toggleClientProductCategory(category.identifier)}
                      >
                        <span className="icon-not-selected">
                          <FDN.Icon icon="minus" left />
                        </span>
                        <span className="icon-selected">
                          <FDN.Icon icon="check" left />
                        </span>
                        <span className="product-category-name">{category.name}</span>
                        <span className="product-category-productscount">
                          {actions.getSelectedProductsCount(category.identifier)} /{" "}
                          {category.products?.length || 0}
                        </span>
                      </button>
                      <div className="admin-clients-popup-products-button-settings">
                        {actions.isCategoryEnabled(category.identifier) ? (
                          <button onClick={() => actions.selectCategory(category)}>
                            <FDN.Icon icon="cog" />
                          </button>
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={16}>
            {selectedCategory ? (
              <ClientProductsCategorySettings
                client={client}
                category={selectedCategory}
                actions={actions}
              />
            ) : null}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IClientProductsCategorySettings {
  client: IClient;
  category: IProductCategory;
  actions: TActionsAdminClients;
}

const ClientProductsCategorySettings: React.FunctionComponent<IClientProductsCategorySettings> = ({
  client,
  category,
  actions,
}) => {
  const categorySettingOption = actions.getCategorySettingsOption(category.identifier);

  if (categorySettingOption === "none") return null;

  return (
    <div className="admin-clients-popup-products-selectedcategory">
      <FDN.Box>
        <h3>{category.name}</h3>
        <select
          value={categorySettingOption}
          onChange={(e) =>
            actions.setCategorySettingOption(
              category.identifier,
              e.target.value as IClientCategorySettingOptions
            )
          }
        >
          <option value="all">
            {FDN.I18n.t("adminClients.popup.products.category.options.all.label")}
          </option>
          <option value="selected">
            {FDN.I18n.t("adminClients.popup.products.category.options.selected.label")}
          </option>
        </select>
        {categorySettingOption === "selected" ? (
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24}>
                <ul>
                  {category.products?.map((product) => {
                    return (
                      <li key={product.identifier}>
                        <label>
                          <FDN.Grid full>
                            <FDN.Row margin="xy">
                              <FDN.Cell sm={2} md={1}>
                                <input
                                  type="checkbox"
                                  checked={client.enabledProducts?.includes(product.identifier)}
                                  onChange={() => actions.toggleEnabledProduct(product.identifier)}
                                />
                              </FDN.Cell>
                              <FDN.Cell sm={22} md={12}>
                                {product.productId} | {product.name}
                              </FDN.Cell>
                              <FDN.Cell sm={12} md={4} className="text-right">
                                {product.amount} {product.unit}
                              </FDN.Cell>
                              <FDN.Cell sm={12} md={6} className="text-right">
                                {Config.get("currency") as string}
                                {FDN.formatNumber(product.priceUnit || 0, 2, 2)}
                              </FDN.Cell>
                            </FDN.Row>
                          </FDN.Grid>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        ) : null}
      </FDN.Box>
    </div>
  );
};

export default PopupClientEdit;
