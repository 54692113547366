import { IPurchaseRequisition, PurchaseRequisitionsListTabTypes } from "src/types/pr.types";
import Api from "./Api";

export default class PrApi {
  public static getPrs(api: Api, type: PurchaseRequisitionsListTabTypes, clientIdentifier?: string) {
    return api.get("purchaserequisitions.index", { clientIdentifier, type }, { queryParams: true });
  }

  public static newPr(api: Api, clientIdentifier: string) {
    return api.get("purchaserequisitions.show", { identifier: "new", clientIdentifier });
  }

  public static getPr(api: Api, identifier: string, clientIdentifier?: string) {
    if (identifier === "new") return api.get("purchaserequisitions.show", { identifier: "new", clientIdentifier });
    else return api.get("purchaserequisitions.show", { identifier, clientIdentifier });
  }

  public static getPrPdf(api: Api, identifier: string, clientIdentifier?: string) {
    return api.get("purchaserequisitions.pdf", { identifier, clientIdentifier });
  }

  public static savePr(api: Api, identifier: string, clientIdentifier: string, data: IPurchaseRequisition) {
    return api.post("purchaserequisitions.show", { clientIdentifier, identifier }, { pr: data });
  }

  public static sendPr(api: Api, identifier: string, clientIdentifier: string) {
    return api.post("purchaserequisitions.send", { clientIdentifier, identifier });
  }

  public static cancelPr(api: Api, identifier: string, clientIdentifier: string) {
    return api.post("purchaserequisitions.cancel", { clientIdentifier, identifier });
  }

  public static duplicatePr(api: Api, identifier: string) {
    return api.post("purchaserequisitions.duplicate", { identifier });
  }

  public static prArrived(api: Api, identifier: string) {
    return api.post("purchaserequisitions.arrived", { identifier });
  }

  public static prUpdateProductStatus(
    api: Api,
    clientIdentifier: string,
    identifier: string,
    productIdentifier: string,
    status: string,
    amount: number
  ) {
    return api.post("purchaserequisitions.changeProductStatus", {
      clientIdentifier,
      identifier,
      productIdentifier,
      status,
      amount,
    });
  }
}
