import { Icon } from "@tschirpke/ptfdn-frontend-components";
import React from "react";

interface IInputNumberProps {
  value?: number | null;
  horizontal?: boolean;
  view?: "input" | "buttons";
  min?: number;
  max?: number;
  onUpdate: (value: number | undefined | null) => void;
}

const InputNumber = ({ value, horizontal, view, min, max, onUpdate }: IInputNumberProps) => {
  const onPlus = () => {
    const newValue = (value || 0) + 1;
    onUpdate(newValue);
  };

  const onMinus = () => {
    let newValue = (value || 0) - 1;
    if (newValue <= 0) newValue = 0;
    onUpdate(newValue);
  };

  if (view === "input") {
    return (
      <div className={`${horizontal ? "horizontal" : "vertical"} input-number`}>
        <input
          type="text"
          value={value !== undefined && value !== null && value > 0 ? value?.toString() || "" : ""}
          onChange={(e) => {
            onUpdate(e.target.value ? parseInt(e.target.value) : undefined);
          }}
        />
      </div>
    );
  }

  if (view === "buttons") {
    return (
      <div className={`${horizontal ? "horizontal" : "vertical"} input-number view-only-buttons`}>
        <button
          disabled={max && value && value >= max ? true : false}
          className="input-number-plus disabled:opacity-40 disabled:cursor-not-allowed"
          onClick={onPlus}
        >
          <Icon icon="plus" />
        </button>

        <button
          disabled={(min || min === 0) && (value || value === 0) && value <= min ? true : false}
          className="input-number-minus disabled:opacity-40 disabled:cursor-not-allowed"
          onClick={onMinus}
        >
          <Icon icon="minus" />
        </button>
      </div>
    );
  }

  return (
    <div className={`${horizontal ? "horizontal" : "vertical"} input-number view-only-input`}>
      <button
        disabled={max && value && value >= max ? true : false}
        className="input-number-plus disabled:opacity-40 disabled:cursor-not-allowed"
        onClick={onPlus}
      >
        <Icon icon="plus" />
      </button>
      <input
        type="text"
        value={value !== undefined && value !== null && value > 0 ? value?.toString() || "" : ""}
        onChange={(e) => {
          onUpdate(e.target.value ? parseInt(e.target.value) : undefined);
        }}
      />
      <button
        disabled={(min || min === 0) && (value || value === 0) && value <= min ? true : false}
        className="input-number-minus disabled:opacity-40 disabled:cursor-not-allowed"
        onClick={onMinus}
      >
        <Icon icon="minus" />
      </button>
    </div>
  );
};

export default InputNumber;
