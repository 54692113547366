import React from "react";
import * as FDN from "src/core";
import { TActions, IUserroleState, IUserrolesState } from "src/types/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { cloneDeep } from "lodash";
import { userHasPermission } from "src/core/AdminService/helpers";
import Config from "src/core/Config";

const useServiceAdminUserroleService = () => {
  const language = FDN.I18n.getLanguage();

  const [userroleData, setUserroleData] = React.useState<IUserroleState>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [availableUserPermissions, setAvailableUserPermissions] = React.useState<string[]>([]);

  const permissionGroups = Config.get("admin.permissionGroups") as { [key: string]: any };

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminUserroles(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setAvailableUserPermissions(response?.body?.availableUserPermissions as string[]);
      }
    });
  };

  const refreshUserrole = (userrole: IUserroleState) => {
    setUserroleData(cloneDeep(userrole));
  };

  const updateAllAdminPermissions = (permissions: string[], value: boolean): string[] => {
    for (const permission of availableUserPermissions) {
      if (permission.substring(0, 5) === "admin") {
        if (value === true) permissions = addPermissionToArray(permissions, permission);
        else permissions = removePermissionFromArray(permissions, permission);
      }
    }

    if (value === false) permissions = removePermissionFromArray(permissions, "fulladmin");
    return permissions;
  };

  const togglePermission = (property: string) => {
    if (!userroleData || !userHasPermission(user, "adminUserRolesEdit")) return;

    const updatedUserrole = { ...userroleData };

    if (updatedUserrole.permissions.includes(property)) {
      if (property === "admin")
        updatedUserrole.permissions = updateAllAdminPermissions(userroleData.permissions, false);
      else if (property.substring(0, 5) === "admin")
        updatedUserrole.permissions = removePermissionFromArray(
          updatedUserrole.permissions,
          "fulladmin"
        );

      updatedUserrole.permissions = removePermissionFromArray(
        updatedUserrole.permissions,
        property
      );
    } else {
      if (
        property.substring(0, 5) === "admin" &&
        property !== "admin" &&
        updatedUserrole.admin !== true
      )
        updatedUserrole.permissions = addPermissionToArray(updatedUserrole.permissions, "admin");
      else if (property === "fulladmin")
        updatedUserrole.permissions = updateAllAdminPermissions(userroleData.permissions, true);

      updatedUserrole.permissions = addPermissionToArray(updatedUserrole.permissions, property);
    }

    setUserroleData(cloneDeep(updatedUserrole));
    setUnsavedChanges(true);
  };

  const addPermissionToArray = (permissions: string[], property: string): string[] => {
    if (!permissions.includes(property)) permissions.push(property);
    return permissions;
  };

  const removePermissionFromArray = (permissions: string[], property: string): string[] => {
    permissions = permissions.filter((item: string) => {
      return item !== property;
    });

    return permissions;
  };

  const onEdit = (property: string, value: any) => {
    if (!userroleData) return;
    userroleData[property] = value;
    setUserroleData({ ...userroleData });
  };

  const onDelete = (onUpdate: (userroles: IUserrolesState) => void, onClose: () => void) => {
    if (!userroleData) return;

    const doDelete = () => {
      NOTIFICATIONS.showSaving({ type: "delete" });
      AdminApi.deleteAdminUserrole(api, userroleData.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminUserroles.onDelete.success.title"),
            FDN.I18n.t("adminUserroles.onDelete.success.text")
          );

          NOTIFICATIONS.hideSaving();

          if (onUpdate) {
            const userroles = response?.body?.userroles as IUserrolesState;
            onUpdate(userroles);
          }
          if (onClose) onClose();
        }
      });
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "okcancel",
      message: FDN.I18n.t("adminUserroles.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          label: FDN.I18n.t("buttons.yesdeletepermanently"),
          onClick: () => doDelete(),
        },
      ],
    });
  };

  const onCancel = (onClose: () => void) => {
    if (!unsavedChanges || window.confirm(FDN.I18n.t("main.form.buttons.onCancel.confirm"))) {
      if (onClose) onClose();
    }
  };

  const onSave = (onUpdate: (userroles: IUserrolesState) => void, onClose: () => void) => {
    if (!userroleData) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.updateAdminUserrole(api, userroleData.identifier, userroleData).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminUserroles.onSave.success.title"),
          FDN.I18n.t("adminUserroles.onSave.success.text")
        );

        NOTIFICATIONS.hideSaving();

        if (onUpdate) {
          const userroles = response?.body?.userroles as IUserrolesState;
          onUpdate(userroles);
        }
        if (onClose) onClose();
      }
    });
  };

  const actions: TActions = {
    refreshUserrole,
    updateAllAdminPermissions,
    togglePermission,
    onEdit,
    onCancel,
    onSave,
    onDelete,
  };

  return {
    actions,
    user,
    userroleData,
    unsavedChanges,
    permissionGroups,
    language,
  };
};

export default useServiceAdminUserroleService;
