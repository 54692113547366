import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";

const LegalPage: React.FunctionComponent = () => {
  const { api } = useServiceCore();

  React.useEffect(() => {
    api.get("hello");
  }, []);

  return (
    <FDN.LayoutWithTopbar page="legal">
      <FDN.PageContent>
        <h1>Impressum / Legal</h1>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default LegalPage;
