import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminSuppliers } from "src/services/admin/AdminSuppliersService";
import { ISuppliers } from "src/types/products.types";
import { SuppliersSearchProps, SuppliersSortBy } from "src/types/suppliers.types";

const sortByValues: SuppliersSortBy[] = [
  "name_asc",
  "name_desc",
  "zip_asc",
  "zip_desc",
  "city_asc",
  "city_desc",
  "contactName_asc",
  "contactName_desc",
  "contactEmail_asc",
  "contactEmail_desc",
];

interface ISuppliersSearchProps {
  suppliers: ISuppliers;
  search: SuppliersSearchProps;
  actions: TActionsAdminSuppliers;
}

const SuppliersSearch: React.FunctionComponent<ISuppliersSearchProps> = ({
  suppliers,
  search,
  actions,
}) => {
  const cities: string[] = [];

  for (const supplier of suppliers) {
    if (!supplier.city) continue;

    if (!cities.includes(supplier.city)) {
      cities.push(supplier.city);
    }
  }

  cities.sort();

  return (
    <div className="admin-suppliers-search">
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={7}>
              <input
                type="text"
                value={search.text || ""}
                onChange={(e) => actions.updateSearch("text", e.target.value)}
                placeholder={FDN.I18n.t("adminSuppliers.search.text.placeholder")}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={6}>
              <select
                value={search.city || ""}
                onChange={(e) => actions.updateSearch("city", e.target.value)}
              >
                <option value="">{FDN.I18n.t("adminSuppliers.search.city.label")}</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </FDN.Cell>

            <FDN.Cell sm={24} md={5}>
              <button
                className="primary hollow button"
                style={{ width: "100%" }}
                onClick={actions.resetSearch}
              >
                <FDN.Icon icon="refresh" left /> {FDN.I18n.t("adminSuppliers.search.reset.label")}
              </button>
            </FDN.Cell>
            <FDN.Cell sm={24} md={6}>
              <select
                value={search.sortBy}
                onChange={(e) => {
                  const value = e.target.value as SuppliersSortBy | "none";
                  if (value !== "none") actions.updateSearch("sortBy", value);
                }}
              >
                <option value="none" disabled>
                  {FDN.I18n.t("adminSuppliers.search.sort.label")}
                </option>
                {sortByValues.map((sortByValue) => (
                  <option key={sortByValue} value={sortByValue}>
                    {FDN.I18n.t(`adminSuppliers.search.sort.option.${sortByValue}.label`)}
                  </option>
                ))}
              </select>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default SuppliersSearch;
