import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumn,
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  IFreeItems,
  IFreeItem,
  IPrProductStatusType,
} from "src/types/pr.types";
import InputNumber from "./InputNumber";
import PrProductDropdownMenu from "./PrProductDropdownMenu";
import FreeItemHasImageIcon from "./FreeItemHasImageIcon";

interface IPrFormFreeItemsListProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItems?: IFreeItems;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  hideHead: boolean;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFormFreeItemsList: React.FunctionComponent<IPrFormFreeItemsListProps> = ({
  location,
  showOnlyStatus,
  pr,
  freeItems,
  editMode,
  prFormVisibleColumns,
  hideHead,
  showSupplier,
  actions,
}) => {
  if (!showSupplier) prFormVisibleColumns = prFormVisibleColumns.filter((column) => column !== "supplier");

  return (
    <div className="__pr-form-products">
      {hideHead !== true ? (
        <div className="__pr-form-product-head">
          <FDN.Grid full>
            <FDN.Row>
              {prFormVisibleColumns.map((type) => {
                return <FreeItemListHeadItemColumn key={type} type={type} />;
              })}
            </FDN.Row>
          </FDN.Grid>
        </div>
      ) : (
        <></>
      )}
      <div className="__pr-form-product-body">
        <ul>
          {freeItems?.map((freeItem, index) => {
            if (freeItem.deleted === true) return null;

            return (
              <li key={freeItem.identifier}>
                <div className="__pr-form-product-body-number">#{index + 1}</div>
                <FDN.Box noPadding noMargin>
                  <FreeItemListItem
                    location={location}
                    showOnlyStatus={showOnlyStatus}
                    pr={pr}
                    freeItem={freeItem}
                    editMode={editMode}
                    prFormVisibleColumns={prFormVisibleColumns}
                    showSupplier={showSupplier}
                    actions={actions}
                  />
                </FDN.Box>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface IPrFormFreeItemsListItemProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItem: IFreeItem;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const FreeItemListItem: React.FunctionComponent<IPrFormFreeItemsListItemProps> = ({
  location,
  showOnlyStatus,
  pr,
  freeItem,
  editMode,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-form-product">
      <FDN.Grid full>
        <FDN.Row>
          {prFormVisibleColumns.map((type) => {
            return (
              <FreeItemListBodyItemColumn
                key={type}
                type={type}
                location={location}
                showOnlyStatus={showOnlyStatus}
                pr={pr}
                freeItem={freeItem}
                editMode={editMode}
                showSupplier={showSupplier}
                actions={actions}
              />
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IPrFormFreeItemsListHeadItemColumnProps {
  type: PrFormVisibleColumn;
}

const FreeItemListHeadItemColumn: React.FunctionComponent<IPrFormFreeItemsListHeadItemColumnProps> = ({ type }) => {
  const sm = 24;
  let md = 3;
  let textAlign = "center";

  switch (type) {
    case "amount":
      break;
    case "productId":
      textAlign = "left";
      break;
    case "productName":
      md = 6;
      textAlign = "left";
      break;
    case "productCategory":
      md = 6;
      textAlign = "left";
      break;
    case "priceUnit":
      break;
    case "priceTotal":
      break;
  }

  const content = FDN.I18n.t(`pr.form.list.head.${type}.label`);

  return (
    <FDN.Cell sm={sm} md={md}>
      <div className={`text-${textAlign}`}>{content}</div>
    </FDN.Cell>
  );
};

interface IPrFormFreeItemsListBodyItemColumnProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItem: IFreeItem;
  type: PrFormVisibleColumn;
  editMode: boolean;
  showSupplier: boolean;
  actions: TActionsPr;
}

const FreeItemListBodyItemColumn: React.FunctionComponent<IPrFormFreeItemsListBodyItemColumnProps> = ({
  location,
  showOnlyStatus,
  pr,
  freeItem,
  type,
  editMode,
  showSupplier,
  actions,
}) => {
  let content = <></>;
  let textAlign = "left";
  let sm = 24;
  let md = 3;
  let hideColumnForMobile = false;

  switch (type) {
    case "amount":
      sm = 12;
      md = 3;
      content = (
        <div className={`__pr-form-list-column type-input ${showOnlyStatus && "opacity-50"}`}>
          {editMode === true ? (
            <>
              {/* <input
              type="number"
              min={0}
              value={freeItem.amount || ""}
              onChange={(e) =>
                actions.onEditFreeItemAmount(freeItem.identifier, e.target.valueAsNumber)
              }
            /> */}
              <InputNumber
                horizontal
                value={freeItem.amount || 0}
                onUpdate={(value) => actions.onEditFreeItemAmount(freeItem.identifier, value)}
              />
            </>
          ) : (
            <div className="text-right">
              {showOnlyStatus ? (
                <>
                  {freeItem?.status && (
                    <>
                      {freeItem?.status && freeItem?.status[showOnlyStatus] ? freeItem?.status[showOnlyStatus] || 0 : 0}{" "}
                      /{" "}
                    </>
                  )}
                </>
              ) : (
                <>
                  {freeItem?.status && (freeItem?.perfectAmount || 0) < (freeItem?.amount || 0) && (
                    <>{freeItem?.perfectAmount || 0} / </>
                  )}
                </>
              )}
              {freeItem?.amount || ""}
            </div>
          )}
        </div>
      );
      break;
    case "productName":
      content = (
        <div className="__pr-form-list-column type-text">
          <div className="w-full flex gap-0">
            <div className={`flex-grow ${showOnlyStatus && "opacity-50"}`}>
              <div className="product-name">
                <div className="float-right">
                  <FreeItemHasImageIcon freeItem={freeItem} />
                </div>
                {editMode === true ? (
                  <button onClick={() => actions.onSelectFreeItem(freeItem)}>
                    {freeItem.name} <FDN.Icon icon="pencil" right />
                  </button>
                ) : (
                  <span>{freeItem.name}</span>
                )}
              </div>
              <div className="product-description">{freeItem.description}</div>
            </div>
            <div>
              {freeItem && location === "admin" && pr.status === "PROCESS" ? (
                <>
                  <PrProductDropdownMenu
                    pr={pr}
                    product={freeItem}
                    productName={freeItem.name || ""}
                    location={location}
                    menuItems={["NOT_IN_STOCK"]}
                  >
                    <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                      <FDN.Icon icon="ellipsis-h" />
                    </div>
                  </PrProductDropdownMenu>
                </>
              ) : null}
              {freeItem &&
              location === "client" &&
              showOnlyStatus !== "NOT_IN_STOCK" &&
              (pr.status === "DELIVERY" || pr.status === "COMPLETED") ? (
                <>
                  <PrProductDropdownMenu
                    pr={pr}
                    product={freeItem}
                    productName={freeItem.name || ""}
                    location={location}
                    menuItems={["SHORTAGE", "QUALITY"]}
                  >
                    <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                      <FDN.Icon icon="ellipsis-h" />
                    </div>
                  </PrProductDropdownMenu>
                </>
              ) : null}
            </div>
          </div>
        </div>
      );
      md = showSupplier ? 6 : 9;
      break;
    case "unit":
      sm = 12;
      md = 2;
      textAlign = "right";
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>{freeItem.unit}</div>
      );
      break;
    case "productCategory":
      hideColumnForMobile = true;
      md = 4;
      content = <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>&nbsp;</div>;
      break;
    case "priceUnit":
      content = (
        <div className={`__pr-form-list-column type-price  ${showOnlyStatus && "hidden"}`}>
          <span className="show-for-small-only">{FDN.I18n.t(`pr.form.list.head.${type}.label`)}:&nbsp;</span>
          {FDN.formatNumber(freeItem.priceUnit || 0)}
        </div>
      );
      break;

    case "priceTotal":
      content = (
        <div className={`__pr-form-list-column type-price  ${showOnlyStatus && "hidden"}`}>
          <span className="show-for-small-only">{FDN.I18n.t(`pr.form.list.head.${type}.label`)}:&nbsp;</span>
          {freeItem.priceUnit && freeItem.perfectAmount
            ? FDN.formatNumber(freeItem.priceUnit * freeItem.perfectAmount)
            : FDN.formatNumber(0)}
        </div>
      );
      break;
  }

  return (
    <FDN.Cell
      sm={sm}
      md={md}
      className={`text-${textAlign} ${hideColumnForMobile === true ? `hide-for-small-only` : ``}`}
    >
      {content}
    </FDN.Cell>
  );
};

export default PrFormFreeItemsList;
