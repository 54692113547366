import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import useServiceAdminDashboard from "src/services/admin/DashboardService";
import AdminHeader from "../layouts/AdminHeader";
import UrlService from "src/core/UrlService";

const AdminDashboard: React.FunctionComponent = () => {
  //const { dashboard } = useServiceAdminDashboard();

  React.useEffect(() => {
    window.location.href = UrlService.url("admin.purchaserequisitions.index");
  }, []);

  return <FDN.Loading />;

  // return (
  //   <AdminLayout page="admin-dashboard" selectedNav="dashboard">
  //     <AdminHeader title={FDN.I18n.t("adminDashboard.header.title")} />
  //   </AdminLayout>
  // );
};

export default AdminDashboard;
