import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminPrOverview } from "src/services/admin/AdminPrsService";
import { PrOverviewSearchProps, PrOverviewSortBy } from "src/types/pr.types";

const sortByValues: PrOverviewSortBy[] = [
  "createdAt_asc",
  "createdAt_desc",
  "clientNr_asc",
  "clientNr_desc",
  "priceTotal_asc",
  "priceTotal_desc",
];

interface IPrSearchProps {
  search: PrOverviewSearchProps;
  actions: TActionsAdminPrOverview;
}

const PrSearch: React.FunctionComponent<IPrSearchProps> = ({ search, actions }) => {
  return (
    <div className="__pro-form-search admin-pr-overview-search">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={14}>
            <input
              type="text"
              value={search.text || ""}
              onChange={(e) => actions.updateSearch("text", e.target.value)}
              placeholder={FDN.I18n.t("adminPr.search.text.placeholder")}
            />
          </FDN.Cell>

          <FDN.Cell sm={24} md={4}>
            <button
              className="primary hollow button"
              style={{ width: "100%" }}
              onClick={actions.resetSearch}
            >
              <FDN.Icon icon="refresh" left /> {FDN.I18n.t("pr.search.reset.label")}
            </button>
          </FDN.Cell>
          <FDN.Cell sm={24} md={6}>
            <select
              value={search.sortBy}
              onChange={(e) => {
                const value = e.target.value as PrOverviewSortBy | "none";
                if (value !== "none") actions.updateSearch("sortBy", value);
              }}
            >
              <option value="none" disabled>
                {FDN.I18n.t("adminPr.search.sort.label")}
              </option>
              {sortByValues.map((sortByValue) => (
                <option key={sortByValue} value={sortByValue}>
                  {FDN.I18n.t(`adminPr.search.sort.option.${sortByValue}.label`)}
                </option>
              ))}
            </select>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default PrSearch;
