import React from "react";
import * as FDN from "src/core";
import { classNames } from "src/core/helpers/design.helper";
import { IPurchaseRequisition } from "src/types/pr.types";

const lineClassNamesWeb = "px-4 py-1.5";
const lineClassNamesPdf = "px-2 py-0";

const lineBorderClassNamesWeb = "border-b border-gray-200";
const lineBorderClassNamesPdf = "";

interface PrInfoBlockProps {
  pr: IPurchaseRequisition;
  location: "web" | "pdf";
}

const PrInfoBlock = ({ pr, location }: PrInfoBlockProps) => {
  if (location === "pdf")
    return (
      <div className={classNames("text-green-400 text-xs")}>
        <PrInfoBlockContent pr={pr} location={location} />
      </div>
    );

  if (location === "web")
    return (
      <div className={""}>
        <FDN.Box noPadding>
          <PrInfoBlockContent pr={pr} location={location} />
        </FDN.Box>
      </div>
    );

  return null;
};

const PrInfoBlockContent = ({ pr, location }: PrInfoBlockProps) => {
  const styles: React.CSSProperties = {};

  const lineClassNames = location === "web" ? lineClassNamesWeb : lineClassNamesPdf;
  const lineBorderClassNames = location === "web" ? lineBorderClassNamesWeb : lineBorderClassNamesPdf;

  if (location === "pdf") styles.fontSize = "0.6rem";

  return (
    <>
      <div className={classNames(lineClassNames, lineBorderClassNames)} style={styles}>
        {FDN.I18n.t("pr.date")}:{" "}
        <strong className="font-semibold">
          {FDN.formatDateTime(pr.requisitionDate, { useHumanReadableDate: false })}
        </strong>
        <FDN.Icon icon="calendar" className="ml-3" />
      </div>
      <div className={classNames(lineClassNames, lineBorderClassNames)} style={styles}>
        {FDN.I18n.t("pr.createdBy")}:{" "}
        <strong className="font-semibold">{pr.createdBy?.displayname ?? <FDN.Icon icon="question" />}</strong>
        <FDN.Icon icon="user" className="ml-3" />
      </div>
      <div className={classNames(lineClassNames, lineBorderClassNames)} style={styles}>
        {FDN.I18n.t("pr.options.delivery.delivery.label")}:{" "}
        <strong className="font-semibold">
          {pr.desiredDeliveryType === "DESIRED" ? (
            <>{FDN.formatDate(pr.desiredDeliveryDate)}</>
          ) : (
            <>{FDN.I18n.t(`pr.options.delivery.select.${pr.desiredDeliveryType}.label`)}</>
          )}
        </strong>
        <FDN.Icon icon="truck" className="ml-3" />
      </div>
      <div className={classNames(lineClassNames)} style={styles}>
        {FDN.I18n.t("pr.options.delivery.scheduledDelivery.label")}:{" "}
        <strong className="font-semibold">
          {pr.scheduledDeliveryDate ? <>{FDN.formatDate(pr.scheduledDeliveryDate)}</> : <FDN.Icon icon="question" />}
        </strong>
        <FDN.Icon icon="truck" className="ml-3" />
      </div>
    </>
  );
};

export default PrInfoBlock;
