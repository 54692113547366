import * as FDN from "src/core";
import {
  formatDate as ptfdnFormatDate,
  formatTime as ptfdnFormatTime,
  formatDateTime as ptfdnFormatDateTime,
  TFormatDateTimeDate,
  IFormatDateOptions,
  IFormatTimeOptions,
  IFormatDateTimeOptions,
} from "@tschirpke/ptfdn-frontend-components";

export function nl2br(str: string, breakTag = "<br />") {
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
}

/**
 *
 * HELPER FOR DATES AND TIMES
 *
 */

export const formatDate = (date: TFormatDateTimeDate, options?: IFormatDateOptions): string => {
  let dateStr = ptfdnFormatDate(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

export const formatTime = (date: TFormatDateTimeDate, options?: IFormatTimeOptions): string => {
  return ptfdnFormatTime(date, options);
};

export const formatDateTime = (
  date: TFormatDateTimeDate,
  options?: IFormatDateTimeOptions
): string => {
  let dateStr = ptfdnFormatDateTime(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

export const dateStrI18n = (dateStr: string): string => {
  dateStr = dateStr.replace("Today", FDN.I18n.t(`main.date.today`));
  dateStr = dateStr.replace("Tomorrow", FDN.I18n.t(`main.date.tomorrow`));
  dateStr = dateStr.replace("Yesterday", FDN.I18n.t(`main.date.yesterday`));

  return dateStr;
};

export const getAddressInOneLine = (obj: { [key: string]: any }): string => {
  let address = "";

  if (obj.street) {
    address = obj.street;
    if (obj.streetAdditional || obj.zip || obj.city || obj.country) address = `${address}, `;
  }

  if (obj.streetAdditional) {
    address = `${address}${obj.streetAdditional}`;
    if (obj.zip || obj.city || obj.country) address = `${address}, `;
  }

  if (obj.zip || obj.city) {
    if (obj.zip) address = `${address}${obj.zip} `;
    if (obj.city) address = `${address}${obj.city}`;
    if (obj.country) address = `${address}, `;
  }

  if (obj.country) address = `${address}${obj.country} `;

  return address;
};

export const getAddressInMultipleLine = (obj: { [key: string]: any }): string => {
  let address = "";

  if (obj.street) {
    address = obj.street;
    if (obj.streetAdditional || obj.zip || obj.city || obj.country) address = `${address}<br />`;
  }

  if (obj.streetAdditional) {
    address = `${address}${obj.streetAdditional}`;
    if (obj.zip || obj.city || obj.country) address = `${address}<br />`;
  }

  if (obj.zip || obj.city) {
    if (obj.zip) address = `${address}${obj.zip} `;
    if (obj.city) address = `${address}${obj.city}`;
    if (obj.country) address = `${address}<br />`;
  }

  if (obj.country) address = `${address}${obj.country} `;

  return address;
};
