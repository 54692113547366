import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  IFreeItems,
  IPrProductStatusType,
} from "src/types/pr.types";
import InputNumber from "../InputNumber";
import PrProductDropdownMenu from "../PrProductDropdownMenu";

interface IPrFormFreeItemsListProps {
  showOnlyStatus?: IPrProductStatusType;
  location: "admin" | "client";
  pr: IPurchaseRequisition;
  freeItems?: IFreeItems;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFormFreeItemsListMobile: React.FunctionComponent<IPrFormFreeItemsListProps> = ({
  showOnlyStatus,
  location,
  pr,
  freeItems,
  editMode,
  actions,
}) => {
  return (
    <div className="__pr-mobile-form-products">
      {freeItems?.map((freeItem, index) => {
        return (
          <div className="border rounded mb-3 bg-white" key={freeItem.identifier}>
            <div className={`flex justify-between px-2 py-1 bg-slate-200 border-b ${showOnlyStatus && "opacity-50"}`}>
              <div className="flex-1">
                <FDN.Icon icon="archive" left />#{index + 1}{" "}
                {editMode === true ? (
                  <button
                    onClick={() => actions.onSelectFreeItem(freeItem)}
                    className="text-primary cursor-pointer hover:underline"
                  >
                    <strong className="pl-1">
                      {freeItem.name} <FDN.Icon icon="pencil" right />
                    </strong>
                  </button>
                ) : (
                  <span>{freeItem.name}</span>
                )}
              </div>
            </div>
            <div className="px-2 py-1.5">
              <div className="text-right">
                {freeItem && location === "admin" && pr.status === "PROCESS" ? (
                  <>
                    <PrProductDropdownMenu
                      pr={pr}
                      product={freeItem}
                      productName={freeItem.name || ""}
                      location={location}
                      menuItems={["NOT_IN_STOCK"]}
                    >
                      <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                        <FDN.Icon icon="ellipsis-h" />
                      </div>
                    </PrProductDropdownMenu>
                  </>
                ) : null}
                {freeItem &&
                location === "client" &&
                showOnlyStatus !== "NOT_IN_STOCK" &&
                (pr.status === "DELIVERY" || pr.status === "COMPLETED") ? (
                  <>
                    <PrProductDropdownMenu
                      pr={pr}
                      product={freeItem}
                      productName={freeItem.name || ""}
                      location={location}
                      menuItems={["SHORTAGE", "QUALITY"]}
                    >
                      <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                        <FDN.Icon icon="ellipsis-h" />
                      </div>
                    </PrProductDropdownMenu>
                  </>
                ) : null}
              </div>
              <div className={`flex justify-between gap-2 ${showOnlyStatus && "opacity-50"}`}>
                <div className="w-1/3">
                  {editMode ? (
                    <div className="is-editmode">
                      <InputNumber
                        view="input"
                        value={freeItem.amount}
                        onUpdate={(value) => actions.onEditFreeItemAmount(freeItem.identifier, value)}
                      />
                    </div>
                  ) : (
                    <div className="is-not-editmode text-center font-bold text-base pt-1">
                      {showOnlyStatus ? (
                        <>
                          {freeItem?.status && (
                            <>
                              {freeItem?.status && freeItem?.status[showOnlyStatus]
                                ? freeItem?.status[showOnlyStatus] || 0
                                : 0}{" "}
                              /{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {freeItem?.status && (freeItem?.perfectAmount || 0) < (freeItem?.amount || 0) && (
                            <>{freeItem?.perfectAmount || 0} / </>
                          )}
                        </>
                      )}
                      {freeItem?.amount || ""}
                    </div>
                  )}
                  <div className="py-1 text-center">{freeItem.unit}</div>
                </div>
                <div className={`${editMode ? "w-1/3" : "hidden"}`}>
                  {editMode ? (
                    <div className="is-editmode">
                      <InputNumber
                        view="buttons"
                        value={freeItem.amount}
                        onUpdate={(value) => actions.onEditFreeItemAmount(freeItem.identifier, value)}
                      />
                    </div>
                  ) : (
                    <div className="is-not-editmode">&nbsp;</div>
                  )}
                </div>
                <div className={`${editMode ? "w-1/3" : "w-2/3"}`}>
                  <div className={`${editMode ? "py-1.5" : "py-1"} text-right ${showOnlyStatus && "hidden"}`}>
                    {FDN.I18n.t("pr.form.list.head.priceUnit.short.label")}: {FDN.formatNumber(freeItem.priceUnit || 0)}
                  </div>
                  <div
                    className={`${editMode ? "py-1.5" : "py-1"} text-right text-primary font-semibold ${
                      showOnlyStatus && "hidden"
                    }`}
                  >
                    {FDN.I18n.t("pr.form.list.head.priceTotal.short.label")}:{" "}
                    {freeItem.priceUnit && freeItem.perfectAmount
                      ? FDN.formatNumber(freeItem.priceUnit * freeItem.perfectAmount)
                      : FDN.formatNumber(0)}
                  </div>
                </div>
              </div>
            </div>
            {freeItem.description ? (
              <div className={`px-2 py-1.5 border-t ${showOnlyStatus && "opacity-50"}`}>
                <div className="text-left text-xs">
                  <FDN.Icon icon="comments-o" left /> {freeItem.description}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}

      {editMode === true && !showOnlyStatus && (
        <div className="__pr-freeitems-add text-center pt-4">
          <button className="clickable-add-button" onClick={() => actions.onSelectFreeItem("new")}>
            <FDN.Icon icon="plus-circle" left /> {FDN.I18n.t("pr.freeitems.buttons.add.label")}
          </button>
        </div>
      )}
    </div>
  );
};

export default PrFormFreeItemsListMobile;
