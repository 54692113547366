import { INotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { IEditLockStatus } from "src/components/context/NotificationsContext/types";
import StatusCode from "src/config/statuscodes";
import Config from "src/core/Config";
import { AccountsSearch, EmailTemplateState, IUserroleState } from "src/types/types";
import Api from "./Api";
import { Logger } from "src/core";
import { IProductCategory, IProducts, ISupplier } from "src/types/products.types";
import { IClient, IClientAddUser } from "src/types/clients.types";
import { IPurchaseRequisition, PurchaseRequisitionsListTabTypes } from "src/types/pr.types";

export default class AdminApi {
  public static adminDashboard(api: Api) {
    return api.get("admin.dashboard.index");
  }

  public static adminSettings(api: Api) {
    return api.get("admin.settings.index");
  }

  public static updateAdminSettings(api: Api, settings: { [key: string]: any }) {
    return api.post("admin.settings.index", null, { settings });
  }

  public static adminEmailTemplates(api: Api) {
    return api.get("admin.emailtemplates.index");
  }

  public static adminEmailTemplate(api: Api, emailTemplateIdentifier: string) {
    return api.get("admin.emailtemplates.show", { identifier: emailTemplateIdentifier });
  }

  public static updateAdminEmailTemplate(api: Api, emailTemplateIdentifier: string, emailTemplate: EmailTemplateState) {
    return api.post("admin.emailtemplates.show", { identifier: emailTemplateIdentifier }, { emailTemplate });
  }

  public static adminAccounts(api: Api, search: AccountsSearch) {
    return api.get("admin.accounts.index", search, { queryParams: true });
  }

  public static adminAccount(api: Api, identifier: string) {
    return api.get("admin.accounts.account", { identifier });
  }

  public static adminAccountLoginAttempts(api: Api, identifier: string) {
    return api.get("admin.accounts.loginattempts", { identifier });
  }

  public static adminAccountUserPermissionsStatus(api: Api, identifier: string) {
    return api.get("admin.accounts.userpermissionsstatus", { identifier });
  }

  public static updateAccountUserroles(api: Api, identifier: string, roles: string[]) {
    return api.post("admin.accounts.userpermissionsstatus", { identifier }, { roles });
  }

  public static adminDeleteAccount(api: Api, identifier: string) {
    return api.delete("admin.accounts.account", { identifier });
  }

  public static updateAdminAccount(
    api: Api,
    account: { [key: string]: any },
    sendConfirmationMailAfterCreate?: boolean
  ) {
    return api.post(
      "admin.accounts.account",
      { identifier: account.identifier },
      {
        account,
        sendconfirmationmail: sendConfirmationMailAfterCreate || false,
      }
    );
  }

  public static adminAccountResendConfirmationMail(api: Api, accountIdentifier: string) {
    return api.post("admin.accounts.mails.resendconfirmationmail", {
      identifier: accountIdentifier,
    });
  }

  public static adminAccountSendPasswordForgottenMail(api: Api, accountIdentifier: string) {
    return api.post("admin.accounts.mails.sendpasswordforgottenmail", {
      identifier: accountIdentifier,
    });
  }

  public static adminUserroles(api: Api) {
    return api.get("admin.userroles.index");
  }

  public static updateAdminUserrole(api: Api, userroleIdentifier: string, userrole: IUserroleState) {
    return api.post("admin.userroles.userrole", { identifier: userroleIdentifier }, { userrole });
  }

  public static deleteAdminUserrole(api: Api, userroleIdentifier: string) {
    return api.delete("admin.userroles.userrole", { identifier: userroleIdentifier });
  }

  public static getLogs(api: Api, type: string, affectedIdentifier: string) {
    return api.get("admin.logs", { type, affectedIdentifier });
  }

  public static async requestEditLock(
    api: Api,
    NOTIFICATIONS: INotificationsContext,
    type: string,
    affectedIdentifier: string
  ) {
    if (Config.isDebug()) Logger.info(`Requesting edit lock for [${type}-${affectedIdentifier}] ...`);
    return api.get("admin.editLock", { type, affectedIdentifier }).then((response) => {
      if (!response || !response.body) return;

      if (response.statusCode === StatusCode.EDITLOCK_LOCKED || response.statusCode === StatusCode.EDITLOCK_OWN) {
        NOTIFICATIONS.updateEditLock(response.body.editLockStatus as IEditLockStatus);
      }

      return response.statusCode;
    });
  }

  public static deleteEditLock(api: Api, type: string, affectedIdentifier: string) {
    return api.delete("admin.editLock", { type, affectedIdentifier });
  }

  public static getProductCategories(api: Api) {
    return api.get("admin.products.index");
  }

  public static saveProductCategory(api: Api, identifier: string, data: IProductCategory) {
    return api.post("admin.products.category", { identifier }, { category: data });
  }

  public static deleteProductCategory(api: Api, identifier: string) {
    return api.post("admin.products.archiveCategory", { identifier });
  }

  public static saveProduct(api: Api, identifier: string, data: IProductCategory) {
    return api.post("admin.products.show", { identifier }, { product: data });
  }

  public static archiveProduct(api: Api, identifier: string) {
    return api.post("admin.products.archive", { identifier });
  }

  public static importProducts(api: Api, data: IProducts) {
    return api.post("admin.products.import", {}, { products: data });
  }

  public static getSuppliers(api: Api) {
    return api.get("admin.suppliers.index");
  }

  public static saveSupplier(api: Api, identifier: string, data: ISupplier) {
    return api.post("admin.suppliers.show", { identifier }, { supplier: data });
  }

  public static deleteSupplier(api: Api, identifier: string) {
    return api.delete("admin.suppliers.show", { identifier });
  }

  public static getClients(api: Api) {
    return api.get("admin.clients.index");
  }

  public static saveClient(api: Api, identifier: string, data: IClient) {
    return api.post("admin.clients.show", { identifier }, { client: data });
  }

  public static clientCheckEmail(api: Api, email: string) {
    return api.post("admin.clients.checkemail", { email }, { email });
  }

  public static clientAddUserAccount(api: Api, identifier: string, user: IClientAddUser, type: string) {
    return api.post("admin.clients.adduser", { identifier }, { user, type });
  }

  public static clientRemoveUserAccount(api: Api, identifier: string, userIdentifier: string) {
    return api.post("admin.clients.removeuser", { identifier }, { userIdentifier });
  }

  public static clientSwitchToManager(api: Api, identifier: string, userIdentifier: string) {
    return api.post("admin.clients.switchtomanager", { identifier }, { userIdentifier });
  }

  public static clientSwitchToEmployee(api: Api, identifier: string, userIdentifier: string) {
    return api.post("admin.clients.switchtoemployee", { identifier }, { userIdentifier });
  }

  public static getPrs(api: Api, type: PurchaseRequisitionsListTabTypes) {
    return api.get("admin.purchaserequisitions.index", { type }, { queryParams: true });
  }

  public static getPr(api: Api, identifier: string) {
    return api.get("admin.purchaserequisitions.show", { identifier });
  }

  public static prChangeStatus(api: Api, identifier: string, newStatus: string, goingBack: boolean) {
    return api.post("admin.purchaserequisitions.changestatus", { identifier, newStatus }, { goingBack });
  }

  public static prUpdate(api: Api, identifier: string, pr: IPurchaseRequisition) {
    return api.post("admin.purchaserequisitions.show", { identifier }, { pr });
  }
}
