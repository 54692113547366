import { IPurchaseRequisition } from "src/types/pr.types";

export const getTotalPositions = (pr: IPurchaseRequisition): number => {
  let totalPositions = 0;

  if (pr.products) {
    for (const product of pr.products) {
      if (product.perfectAmount) totalPositions++;
    }
  }

  if (pr.freeItems) {
    for (const freeItem of pr.freeItems) {
      if (freeItem.perfectAmount) totalPositions++;
    }
  }

  return totalPositions;
};
