import { useState } from "react";
import * as FDN from "src/core";
import useServiceCore from "../CoreService";
import Papa from "papaparse";
import { Logger } from "@tschirpke/ptfdn-frontend-components";
import { IProduct, IProductCategories, IProducts } from "src/types/products.types";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";

export type CsvDataSchema = { [key: string]: string | number | undefined | null }[];

export interface TActionsAdminProductsCsv {
  onSelectFile: (file: File) => void;
  updateCategory: (index: number, categoryIdentifier: string) => void;
  importIsReady: () => boolean;
  reset: () => void;
  onImport: () => void;
}

const useServiceAdminProductsCsv = (categories: IProductCategories) => {
  const [view, setView] = useState<"upload" | "list" | "finished">("upload");
  const [error, setError] = useState<string | undefined>();
  const [csvData, setCsvData] = useState<IProducts>();

  const [selectedFile, setSelectedFile] = useState<File>();

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const onSelectFile = (file: File) => {
    setSelectedFile(file);
    parseFile(file);
  };

  const parseFile = (file?: File) => {
    const csvFile: File | undefined = file ? file : selectedFile;

    if (!csvFile) return [];

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        parseData(results.data as CsvDataSchema);
        setView("list");
      },
      error: function (error) {
        setError(error.message);
        Logger.error(error.message);
      },
    });
  };

  const parseData = (rawData: CsvDataSchema) => {
    const data: IProducts = [];

    for (const rawLine of rawData) {
      const product: IProduct = {
        identifier: "new",
        name: (rawLine.Name as string) || "",
        description: (rawLine.Beschreibung as string) || "",
        amount: (rawLine.Menge as number) || 0,
        unit: (rawLine.Einheit as string) || "",
        priceUnit: parsePrice(rawLine["Einzelpreis"] as string) || 0,
      };

      if ("Kategorie" in rawLine) {
        const category = categories.find((c) => c.name === rawLine.Kategorie);
        if (category) product.categoryIdentifier = category.identifier;
        else product.categoryIdentifier = "NONE";
      }

      data.push(product);
    }

    setCsvData(data);
  };

  const parsePrice = (rawPrice: string): number => {
    let price = 0;

    rawPrice = rawPrice.replace("€", "").replace("EUR", "").replace(",", ".").trim();
    price = parseFloat(rawPrice) || 0;

    return price;
  };

  const updateCategory = (index: number, categoryIdentifier: string) => {
    if (!csvData) return;

    const updatedCsvData = csvData.map((row, rowIndex) => {
      if (rowIndex === index) row.categoryIdentifier = categoryIdentifier;
      return row;
    });

    setCsvData(updatedCsvData);
  };

  const importIsReady = (): boolean => {
    if (!csvData) return false;

    let importReady = true;

    for (const row of csvData) {
      if (!row.categoryIdentifier || row.categoryIdentifier === "NONE") importReady = false;
    }

    return importReady;
  };

  const reset = () => {
    setCsvData(undefined);
    setView("upload");
  };

  const onImport = () => {
    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminProducts.css.list.onImport.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.hideDialog();

            if (csvData) {
              NOTIFICATIONS.showSaving({ type: "save" });
              AdminApi.importProducts(api, csvData).then((response) => {
                NOTIFICATIONS.hideSaving();
                if (response?.statusCode === StatusCode.SUCCESS) {
                  setView("finished");
                }
              });
            }
          },
        },
      ],
    });
  };

  const actions: TActionsAdminProductsCsv = {
    onSelectFile,
    updateCategory,
    importIsReady,
    reset,
    onImport,
  };

  return {
    APP,
    view,
    csvData,
    error,
    actions,
  };
};

export default useServiceAdminProductsCsv;
