import React from "react";
import { IAccountState, LoginAttemptsState, TActions } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import AdminApi from "src/api/AdminApi";

const useServiceLoginAttempts = (account: IAccountState | null) => {
  const [loginAttempts, setLoginAttempts] = React.useState<LoginAttemptsState>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminAccountLoginAttempts(api, account?.identifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const loginAttempts = response?.body?.loginAttempts as LoginAttemptsState;
        setLoginAttempts(loginAttempts);
      }
    });
  };

  const actions: TActions = {};

  return { actions, loginAttempts };
};

export default useServiceLoginAttempts;
