import * as React from "react";
import * as FDN from "src/core";
import useServiceCookieBanner, {
  CookieBannerView,
  ICookieSettings,
} from "src/services/dataprivacy/CookieBannerService";
import UrlService from "src/core/UrlService";
import { TActions } from "src/types/types";

const CookieBanner: React.FunctionComponent = () => {
  const { showCookieBanner, cookieSettings, view, actions } = useServiceCookieBanner();

  if (!cookieSettings || showCookieBanner === false) return null;

  switch (view) {
    case CookieBannerView.SMALL:
      return <ViewSmall cookieSettings={cookieSettings} actions={actions} />;
    case CookieBannerView.MEDIUM:
      return <ViewMedium cookieSettings={cookieSettings} actions={actions} />;
    case CookieBannerView.SETTINGS:
      return <ViewSettings cookieSettings={cookieSettings} actions={actions} />;
  }

  return null;
};

interface IView {
  cookieSettings: ICookieSettings;
  actions: TActions;
}

const ViewSmall: React.FunctionComponent<IView> = ({ actions }) => {
  return (
    <button
      className="__cookiebanner __cookiebanner-small"
      onClick={() => actions.switchView(CookieBannerView.SETTINGS)}
    >
      {FDN.I18n.t("cookiebanner.small.label")}
    </button>
  );
};

const ViewMedium: React.FunctionComponent<IView> = ({ actions }) => {
  return (
    <div className="__cookiebanner __cookiebanner-medium">
      <div className="__cookiebanner-title">{FDN.I18n.t("cookiebanner.medium.title")}</div>
      <div
        className="__cookiebanner-text"
        dangerouslySetInnerHTML={{
          __html: FDN.I18n.t("cookiebanner.medium.text", {
            dataprivacy: `<a href="${UrlService.url("dataprivacy")}">${FDN.I18n.t(
              "cookiebanner.medium.text.dataprivacy"
            )}</a>`,
          }),
        }}
      />
      <div className="__cookiebanner-text2">{FDN.I18n.t("cookiebanner.medium.text2")}</div>
      <div className="__cookiebanner-buttons">
        <button
          className="small hollow secondary button"
          onClick={() => actions.switchView(CookieBannerView.SETTINGS)}
        >
          {FDN.I18n.t("cookiebanner.buttons.select")}
        </button>
        <button
          className="small hollow secondary button"
          onClick={() => actions.acceptOnlyEssential()}
        >
          {FDN.I18n.t("cookiebanner.buttons.essential")}
        </button>
        <button className="small success button" onClick={() => actions.acceptAllSettings()}>
          {FDN.I18n.t("cookiebanner.buttons.all")}
        </button>
      </div>
    </div>
  );
};
const ViewSettings: React.FunctionComponent<IView> = ({ cookieSettings, actions }) => {
  return (
    <div className="__cookiebanner __cookiebanner-settings">
      <div className="__cookiebanner-title">{FDN.I18n.t("cookiebanner.settings.title")}</div>
      <div className="__cookiebanner-settings-settings">
        <ul>
          {Object.keys(cookieSettings).map((cookieSettingKey) => {
            if (cookieSettingKey === "saved") return null;

            return (
              <li key={cookieSettingKey}>
                <label>
                  <input
                    type="checkbox"
                    checked={cookieSettings[cookieSettingKey as keyof ICookieSettings] || false}
                    disabled={cookieSettingKey === "essential"}
                    onChange={() => actions.toggleSetting(cookieSettingKey)}
                  />
                  {FDN.I18n.t(`cookiebanner.settings.${cookieSettingKey}`)}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="__cookiebanner-text2">{FDN.I18n.t("cookiebanner.medium.text2")}</div>
      <div className="__cookiebanner-buttons">
        <button
          className="small hollow secondary button"
          onClick={() => actions.acceptOnlyEssential()}
        >
          {FDN.I18n.t("cookiebanner.buttons.essential")}
        </button>
        <button
          className="small hollow secondary button"
          onClick={() => actions.acceptAllSettings()}
        >
          {FDN.I18n.t("cookiebanner.buttons.all")}
        </button>
        <button className="small success button" onClick={() => actions.saveSettings()}>
          {FDN.I18n.t("cookiebanner.buttons.save")}
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
