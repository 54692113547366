import * as React from "react";
import * as FDN from "src/core";
import { IPurchaseRequisition } from "src/types/pr.types";

export const prStatus = {
  CREATED: {
    icon: "edit",
  },
  SENT: {
    icon: "envelope-o",
  },
  PROCESS: {
    icon: "refresh",
  },
  PLACED: {
    icon: "archive",
  },
  DELIVERY: {
    icon: "truck",
  },
  COMPLETED: {
    icon: "check",
  },
  CANCELED: {
    icon: "times",
  },
};

interface IPrStatusProps {
  pr: IPurchaseRequisition;
  location: "overview" | "view";
}

const PrStatus: React.FunctionComponent<IPrStatusProps> = ({ pr, location }) => {
  const isDone = (statusKey: string) => {
    let done = false;

    if (statusKey === "CREATED" && pr.createdAt) done = true;
    else if (statusKey === "SENT" && pr.statusDateSENT) done = true;
    else if (statusKey === "PROCESS" && pr.statusDatePROCESS) done = true;
    else if (statusKey === "PLACED" && pr.statusDatePLACED) done = true;
    else if (statusKey === "DELIVERY" && pr.statusDateDELIVERY) done = true;
    else if (statusKey === "CANCELED" && pr.status === "CANCELED" && pr.statusDateCANCELED)
      done = true;
    else if (statusKey === "COMPLETED" && pr.statusDateCOMPLETED) done = true;

    return done;
  };

  return (
    <div className={`__pr-status __pr-status-location-${location}`}>
      {Object.keys(prStatus).map((status) => {
        return (
          <div
            className={`__pr-status-status-container status-${status} ${
              isDone(status) ? `done` : ``
            }`}
            key={status}
          >
            <div className="__pr-status-status-bubble">
              <FDN.Icon icon={prStatus[status as keyof typeof prStatus].icon} />
            </div>

            <div className="__pr-status-status-label">
              <FDN.Icon icon="check" /> {FDN.I18n.t(`pr.status.${status}.label`)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrStatus;
