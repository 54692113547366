import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumn,
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  IFreeItems,
  IFreeItem,
  IPrProductStatusType,
} from "src/types/pr.types";
import { IProduct, IProducts } from "src/types/products.types";

const prFormVisibleColumns = [
  "amount",
  "productName",
  "unit",
  "productCategory",
  "supplier",
  "priceUnit",
  "priceTotal",
];

interface IPrPdfFormFreeItemsListProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItems?: IFreeItems;
  hideHead: boolean;
  showSupplier: boolean;
}

const PrPdfFormFreeItemsList: React.FunctionComponent<IPrPdfFormFreeItemsListProps> = ({
  showOnlyStatus,
  pr,
  freeItems,
  hideHead,
  showSupplier,
}) => {
  return (
    <div className="__pr-form-products">
      {hideHead === true ? null : (
        <div className="__pr-form-product-head">
          <FDN.Grid full>
            <FDN.Row>
              {prFormVisibleColumns.map((type) => {
                return <FreeItemsListHeadItemColumn key={type} type={type} />;
              })}
            </FDN.Row>
          </FDN.Grid>
        </div>
      )}
      <div className="__pr-form-product-body">
        <ul>
          {freeItems?.map((freeItem) => {
            return (
              <li key={freeItem.identifier}>
                <FreeItemsListItem
                  showOnlyStatus={showOnlyStatus}
                  pr={pr}
                  freeItem={freeItem}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface IPrPdfFormFreeItemsListItemProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItem: IFreeItem;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
}

const FreeItemsListItem: React.FunctionComponent<IPrPdfFormFreeItemsListItemProps> = ({
  showOnlyStatus,
  pr,
  freeItem,
  prFormVisibleColumns,
  showSupplier,
}) => {
  return (
    <div className="__pr-form-product">
      <FDN.Grid full>
        <FDN.Row>
          {prFormVisibleColumns.map((type) => {
            return (
              <FreeItemsListBodyItemColumn
                key={type}
                type={type}
                showOnlyStatus={showOnlyStatus}
                pr={pr}
                freeItem={freeItem}
                showSupplier={showSupplier}
              />
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IPrPdfFormFreeItemsListHeadItemColumnProps {
  type: PrFormVisibleColumn;
}

const FreeItemsListHeadItemColumn: React.FunctionComponent<
  IPrPdfFormFreeItemsListHeadItemColumnProps
> = ({ type }) => {
  const sm = 24;
  let md = 3;
  let textAlign = "center";

  switch (type) {
    case "amount":
      break;
    case "productId":
      textAlign = "left";
      break;
    case "productName":
      md = 6;
      textAlign = "left";
      break;
    case "productCategory":
      md = 6;
      textAlign = "left";
      break;
    case "priceUnit":
      break;
    case "priceTotal":
      break;
  }

  const content = FDN.I18n.t(`pr.form.list.head.${type}.label`);

  return (
    <FDN.Cell sm={md} md={md}>
      <div className={`text-${textAlign}`}>{content}</div>
    </FDN.Cell>
  );
};

interface IPrPdfFormFreeItemsListBodyItemColumnProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  freeItem: IFreeItem;
  type: PrFormVisibleColumn;
  showSupplier: boolean;
}

const FreeItemsListBodyItemColumn: React.FunctionComponent<
  IPrPdfFormFreeItemsListBodyItemColumnProps
> = ({ showOnlyStatus, pr, freeItem, type, showSupplier }) => {
  let content = <></>;
  let textAlign = "left";
  const sm = 24;
  let md = 3;

  switch (type) {
    case "amount":
      content = (
        <div className={`__pr-form-list-column type-input ${showOnlyStatus && "opacity-50"}`}>
          <div className="text-right">
            {showOnlyStatus ? (
              <>
                {freeItem?.status && (
                  <>
                    {freeItem?.status && freeItem?.status[showOnlyStatus]
                      ? freeItem?.status[showOnlyStatus] || 0
                      : 0}{" "}
                    /{" "}
                  </>
                )}
              </>
            ) : (
              <>
                {freeItem?.status && (freeItem?.perfectAmount || 0) < (freeItem?.amount || 0) && (
                  <>{freeItem?.perfectAmount || 0} / </>
                )}
              </>
            )}
            {freeItem?.amount || ""}
          </div>
        </div>
      );
      md = 2;
      break;
    case "productName":
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>
          <div className="product-name">
            <strong>{freeItem.name}</strong>
          </div>
          <div className="product-description">{freeItem.description}</div>
        </div>
      );
      md = showSupplier ? 6 : 9;
      break;
    case "unit":
      textAlign = "right";
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>
          {freeItem.unit}
        </div>
      );
      break;
    case "productCategory":
      md = 4;
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>
          &nbsp;
        </div>
      );
      break;
    case "supplier":
      if (!showSupplier) return null;
      textAlign = "left";
      md = 3;
      break;
    case "priceUnit":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          {FDN.formatNumber(freeItem.priceUnit || 0)}
        </div>
      );
      break;
    case "priceTotal":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          {freeItem.priceUnit && freeItem.amount
            ? FDN.formatNumber(freeItem.priceUnit * (freeItem.perfectAmount || 0))
            : ""}
        </div>
      );
      break;
  }

  return (
    <FDN.Cell sm={md} md={md} className={`text-${textAlign}`}>
      {content}
    </FDN.Cell>
  );
};

export default PrPdfFormFreeItemsList;
