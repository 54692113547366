import * as React from "react";
import * as FDN from "src/core";
import { userHasPermission } from "src/core/AdminService/helpers";
import useServiceCore from "src/services/CoreService";
import { TActionsAdminProducts } from "src/services/admin/AdminProductsService";
import { IProductCategories, IProducts, ISuppliers } from "src/types/products.types";
import ProductsList from "./ProductsList";
import { PrFormSearchProps } from "src/types/pr.types";
import ProductsSearch from "./ProductsSearch";

interface IProductsByCategoryListProps {
  categories: IProductCategories;
  selectedCategory?: string;
  search: PrFormSearchProps;
  actions: TActionsAdminProducts;
}

const ProductsByCategoryList: React.FunctionComponent<IProductsByCategoryListProps> = ({
  categories,
  selectedCategory,
  search,
  actions,
}) => {
  const products = actions.getFilteredProducts();
  const category = categories.find((c) => c.identifier === selectedCategory);

  return (
    <div className="products-categories-list">
      <div className="products-categories-list-categories">
        <FDN.Box noPadding>
          <ul>
            <li className="categories-title">{FDN.I18n.t("adminProducts.categories.title")}</li>
            <li>
              <button
                className={`${!selectedCategory ? `selected` : ``}`}
                onClick={() => actions.selectCategory(undefined)}
              >
                {FDN.I18n.t("adminProducts.categories.allProducts")}
              </button>
            </li>
            {categories.map((category) => {
              return (
                <li key={category.identifier}>
                  <button
                    className={`${selectedCategory === category.identifier ? `selected` : ``}`}
                    onClick={() => actions.selectCategory(category.identifier)}
                  >
                    {category.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </FDN.Box>
      </div>
      <div className="products-categories-list-products">
        <FDN.Box noPadding>
          {products ? (
            <>
              <div className="__box-padding">
                <h2 style={{ margin: 0 }}>
                  {category && (
                    <div className="float-right">
                      <button
                        className="small primary hollow button"
                        onClick={() => actions.showPopupEditCategory(category)}
                      >
                        <FDN.Icon icon="edit" />
                      </button>
                    </div>
                  )}
                  {!selectedCategory
                    ? FDN.I18n.t("adminProducts.categories.allProducts")
                    : category?.name}
                </h2>
              </div>
              <ProductsSearch categories={categories} search={search} actions={actions} />
              <div className="__box-padding">
                <Products products={products} actions={actions} />
              </div>
            </>
          ) : (
            <div className="__box-padding">
              <div className="text-center">{FDN.I18n.t("adminProducts.noCategorySelected")}</div>
            </div>
          )}
        </FDN.Box>
      </div>
    </div>
  );
};

interface IProductsProps {
  products: IProducts | false | undefined;
  actions: TActionsAdminProducts;
}

const Products: React.FunctionComponent<IProductsProps> = ({ products, actions }) => {
  const { APP } = useServiceCore();
  const user = APP.getUser();

  return (
    <div>
      {!products || products.length === 0 ? (
        <div className="text-center">
          <p>
            <em>{FDN.I18n.t("adminProducts.noProducts")}</em>
          </p>
          {userHasPermission(user, "adminProductsAdd") && (
            <button className="primary button" onClick={() => actions.showPopupEditProduct("new")}>
              <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminProducts.buttons.add.label")}
            </button>
          )}
        </div>
      ) : (
        <ProductsList products={products} actions={actions} />
      )}
    </div>
  );
};

export default ProductsByCategoryList;
