import * as React from "react";
import * as FDN from "src/core";
import { IPurchaseRequisition, TActionsPr } from "src/types/pr.types";

interface IPrFormOptionsProps {
  pr: IPurchaseRequisition;
  editMode: boolean;
  actions: TActionsPr;
}

const PrFormOptions: React.FunctionComponent<IPrFormOptionsProps> = ({ pr, editMode, actions }) => {
  return (
    <div className="__pr-form-options">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <OptionsDelivery pr={pr} editMode={editMode} actions={actions} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

const OptionsDelivery: React.FunctionComponent<IPrFormOptionsProps> = ({
  pr,
  editMode,
  actions,
}) => {
  const selectValuesDelivery = {
    NEXT_REGULAR: FDN.I18n.t("pr.options.delivery.select.NEXT_REGULAR.label"),
    DESIRED: FDN.I18n.t("pr.options.delivery.select.DESIRED.label"),
    ASAP: FDN.I18n.t("pr.options.delivery.select.ASAP.label"),
  };

  return (
    <div className="__pr-form-options-delivery">
      <FDN.Box>
        <h3>{FDN.I18n.t("pr.options.delivery.title")}</h3>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="select"
                value={pr.desiredDeliveryType}
                showValue={
                  pr.desiredDeliveryType
                    ? FDN.I18n.t(`pr.options.delivery.select.${pr.desiredDeliveryType}.label`)
                    : ""
                }
                selectValues={selectValuesDelivery}
                label={FDN.I18n.t("pr.options.delivery.delivery.label")}
                editMode={editMode}
                onUpdate={(value) => actions.updatePr("desiredDeliveryType", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              {pr.desiredDeliveryType === "DESIRED" ? (
                <FDN.Input
                  type="date"
                  value={pr.desiredDeliveryDate || undefined}
                  showValue={
                    pr.desiredDeliveryDate ? FDN.formatDate(pr.desiredDeliveryDate) : undefined
                  }
                  label={FDN.I18n.t("pr.options.delivery.desireddate.label")}
                  editMode={editMode}
                  onUpdate={(value) => actions.updatePr("desiredDeliveryDate", value)}
                />
              ) : null}
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} className="text-center">
              <FDN.Input
                type="checkbox"
                value={pr.informClientAboutDeliveryDate || false}
                label={FDN.I18n.t("pr.options.delivery.informClient.label")}
                editMode={editMode}
                onUpdate={(value) => actions.updatePr("informClientAboutDeliveryDate", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
      {/* {pr.statusDateARRIVED ? (
        <FDN.CalloutBox type="success">
          <div>
            <strong>{FDN.I18n.t("pr.isArrived.title")}</strong>
          </div>
          <div>
            {FDN.I18n.t("pr.isArrived.text", {
              date: FDN.formatDateTime(pr.statusDateARRIVED, { useHumanReadableDate: false }),
            })}
          </div>
        </FDN.CalloutBox>
      ) : null} */}
    </div>
  );
};

export default PrFormOptions;
