import * as React from "react";
import * as FDN from "src/core";
import { TActionsPr, IPurchaseRequisition } from "src/types/pr.types";
import { IProductCategories } from "src/types/products.types";
import PrFormPriceTotal from "./PrFormPriceTotal";
import PrPdfFormProductsList from "./PrPdfFormProductsList";
import PrPdfFreeItems from "./PrPdfFreeItems";

const prFormVisibleColumns = [
  "amount",
  "productName",
  "unit",
  "productCategory",
  "supplier",
  "priceUnit",
  "priceTotal",
];

interface IPrPdfFormProps {
  pr: IPurchaseRequisition;
  categories?: IProductCategories;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrPdfForm: React.FunctionComponent<IPrPdfFormProps> = ({
  pr,
  categories,
  showSupplier,
  actions,
}) => {
  const editMode = false;

  return (
    <div className="__pr-form">
      {!categories || categories.length === 0 ? (
        <p>
          <em>{FDN.I18n.t("pr.noProducts")}</em>
        </p>
      ) : (
        <>
          <div className="mb-4">
            <PrPdfFormProductsList
              products={actions.getProductsForList(undefined, true)}
              pr={pr}
              editMode={editMode}
              showSupplier={showSupplier}
              actions={actions}
            />

            <PrPdfFreeItems
              pr={pr}
              prFormVisibleColumns={prFormVisibleColumns}
              showSupplier={showSupplier}
              actions={actions}
            />
          </div>

          {(actions.getProductsForList("NOT_IN_STOCK", true).length > 0 ||
            actions.getFreeItemsForList("NOT_IN_STOCK").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.NOT_IN_STOCK.title")}
              </h3>
              <div className="opacity-50 mb-4">
                <PrPdfFormProductsList
                  showOnlyStatus="NOT_IN_STOCK"
                  products={actions.getProductsForList("NOT_IN_STOCK", true)}
                  pr={pr}
                  editMode={editMode}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrPdfFreeItems
                  showOnlyStatus="NOT_IN_STOCK"
                  pr={pr}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          {(actions.getProductsForList("SHORTAGE", true).length > 0 ||
            actions.getFreeItemsForList("SHORTAGE").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.SHORTAGE.title")}
              </h3>
              <div className="opacity-50 mb-4">
                <PrPdfFormProductsList
                  showOnlyStatus="SHORTAGE"
                  products={actions.getProductsForList("SHORTAGE", true)}
                  pr={pr}
                  editMode={editMode}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrPdfFreeItems
                  showOnlyStatus="SHORTAGE"
                  pr={pr}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          {(actions.getProductsForList("QUALITY", true).length > 0 ||
            actions.getFreeItemsForList("QUALITY").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.QUALITY.title")}
              </h3>
              <div className="opacity-50 mb-4">
                <PrPdfFormProductsList
                  showOnlyStatus="QUALITY"
                  products={actions.getProductsForList("QUALITY", true)}
                  pr={pr}
                  editMode={editMode}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrPdfFreeItems
                  showOnlyStatus="QUALITY"
                  pr={pr}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          <PrFormPriceTotal pr={pr} />
        </>
      )}
    </div>
  );
};

export default PrPdfForm;
