import React, { useState } from "react";
import * as FDN from "src/core";
import {
  AccountsSearch,
  IAccountsState,
  FOnSearchCompleted,
  FOnSearchStarted,
  TActions,
} from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import useServiceAdminListSearch from "./AdminListSearchService";

const defaultSearch: AccountsSearch = {
  text: "",
  sort: "",
};

const sortOptions = [
  "createdat_asc",
  "createdat_desc",
  "firstname_asc",
  "firstname_desc",
  "lastname_asc",
  "lastname_desc",
  "email_asc",
  "email_desc",
  "lastvisit_asc",
  "lastvisit_desc",
];

const useServiceAdminAccountsService = () => {
  const [accounts, setAccounts] = React.useState<IAccountsState>();
  const [totalFound, setTotalFound] = useState<number>();
  const [listLoading, setListLoading] = useState(false);

  const { APP } = useServiceCore();

  const onSearchStarted: FOnSearchStarted = () => {
    setListLoading(true);
  };

  const onSearchCompleted: FOnSearchCompleted = (response) => {
    if (response?.statusCode === StatusCode.SUCCESS) {
      setAccounts(response?.body?.accounts as IAccountsState);
      setTotalFound(response?.body?.totalFound as number);
      setListLoading(false);
    }
  };

  const { search, updateSearch, doReset, doSearch, onSortSelect } = useServiceAdminListSearch(
    "adminAccounts",
    defaultSearch,
    onSearchStarted,
    onSearchCompleted
  );

  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    APP.setPageTitle(FDN.I18n.t("adminAccounts.pageTitles.overview"));
  };

  const actions: TActions = {
    updateSearch,
    doReset,
    doSearch,
    onSortSelect,
  };

  return { actions, accounts, user, search, totalFound, sortOptions, listLoading };
};

export default useServiceAdminAccountsService;
