import { ISignupInputValues, IPasswordForgottenInputValues } from "src/types/types";
import Api from "./Api";

export default class AuthApi {
  public static attemptLogin(api: Api, email: string, password: string) {
    return api.attemptLogin(email, password);
  }

  public static attemptSignup(api: Api, formData: ISignupInputValues) {
    return api.post("auth.signup", {}, formData);
  }

  public static attemptReset(api: Api, formData: IPasswordForgottenInputValues) {
    return api.post("auth.passwordForgotten", {}, formData);
  }

  public static checkToken(api: Api, token: string) {
    return api.get(`auth.checkToken`, { token });
  }

  public static setPassword(api: Api, token: string, password: string) {
    return api.post("auth.setPassword", { token }, { password });
  }
}
