import * as React from "react";
import { IAdminListSchema } from "./types";
import AdminListBody from "./AdminListBody";
import AdminListHead from "./AdminListHead";
import useServiceAdminListService from "src/services/admin/AdminListService";
import Pagination from "./Pagination";
import SelectPerPage from "./SelectPerPage";
import SortBy from "./SortBy";
import { SearchSort } from "src/types/types";

interface IAdminListProps {
  schema?: IAdminListSchema;
  hideHead?: boolean;
  hideBody?: boolean;
  hidePagination?: boolean;
  sortLineLeftContent?: React.ReactNode;
  tabs?: React.ReactNode;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
  loading?: boolean;
  page?: number;
  showPagesOn?: "top" | "bottom" | "both";
}

const AdminList: React.FunctionComponent<IAdminListProps> = (props) => {
  let { showPagesOn } = props;
  const {
    schema,
    hideHead,
    hideBody,
    loading,
    hidePagination,
    sortLineLeftContent,
    tabs,
    sortBy,
    sortOptions,
    sortOnSelect,
  } = props;

  const selectedPage = props.page;

  const { actions, page, pagesTotal, paginate } = useServiceAdminListService({
    schema,
    page: selectedPage,
  });

  if (!schema) return null;
  if (!showPagesOn) showPagesOn = "both";

  return (
    <>
      <div className="__adminlist-sortline">
        <div className="__adminlist-sortline-left">{sortLineLeftContent}</div>
        {sortBy && sortOptions && sortOnSelect ? (
          <SortBy sort={sortBy} options={sortOptions} onSelect={sortOnSelect} />
        ) : null}
        {!hidePagination && paginate && pagesTotal > 0 ? (
          <SelectPerPage selected={paginate} onSelect={actions.selectPerPage} />
        ) : null}
      </div>
      {tabs}

      {!hidePagination && paginate && pagesTotal > 0 && ["top", "both"].includes(showPagesOn) ? (
        <>
          <Pagination page={page} position="top" pagesTotal={pagesTotal} selectPage={actions.selectPage} />
        </>
      ) : null}
      {hideHead !== true ? <AdminListHead schema={schema} /> : null}
      {hideBody !== true ? <AdminListBody schema={schema} loading={loading} page={page} paginate={paginate} /> : null}
      {paginate && pagesTotal > 0 && ["bottom", "both"].includes(showPagesOn) ? (
        <Pagination page={page} position="bottom" pagesTotal={pagesTotal} selectPage={actions.selectPage} />
      ) : null}
    </>
  );
};

export default AdminList;
