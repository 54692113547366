import * as React from "react";
import * as FDN from "src/core";
import { userHasPermission } from "src/core/AdminService/helpers";
import { IUserroleState, IUserrolesState } from "src/types/types";
import useServiceAdminUserroleService from "src/services/admin/UserroleService";
import AdminApi from "src/api/AdminApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import EditLockMessage from "../editlock/EditLockMessage";
import UserRolePermissions from "./parts/UserRolePermissions";
import UserRoleMembers from "./parts/UserRoleMembers";
import { useNavigate } from "react-router-dom";

const editLockType = "userrole";

const tabs: FDN.ITabs = {
  permissions: {
    label: FDN.I18n.t("adminUserroles.tabs.permissions.label"),
    icon: "key",
  },
  members: {
    label: FDN.I18n.t("adminUserroles.tabs.members.label"),
    icon: "user",
    hideOnNew: true,
  },
};

const defaultTab = "permissions";

interface IAdminUserRoleProps {
  userrole: IUserroleState;
  onUpdate?: (userroles: IUserrolesState) => void;
  onClose?: () => void;
}

const AdminUserRole: React.FunctionComponent<IAdminUserRoleProps> = (props) => {
  const [editMode, setEditMode] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const navigate = useNavigate();

  const { APP, api, NOTIFICATIONS } = useServiceCore();
  const { actions, user, userroleData, permissionGroups, language } =
    useServiceAdminUserroleService();

  const appI18n = APP.getI18n();
  const availableLanguages =
    appI18n && appI18n.availableLanguages ? appI18n.availableLanguages : undefined;

  React.useEffect(() => {
    if (!userHasPermission(user, "adminUserRolesEdit")) return;

    AdminApi.requestEditLock(api, NOTIFICATIONS, editLockType, userroleData?.identifier).then(
      (result) => {
        if (result === StatusCode.EDITLOCK_OWN) setEditMode(true);
      }
    );
  }, []);

  React.useEffect(() => {
    actions.refreshUserrole(props.userrole);
  }, [props.userrole]);

  if (!userroleData) return <FDN.Loading />;

  return (
    <FDN.Popup
      size="large"
      onClose={() => {
        actions.onCancel(props.onClose);
      }}
    >
      <div className="admin-userroles-userrole">
        <h3>
          {userroleData.identifier === "new"
            ? FDN.I18n.t("adminUserroles.popup.title.new")
            : userroleData.name[language]}
        </h3>

        {NOTIFICATIONS.editLock && <EditLockMessage editLockStatus={NOTIFICATIONS.editLock} />}

        <FDN.Box small>
          <p>
            <strong>{FDN.I18n.t(`adminUserroles.permissions.name.title`)}</strong>
          </p>
          <FDN.Input
            type="i18ntext"
            editMode={editMode}
            value={props?.userrole?.name}
            options={{
              availableLanguages,
            }}
            onUpdate={(value) => actions.onEdit("name", value)}
          />
        </FDN.Box>

        <FDN.Tabs
          updateHistory={false}
          editMode={editMode}
          tabs={tabs}
          selected={selectedTab}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />

        <FDN.TabsContent>
          {selectedTab === "permissions" && (
            <UserRolePermissions
              editMode={editMode}
              userroleData={userroleData}
              actions={actions}
              permissionGroups={permissionGroups}
            />
          )}
          {selectedTab === "members" && <UserRoleMembers members={props.userrole?.members} />}
        </FDN.TabsContent>
      </div>
      {editMode && (
        <FDN.FormButtons
          onCancel={() => {
            actions.onCancel(props.onClose);
          }}
          onSave={() => {
            actions.onSave(props.onUpdate, props.onClose);
          }}
          onDelete={
            userHasPermission(user, "adminUserRolesDelete") && !userroleData.internalKey
              ? () => {
                  actions.onDelete(props.onUpdate, props.onClose);
                }
              : undefined
          }
        />
      )}
    </FDN.Popup>
  );
};

export default AdminUserRole;
