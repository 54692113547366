import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { SearchSort } from "src/types/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { IClients } from "src/types/clients.types";
import { TActionsAdminClients } from "src/services/admin/AdminClientsService";
import { getAddressInOneLine } from "src/core/helpers/helpers";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 3 },
    textAlign: "right",
    content: FDN.I18n.t("adminClients.list.head.clientNr.title"),
  },
  {
    size: {
      md: 3,
    },
    content: <></>,
  },
  {
    size: {
      md: 11,
    },
    content: FDN.I18n.t("adminClients.list.head.name.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="star" />,
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="users" />,
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="lock" />,
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (clients: IClients, actions: TActionsAdminClients) => {
  if (!clients) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const client of clients) {
    if (!client) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => actions.showPopupEdit(client);

    row.columns[0].content = <>{client.clientNr}</>;

    row.columns[1].content = (
      <div className="admin-clients-list-logo">
        {client.logoUrl ? <img src={client.logoUrl} alt="" /> : null}
      </div>
    );

    row.columns[2].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">{client.name}</div>
          <div className="__adminlist-name-subtitle">{getAddressInOneLine(client)}</div>
        </div>
      </>
    );

    row.columns[3].content = <>{client.managers?.length || 0}</>;
    row.columns[4].content = <>{client.employees?.length || 0}</>;

    row.columns[5].content = (
      <>
        {client.locked === true ? (
          <span className="error-text">
            <FDN.Icon icon="lock" />
          </span>
        ) : (
          <span className="success-text">
            <FDN.Icon icon="unlock" />
          </span>
        )}
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IClientsListProps {
  clients: IClients;
  actions: TActionsAdminClients;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const ClientsList: React.FunctionComponent<IClientsListProps> = ({
  clients,
  actions,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (clients) {
      setAdminListSchema(buildAdminList(clients as IClients, actions));
      setListLoading(false);
    }
  }, [clients]);

  if (!clients) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default ClientsList;
