import * as React from "react";
import { I18n } from "src/core";
import { PrsFilter } from "src/services/PrsService";

interface IPrOverviewFilterProps {
  filter: PrsFilter;
  updateFilter: (key: string, value: string) => void;
}

const PrOverviewFilter = ({ filter, updateFilter }: IPrOverviewFilterProps) => {
  return (
    <div className="__prs-filter">
      <input
        type="text"
        value={filter.text || ""}
        placeholder={I18n.t("pr.filter.text.placeholder")}
        onChange={(e) => {
          updateFilter("text", e.target.value);
        }}
      />
    </div>
  );
};

export default PrOverviewFilter;
