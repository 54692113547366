import React from "react";
import * as FDN from "src/core";
import { FOnCancel, FOnSave, SettingsState, TActions, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import AdminApi from "src/api/AdminApi";
import { userHasPermission } from "src/core/AdminService/helpers";
import { showDefaultCancelDialog } from "src/components/main/Dialog/service";

const useServiceAdminSettings = () => {
  const [editMode, setEditMode] = React.useState(false);
  const [settings, setSettings] = React.useState<SettingsState | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const appI18n = APP.getI18n();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminSettings(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setSettings(response?.body?.settings as SettingsState);
        APP.setPageTitle(FDN.I18n.t("adminSettings.pageTitle"));

        if (response?.user && userHasPermission(response?.user, "adminSettingsEdit"))
          setEditMode(true);
      }
    });
  };

  const onEdit: TFunction = (property: string, value: any) => {
    if (!settings) return;

    settings.forEach((s, index) => {
      if (s.key === property) {
        settings[index].value = value;
        setSettings([...settings]);
        setUnsavedChanges(true);
      }
    });
  };

  const onCancel: FOnCancel = () => {
    const doConfirm = () => window.location.reload();
    showDefaultCancelDialog(NOTIFICATIONS, unsavedChanges, doConfirm);
  };

  const onSave: FOnSave = () => {
    if (!settings) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.updateAdminSettings(api, settings).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const setting = settings.find((s) => s.key === "defaultLanguage");
        if (setting && setting.value) FDN.I18n.setLanguage(setting.value);

        NOTIFICATIONS.hideSaving();

        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminSettings.onSave.success.title"),
          FDN.I18n.t("adminSettings.onSave.success.text")
        );
      }
    });
  };

  const actions: TActions = {
    onEdit,
    onCancel,
    onSave,
  };

  return { actions, appI18n, settings, editMode, unsavedChanges };
};

export default useServiceAdminSettings;
