import * as React from "react";
import * as FDN from "src/core";
import Api from "src/api/Api";
import UserApi from "src/api/UserApi";
import StatusCode from "src/config/statuscodes";
import Config from "src/core/Config";
import { IPasswordSetInputValues, TActions, IUserSettingsState } from "src/types/types";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import PasswordSetForm from "../myaccount/PasswordSetForm";

interface ITabSecurityProps {
  userSettings: IUserSettingsState;
  actions: TActions;
}

const TabSecurity: React.FunctionComponent<ITabSecurityProps> = (props) => {
  const { actions } = props;

  const [popupChangePasswordVisible, setPopupChangePasswordVisible] = React.useState(false);

  const onPasswordPopupShow = () => {
    setPopupChangePasswordVisible(true);
  };

  const onPasswordPopupClose = () => {
    setPopupChangePasswordVisible(false);
  };

  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <FDN.FormGroup title={FDN.I18n.t("usersettings.security.form.password.title")}>
              <p>{FDN.I18n.t("usersettings.security.form.password.intro")}</p>
              <div className="text-center">
                <button className="primary button" onClick={onPasswordPopupShow}>
                  <FDN.Icon icon="key" left />
                  {FDN.I18n.t("usersettings.security.form.password.button.label")}
                </button>
              </div>
            </FDN.FormGroup>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {popupChangePasswordVisible === true && (
        <PopupChangePassword actions={actions} onPasswordPopupClose={onPasswordPopupClose} />
      )}
    </>
  );
};

interface IPopupChangePassword {
  actions: TActions;
  onPasswordPopupClose: () => void;
}

const PopupChangePassword: React.FunctionComponent<IPopupChangePassword> = ({
  onPasswordPopupClose,
}) => {
  const [errorPasswordInvalid, setErrorPasswordInvalid] = React.useState(false);
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [errorPasswordTooShort, setErrorPasswordTooShort] = React.useState(false);
  const [errorNotEqual, setErrorNotEqual] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<IPasswordSetInputValues>({
    oldpassword: "",
    password1: "",
    password2: "",
  });

  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const api = new Api(APP, NOTIFICATIONS);

  const onEdit = (property: string, value: string) => {
    setInputValues({ ...inputValues, [property]: value });
  };

  const checkInput = () => {
    if (!inputValues.password1 || !inputValues.password2) {
      setErrorEmpty(true);
      return false;
    } else setErrorEmpty(false);

    if (inputValues.password1 !== inputValues.password2) {
      setErrorNotEqual(true);
      return false;
    } else setErrorNotEqual(false);

    const passwordMinLength = Config.get("auth.passwordMinLength") as number;

    if (inputValues.password1.length < passwordMinLength) {
      setErrorPasswordTooShort(true);
      return false;
    } else setErrorPasswordTooShort(false);

    return true;
  };

  const onSave = () => {
    if (!checkInput()) return false;

    UserApi.setPassword(api, inputValues.oldpassword as string, inputValues.password1).then(
      (response) => {
        if (response?.statusCode === StatusCode.INVALID_LOGIN_DATA) {
          setErrorPasswordInvalid(true);
        }
        if (response?.statusCode === StatusCode.ERROR) {
          NOTIFICATIONS.showNotification(
            "danger",
            FDN.I18n.t("usersettings.changePassword.onSave.error.title"),
            FDN.I18n.t("usersettings.changePassword.onSave.error.text")
          );
        } else if (response?.statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("usersettings.changePassword.onSave.success.title"),
            FDN.I18n.t("usersettings.changePassword.onSave.success.text")
          );

          onPasswordPopupClose();
        }
      }
    );
  };

  const formErrors = {
    "login.passwordInvalid": errorPasswordInvalid,
    "login.emptyInput": errorEmpty,
    "login.notEqual": errorNotEqual,
    "login.passwordTooShort": errorPasswordTooShort,
  };

  return (
    <FDN.Popup size="small" onClose={onPasswordPopupClose}>
      <h3>{FDN.I18n.t("usersettings.changePassword.popup.title")}</h3>
      <p>{FDN.I18n.t("usersettings.changePassword.popup.intro")}</p>
      <FDN.FormErrors errors={formErrors} />
      <PasswordSetForm
        oldpassword={inputValues.oldpassword}
        password1={inputValues.password1}
        password2={inputValues.password2}
        onEdit={onEdit}
      />
      <FDN.FormButtons
        onSave={onSave}
        saveLabel={FDN.I18n.t("passwordsetform.buttons.onSave.label")}
      />
    </FDN.Popup>
  );
};

export default TabSecurity;
