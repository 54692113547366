import * as React from "react";
import Topbar from "../topbar/Topbar";
import Master from "./Master";
import Footer from "./Footer";

interface ILayoutWithTopbarProps {
  page: string;
  children?: any;
  clientLogoUrl?: string;
}

const LayoutWithTopbar: React.FunctionComponent<ILayoutWithTopbarProps> = (props) => {
  return (
    <Master page={props.page}>
      <div className="__layout-with-topbar">
        <Topbar clientLogoUrl={props.clientLogoUrl} />
        {props.children}
      </div>
      <Footer />
    </Master>
  );
};

export default LayoutWithTopbar;
