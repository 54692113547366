import * as React from "react";
import * as FDN from "src/core";
import PrLayout from "./PrLayout";
import AdminHeader from "../admin/layouts/AdminHeader";
import PrClientLogo from "./parts/PrClientLogo";
import PrPdfForm from "./parts/PrPdfForm";
import PrClientAddress from "./parts/PrClientAddress";
import useServicePrPdf from "src/services/PrPdfService";
import PrInfoBlock from "../admin/prs/parts/PrInfoBlock";

const PrPdf: React.FunctionComponent = () => {
  const { pr, APP, client, categories, showSupplier, actions } = useServicePrPdf();

  if (!pr || !client) return <FDN.Loading />;

  return (
    <div className="__prs-view pr-pdf">
      <FDN.LayoutWithoutTopbar page="prs">
        <FDN.PageContent>
          <PrLayout>
            <div className="_prs-view-topline">
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={12} md={12} className="text-left">
                    <div className="_prs-view-topline-logo">
                      <img src={APP.getSetting("logoUrl") as string} alt="" />
                    </div>
                  </FDN.Cell>
                  <FDN.Cell sm={12} md={12} className="text-right">
                    <PrClientLogo client={client} />
                    <PrClientAddress client={client} />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </div>
            <AdminHeader title={`${FDN.I18n.t("pr.pr")} # ${pr.identifier}`} />

            <div className="flex justify-end -mt-2 mb-6">
              <div className="w-max text-right">
                <PrInfoBlock pr={pr} location="pdf" />
              </div>
            </div>

            {/* <FDN.Box noPadding>
              <div className="pr-pdf-infobox">
                <div className="pr-pdf-infobox-date">
                  <FDN.Icon icon="calendar" left /> {FDN.I18n.t("pr.date")}:{" "}
                  {FDN.formatDateTime(pr.requisitionDate, { useHumanReadableDate: false })}
                </div>
                <div className="pr-pdf-infobox-delivery">
                  <FDN.Icon icon="truck" left /> {FDN.I18n.t("pr.options.delivery.delivery.label")}:{" "}
                  {pr.desiredDeliveryType === "DESIRED" ? (
                    <>{FDN.formatDate(pr.desiredDeliveryDate)}</>
                  ) : (
                    <>{FDN.I18n.t(`pr.options.delivery.select.${pr.desiredDeliveryType}.label`)}</>
                  )}
                </div>
              </div>
            </FDN.Box> */}

            <PrPdfForm pr={pr} categories={categories} showSupplier={showSupplier} actions={actions} />
          </PrLayout>
        </FDN.PageContent>
      </FDN.LayoutWithoutTopbar>
    </div>
  );
};

export default PrPdf;
