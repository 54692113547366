import React, { useState } from "react";
import { AccountsSearch, FOnSearchCompleted, FOnSearchStarted } from "src/types/types";
import useServiceCore from "../CoreService";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import AdminApi from "src/api/AdminApi";
import { ApiResponse } from "src/api/Api";

const useServiceAdminListSearch = (
  searchType: string,
  defaultSearch: AccountsSearch,
  onSearchStarted: FOnSearchStarted,
  onSearchCompleted: FOnSearchCompleted
) => {
  const { APP, api } = useServiceCore();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<AccountsSearch>(cloneDeep(defaultSearch));
  //const [initialCallFinished, setInitialCallFinished] = useState(false);

  React.useEffect(() => {
    //
  }, []);

  React.useEffect(() => {
    lookForSearchParameterInTheUrl();
  }, [window.location.search]);

  const lookForSearchParameterInTheUrl = () => {
    const params = new URLSearchParams(window.location.search);

    let changesInParamsFound = false;
    const updatedSearch = cloneDeep(search);

    for (const searchKey of Object.keys(updatedSearch)) {
      const paramValue = params.get(searchKey);

      if (paramValue && paramValue !== updatedSearch[searchKey as keyof AccountsSearch]) {
        updatedSearch[searchKey as keyof AccountsSearch] = paramValue as string;
        changesInParamsFound = true;
      } else if (!paramValue && paramValue !== updatedSearch[searchKey as keyof AccountsSearch]) {
        updatedSearch[searchKey as keyof AccountsSearch] = "";
        changesInParamsFound = true;
      }
    }

    setSearch(updatedSearch);

    if (changesInParamsFound) doSearch(updatedSearch, true);
  };

  const updateSearch = (property: string, value: any) => {
    search[property as keyof AccountsSearch] = value;
    setSearch(cloneDeep(search));
  };

  const updateHistory = (specificSearch?: AccountsSearch) => {
    if (!specificSearch) specificSearch = search;

    const searchParams = new URLSearchParams(location.search);
    for (const searchKey of Object.keys(specificSearch))
      searchParams.set(searchKey, specificSearch[searchKey as keyof AccountsSearch]);

    navigate({ search: searchParams.toString() });
  };

  const doReset = () => {
    const resetSearch = cloneDeep(defaultSearch);
    setSearch(resetSearch);
    doSearch(resetSearch);
  };

  const doSearch = (specificSearch?: AccountsSearch, preventHistoryUpdate?: boolean) => {
    if (!specificSearch) specificSearch = search;

    // Update query url according to given search parameters
    // unless preventHistoryUpdate === true
    if (preventHistoryUpdate !== true) updateHistory(specificSearch);

    // Tell the parent component (e.g. <AdminAccounts /> resp. AccountsService) that
    // the search has started
    onSearchStarted();

    if (searchType === "adminAccounts")
      AdminApi.adminAccounts(api, specificSearch).then((response) => {
        handleUserUpdate(specificSearch, response);
        onSearchCompleted(response);
      });
  };

  const handleUserUpdate = (specificSearch?: AccountsSearch, response?: ApiResponse | null) => {
    const apicallUser = response?.body?.user;
    if (!apicallUser) return;

    APP.handleApiResponse({ user: apicallUser });

    if (apicallUser?.usersettings?.sortBy_adminaccounts && specificSearch) {
      const updatedSearch = cloneDeep(specificSearch);
      updatedSearch.sort = apicallUser?.usersettings?.sortBy_adminaccounts;
      setSearch(updatedSearch);
      updateHistory(updatedSearch);
    }
  };

  const onSortSelect = (option: string) => {
    const updatedSearch = cloneDeep(search);
    updatedSearch.sort = option;
    setSearch(updatedSearch);
    doSearch(updatedSearch);
  };

  return { search, updateSearch, doReset, doSearch, onSortSelect };
};

export default useServiceAdminListSearch;
