import Config from "src/core/Config";

export const hasAccessToAdminArea = (user: { [key: string]: any }, adminArea?: string): boolean => {
  if (!user || !user.permissions || !user.permissions.adminAreas) return false;

  if (!adminArea) return user.permissions.adminPermissions.includes("admin");
  else return user.permissions.adminAreas.includes(adminArea);
};

export const hasPermission = (permissions: string[], neededPermission: string): boolean => {
  if (!permissions) return false;
  return permissions.includes(neededPermission);
};

export const userHasPermission = (
  user: { [key: string]: any } | null | undefined,
  neededPermission: string
): boolean => {
  if (!user || !user.permissions || !user.permissions.adminPermissions) return false;

  return user.permissions.adminPermissions.includes(neededPermission);
};

export const userRolesRoleStatus = (
  userrole: { [key: string]: any },
  area: string
): "yes" | "partly" | "no" => {
  const permissions = Config.get(`admin.userroles.${area}`) as string[];

  if (!permissions) return "no";

  const totalPermissions = permissions.length;
  let userrolePermissionsCount = 0;

  for (const permission of permissions) {
    if (userrole.permissions.includes(permission)) userrolePermissionsCount++;
  }

  if (userrolePermissionsCount === totalPermissions) return "yes";
  else if (userrolePermissionsCount > 0 && userrolePermissionsCount < totalPermissions)
    return "partly";
  else return "no";
};

export const userHasPrPermission = (
  user: { [key: string]: any } | null | undefined,
  clientIdentifier: string,
  type: "manager" | "employee"
): boolean => {
  if (!user || !user.permissions) return false;

  if (type === "manager" && user.permissions.prManager) {
    const prManager = user.permissions.prManager as string[];
    if (prManager.includes(clientIdentifier)) return true;
    else return false;
  }

  if (type === "employee" && user.permissions.prEmployee) {
    const prEmployee = user.permissions.prEmployee as string[];
    if (prEmployee.includes(clientIdentifier)) return true;
    else return false;
  }

  return false;
};

// interface IActiveEditModeProps {
//   setEditMode?: Function | null;
//   tabs?: ITabs | null;
//   selectedTab?: string | null;
//   setSelectedTab?: Function | null;
//   editLockType?: string | null;
//   editLockIdentifier?: string | null;
//   api: Api;
//   NOTIFICATIONS: INotificationsContext;
// }
