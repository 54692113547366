import * as React from "react";
import * as FDN from "src/core";
import { IClient } from "src/types/clients.types";
import { IPurchaseRequisition } from "src/types/pr.types";

interface IPrClientLogoProps {
  client: IClient;
}

const PrClientLogo: React.FunctionComponent<IPrClientLogoProps> = ({ client }) => {
  return (
    <div className="__prs-clientlogo">
      {client.logoUrl ? <img src={client.logoUrl} alt={client.name} /> : null}
    </div>
  );
};

export default PrClientLogo;
