import * as React from "react";
import * as FDN from "src/core";
import useServiceAdminPrs from "src/services/admin/AdminPrsService";
import PrList from "./parts/PrList";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import PrSearch from "./parts/PrSearch";
import { PurchaseRequisitionsListTabTypes } from "src/types/pr.types";

const initTabs: FDN.ITabs = {
  active: {
    label: FDN.I18n.t("adminPr.list.tabs.active"),
    icon: <FDN.Icon icon="refresh" />,
  },
  completed: {
    label: FDN.I18n.t("adminPr.list.tabs.completed"),
    icon: <FDN.Icon icon="check" />,
  },
  canceled: {
    label: FDN.I18n.t("adminPr.list.tabs.canceled"),
    icon: <FDN.Icon icon="times" />,
  },
};

const AdminPrOverview: React.FunctionComponent = () => {
  const { tabs, selectedTab, setSelectedTab, prs, search, actions } = useServiceAdminPrs({ initTabs });

  if (!prs) return <FDN.Loading />;

  const filteredPrs = actions.getFilteredPrs();

  if (!filteredPrs || !tabs) return <FDN.Loading box />;

  return (
    <div className="__admin-prs">
      <AdminLayout page="admin-purchaserequisitions" selectedNav="purchaserequisitions">
        <AdminHeader title={FDN.I18n.t("adminPr.header.title")} />
        <FDN.Box>
          <PrSearch search={search} actions={actions} />
        </FDN.Box>
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          onSelect={(tabKey) => setSelectedTab(tabKey as PurchaseRequisitionsListTabTypes)}
        />
        <PrList prs={filteredPrs} />
      </AdminLayout>
    </div>
  );
};

export default AdminPrOverview;
