import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { SearchSort } from "src/types/types";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import Config from "src/core/Config";
import { IPurchaseRequisitions } from "src/types/pr.types";
import UrlService from "src/core/UrlService";
import PrStatus from "./PrStatus";
import { getTotalPositions } from "src/core/helpers/pr.helpers";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { sm: 12, md: 3 },
    textAlign: "left",
    content: FDN.I18n.t("pr.list.head.date.title"),
  },
  {
    size: { sm: 12, md: 5 },
    textAlign: "center",
    content: FDN.I18n.t("pr.list.head.prNr.title"),
  },
  {
    size: {
      md: 8,
    },
    content: FDN.I18n.t("pr.list.head.status.title"),
  },
  {
    size: { sm: 12, md: 3 },
    textAlign: "center",
    content: FDN.I18n.t("pr.list.head.positions.title"),
  },
  {
    size: { sm: 12, md: 5 },
    textAlign: "right",
    content: FDN.I18n.t("pr.list.head.priceTotal.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (prs: IPurchaseRequisitions) => {
  if (!prs) return;

  const currency = Config.get("currency") as string;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const pr of prs) {
    if (!pr) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url("purchaserequisitions.show", {
      clientIdentifier: pr.clientIdentifier,
      identifier: pr.identifier,
    });

    row.columns[0].content = <div className="pr-list-createdAt">{FDN.formatDateTime(pr.createdAt)}</div>;
    row.columns[1].content = <div className="pr-list-identifier"># {pr.identifier}</div>;
    row.columns[2].content = (
      <>
        <PrStatus pr={pr} location="overview" />
      </>
    );

    row.columns[3].content = (
      <div className="text-left md:text-center font-semibold md:font-normal">
        <span className="inline md:hidden">{FDN.I18n.t("pr.list.head.positions.title")}:</span> {getTotalPositions(pr)}
      </div>
    );

    row.columns[4].content = (
      <div className="pr-list-priceTotal text-right medium-text-right">
        {currency} {FDN.formatNumber(pr.priceTotal || 0)}
      </div>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IPrListProps {
  prs: IPurchaseRequisitions;
  sortLineLeftContent?: React.ReactNode;
  tabs?: React.ReactNode;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const PrList: React.FunctionComponent<IPrListProps> = ({
  prs,
  sortLineLeftContent,
  tabs,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (prs) {
      setAdminListSchema(buildAdminList(prs as IPurchaseRequisitions));
      setListLoading(false);
    }
  }, [prs]);

  if (!prs) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortLineLeftContent={sortLineLeftContent}
          tabs={tabs}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default PrList;
