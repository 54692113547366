import { Icon, Popup } from "@tschirpke/ptfdn-frontend-components";
import React from "react";
import { I18n } from "src/core";
import { classNames } from "src/core/helpers/design.helper";
import useServiceCore from "src/services/CoreService";
import { IFreeItem } from "src/types/pr.types";

interface FreeItemImagesPopupProps {
  freeItem: IFreeItem;
  onClose: () => void;
}

const FreeItemImagesPopup = ({ freeItem, onClose }: FreeItemImagesPopupProps) => {
  const [imageIndex, setImageIndex] = React.useState<number>(0);

  const { api } = useServiceCore();

  if (!freeItem.files || freeItem.files.length === 0) return null;

  const selectedFile = freeItem.files[imageIndex];
  const imageUrl: string | null = selectedFile.filetype?.startsWith("image")
    ? (api.getEndpoint("files.file", {
        identifier: selectedFile.identifier,
      }) as string)
    : null;

  return (
    <Popup size="small" title={freeItem.name} onClose={onClose}>
      <div className="flex justify-center item-center pb-3">
        <button
          className={classNames(
            "w-20 text-xl duration-200 text-primary hover:scale-110",
            imageIndex === 0 ? "opacity-10 cursor-not-allowed" : "cursor-pointer"
          )}
          onClick={() => {
            if (imageIndex > 0) setImageIndex(imageIndex - 1);
          }}
        >
          <Icon icon="chevron-left" />
        </button>
        <button
          className={classNames(
            "w-20 text-xl duration-200 text-primary hover:scale-110",
            imageIndex === freeItem.files.length - 1 ? "opacity-10 cursor-not-allowed" : "cursor-pointer"
          )}
          onClick={() => {
            if (freeItem.files && imageIndex < freeItem.files.length - 1) setImageIndex(imageIndex + 1);
          }}
        >
          <Icon icon="chevron-right" />
        </button>
      </div>
      <div className="flex justify-center items-center w-full md:max-w-5xl">
        {selectedFile.filetype?.startsWith("application/pdf") && (
          <div className="border borrder-gray-400 rounded-lg flex items-center justify-center p-4 text-sm h-44 w-64">
            {selectedFile.filename}
          </div>
        )}
        {selectedFile.filetype?.startsWith("image") && imageUrl && <img src={imageUrl} alt={freeItem.name} />}
      </div>
      <div className="pt-2 pb-6 flex justify-center">
        <a
          href={
            api.getEndpoint("files.file", {
              identifier: selectedFile.identifier,
            }) as string
          }
          target="_blank"
          rel="noreferrer"
          className="small primary button"
        >
          <Icon icon="download" left style={{ color: "white" }} />

          {I18n.t("pr.freeitems.form.images.buttons.download.label")}
        </a>
      </div>
      {freeItem.files.length > 1 && (
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          {freeItem.files.map((file, index) => {
            const style: React.CSSProperties = {};
            let label: string | null = null;

            if (file.filetype?.startsWith("image")) {
              style.backgroundImage = `url("${
                api.getEndpoint("files.file", {
                  identifier: file.identifier,
                }) as string
              }")`;
            } else if (file.filetype?.startsWith("application/pdf")) {
              label = file.filename || null;
            }

            return (
              <button key={index} className={classNames("block w-full h-24 mb-1")} onClick={() => setImageIndex(index)}>
                <div
                  className={classNames(
                    "w-full h-full border-2 rounded-lg bg-cover bg-center bg-no-repeat cursor-zoom-in duration-200 z-10 hover:border-primary hover:scale-105 grid items-center justify-center px-2 text-xs no-underline",
                    imageIndex === index ? "border-primary" : "border-gray-400"
                  )}
                  style={style}
                >
                  {label}
                </div>
              </button>
            );
          })}
        </div>
      )}
    </Popup>
  );
};

export default FreeItemImagesPopup;
