import { useEffect, useState } from "react";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { IPurchaseRequisitions, PrOverviewSearchProps, PurchaseRequisitionsListTabTypes } from "src/types/pr.types";
import { I18n, ITabs } from "src/core";
import { cloneDeep } from "lodash";

const initSearch: PrOverviewSearchProps = {
  text: "",
  client: "",
  sortBy: "createdAt_desc",
};

export interface TActionsAdminPrOverview {
  getFilteredPrs: () => IPurchaseRequisitions | undefined | false;
  updateSearch: <P extends keyof PrOverviewSearchProps, V extends PrOverviewSearchProps[P]>(
    property: P,
    value: V
  ) => void;
  resetSearch: () => void;
}

const useServiceAdminPrs = ({ initTabs }: { initTabs: ITabs }) => {
  const [prs, setPrs] = useState<IPurchaseRequisitions>();
  const [search, setSearch] = useState<PrOverviewSearchProps>(cloneDeep(initSearch));

  const [badges, setBadges] = useState<Record<PurchaseRequisitionsListTabTypes, number>>({
    drafts: 0,
    active: 0,
    completed: 0,
    canceled: 0,
  });

  const [tabs, setTabs] = useState<ITabs>();
  const [selectedTab, setSelectedTab] = useState<PurchaseRequisitionsListTabTypes>("active");

  const { APP, api } = useServiceCore();

  useEffect(() => {
    fetchPrs(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const newTabs = cloneDeep(initTabs);

    for (const key in newTabs) {
      newTabs[key].badge = badges[key as PurchaseRequisitionsListTabTypes];
    }

    setTabs(newTabs);
  }, [badges]);

  const fetchPrs = (selectedTab: PurchaseRequisitionsListTabTypes) => {
    setPrs(undefined);
    AdminApi.getPrs(api, selectedTab).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const prs = response?.body?.prs as IPurchaseRequisitions;
        if (response?.body?.badges) setBadges(response.body.badges);

        setPrs(prs);
        APP.setPageTitle(I18n.t("adminPr.pageTitle"));
      }
    });
  };

  const getFilteredPrs = () => {
    if (!search || !prs) return prs;

    const filteredPrs: IPurchaseRequisitions = [];

    for (const pr of prs) {
      let addToList = true;

      if (search.text) {
        const searchText = search.text.toLowerCase();

        if (
          !pr.client?.name?.toLowerCase().includes(searchText) &&
          !pr.client?.clientNr?.toLowerCase().includes(searchText) &&
          !pr.identifier?.toLowerCase().includes(searchText)
        )
          addToList = false;
      }

      if (pr.products) {
        const product = pr.products.find(
          (p) =>
            p.name?.toLowerCase().includes(search.text.toLowerCase()) ||
            p.productId?.toLowerCase().includes(search.text.toLowerCase())
        );
        if (product && product.amount > 0 && !filteredPrs.includes(pr)) addToList = true;
      }

      if (pr.freeItems) {
        for (const freeItem of pr.freeItems) {
          if (freeItem.name?.toLowerCase().includes(search.text.toLowerCase())) {
            if (freeItem.amount && freeItem.amount > 0 && !filteredPrs.includes(pr)) addToList = true;
            break;
          }
        }
      }

      if (addToList === true) filteredPrs.push(pr);
    }

    if (search.sortBy) return sortPrs(filteredPrs, search.sortBy);
    else return filteredPrs;
  };

  const sortPrs = (prs: IPurchaseRequisitions, sortBy: string): IPurchaseRequisitions => {
    const [sortByKey, sortByDirection] = sortBy.split("_");

    let property = "";
    if (sortByKey === "createdAt") property = "createdAt";
    else if (sortByKey === "clientNr") property = "clientNr";
    else if (sortByKey === "status") property = "status";
    else if (sortByKey === "priceTotal") property = "priceTotal";

    if (!property || (sortByDirection !== "asc" && sortByDirection !== "desc")) return prs;

    const sorted = prs.sort((a, b) => {
      if (property === "createdAt" && a.createdAt && b.createdAt) {
        if (sortByDirection === "asc") return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        else return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (property === "clientNr") {
        if (sortByDirection === "asc") return (a.client?.clientNr || "").localeCompare(b.client?.clientNr || "");
        else return (b.client?.clientNr || "").localeCompare(a.client?.clientNr || "");
      } else if (property === "status") {
        if (sortByDirection === "asc") return (a.status || "").localeCompare(b.status || "");
        else return (b.status || "").localeCompare(a.status || "");
      } else if (property === "priceTotal") {
        if (sortByDirection === "asc") return (a.priceTotal || 0) - (b.priceTotal || 0);
        else return (b.priceTotal || 0) - (a.priceTotal || 0);
      } else return 1;
    });

    return sorted;
  };

  function updateSearch<P extends keyof PrOverviewSearchProps, V extends PrOverviewSearchProps[P]>(
    property: P,
    value: V
  ) {
    if (!search) return;
    const updatedSearch = cloneDeep(search);
    updatedSearch[property] = value;
    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    const updatedSearch = cloneDeep(initSearch);
    if (search.sortBy) updatedSearch.sortBy = search.sortBy;
    setSearch(updatedSearch);
  };

  const actions: TActionsAdminPrOverview = {
    getFilteredPrs,
    updateSearch,
    resetSearch,
  };

  return { tabs, selectedTab, setSelectedTab, prs, search, actions };
};

export default useServiceAdminPrs;
