import * as React from "react";
import * as FDN from "src/core";
import PrLayout from "./PrLayout";
import AdminHeader from "../admin/layouts/AdminHeader";
import useServicePr from "src/services/PrService";
import { Link } from "react-router-dom";
import UrlService from "src/core/UrlService";
import PrClientLogo from "./parts/PrClientLogo";
import PrPreviewForm from "./parts/PrPreviewForm";
import { userHasPrPermission } from "src/core/AdminService/helpers";

const PrPreview: React.FunctionComponent = () => {
  const { pr, client, categories, prFormVisibleColumns, user, showSupplier, actions } =
    useServicePr();

  if (!pr || !client) return <FDN.Loading />;

  return (
    <div className="__prs-view">
      <FDN.LayoutWithTopbar page="prs">
        <FDN.PageContent>
          <PrLayout>
            <div className="_prs-view-topline">
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={24} md={12} className="text-left">
                    {userHasPrPermission(user, pr.clientIdentifier, "manager") ||
                    userHasPrPermission(user, pr.clientIdentifier, "employee") ? (
                      <>
                        <Link
                          className="primary hollow button"
                          to={UrlService.url("purchaserequisitions.show", {
                            clientIdentifier: client.identifier,
                            identifier: pr.identifier,
                          })}
                        >
                          <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.back")}
                        </Link>
                      </>
                    ) : null}
                  </FDN.Cell>
                  <FDN.Cell sm={24} md={12} className="text-right">
                    <PrClientLogo client={client} />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </div>
            <AdminHeader title={`${FDN.I18n.t("pr.pr")} # ${pr.identifier}`} />
            <PrPreviewForm
              pr={pr}
              categories={categories}
              prFormVisibleColumns={prFormVisibleColumns}
              showSupplier={showSupplier}
              actions={actions}
            />
            <div className="__prs-view-bottomline">
              <button className="large primary button" onClick={actions.onSend}>
                {FDN.I18n.t(`pr.buttons.send.label`)}
                <FDN.Icon icon="angle-double-right" right />
              </button>
            </div>
            <div>&nbsp;</div>
          </PrLayout>
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

export default PrPreview;
