import * as React from "react";
import * as FDN from "src/core";
import AdminHeader from "../layouts/AdminHeader";
import AdminLayout from "../layouts/AdminLayout";
import Config from "src/core/Config";
import useServiceAdminSettings from "src/services/admin/SettingsService";
import { SettingsState, TActions } from "src/types/types";
import { useNavigate } from "react-router-dom";

const defaultSelectedTab = "general";

const tabs: { [key: string]: FDN.ITabsTab } = {};

const AdminSettings: React.FunctionComponent = () => {
  const [selectedArea, setSelectedArea] = React.useState(defaultSelectedTab);

  const { actions, appI18n, settings, editMode } = useServiceAdminSettings();

  const navigate = useNavigate();

  const settingsAreas = Config.get("admin.settings.areas") as {
    [key: string]: any;
  };

  // Build tabs object based on settingsAreas from config file
  for (const settingsAreaKey of Object.keys(settingsAreas)) {
    tabs[settingsAreaKey] = {
      label: FDN.I18n.t(`adminSettings.nav.${settingsAreaKey}.title`),
      icon: <FDN.Icon icon="cog" />,
    };
  }

  if (!settings) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-settings" selectedNav="settings">
      <AdminHeader title={FDN.I18n.t("adminSettings.header.title")} />
      <FDN.Tabs
        updateHistory={true}
        editMode={editMode}
        tabs={tabs}
        selected={selectedArea}
        onSelect={(tabKey) => setSelectedArea(tabKey)}
        onUpdateUrl={(params) => navigate({ search: params })}
      />
      <FDN.TabsContent>
        {selectedArea === "general" && (
          <AreaGeneral settings={settings} editMode={editMode} actions={actions} />
        )}
        {selectedArea === "logo" && (
          <AreaLogo settings={settings} editMode={editMode} actions={actions} />
        )}
        {selectedArea === "language" && (
          <AreaLanguage
            settings={settings}
            editMode={editMode}
            appI18n={appI18n}
            actions={actions}
          />
        )}
        {selectedArea === "pr" && (
          <AreaPr settings={settings} editMode={editMode} actions={actions} />
        )}
        {selectedArea === "signup" && (
          <AreaSignup settings={settings} editMode={editMode} actions={actions} />
        )}
        {editMode === true && (
          <FDN.FormButtons onCancel={actions.onCancel} onSave={actions.onSave} />
        )}
      </FDN.TabsContent>
    </AdminLayout>
  );
};

interface IArea {
  settings: SettingsState;
  editMode: boolean;
  actions: TActions;
}

const AreaGeneral: React.FunctionComponent<IArea> = ({ settings, editMode, actions }) => {
  return (
    <>
      <h2>{FDN.I18n.t(`adminSettings.area.general.title`)}</h2>
      <FDN.FormGroup title={FDN.I18n.t(`adminSettings.area.general.title`)}>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "appName") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "defaultPageTitle") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "supportEmail") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
      </FDN.FormGroup>
      <FDN.FormGroup title={FDN.I18n.t(`adminSettings.area.general.dataprivacy.title`)}>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "showCookieBanner") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
      </FDN.FormGroup>
    </>
  );
};

const AreaLogo: React.FunctionComponent<IArea> = ({ settings, editMode, actions }) => {
  return (
    <>
      <h2>{FDN.I18n.t(`adminSettings.area.logo.title`)}</h2>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={8}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.FormGroup title={FDN.I18n.t(`adminSettings.setting.logoUrl.label`)}>
                <Setting
                  setting={settings?.find((s) => s.key === "logoUrl") || null}
                  editMode={editMode}
                  actions={actions}
                />
              </FDN.FormGroup>
            </FDN.SingleRowCell>
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.FormGroup title={FDN.I18n.t(`adminSettings.setting.logoLoginUrl.label`)}>
                <Setting
                  setting={settings?.find((s) => s.key === "logoLoginUrl") || null}
                  editMode={editMode}
                  actions={actions}
                />
              </FDN.FormGroup>
            </FDN.SingleRowCell>
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.FormGroup title={FDN.I18n.t(`adminSettings.setting.faviconUrl.label`)}>
                <Setting
                  setting={settings?.find((s) => s.key === "faviconUrl") || null}
                  editMode={editMode}
                  actions={actions}
                />
              </FDN.FormGroup>
            </FDN.SingleRowCell>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </>
  );
};

interface IAreaLanguage extends IArea {
  appI18n?: { [key: string]: any } | null;
}

const AreaLanguage: React.FunctionComponent<IAreaLanguage> = ({
  settings,
  appI18n,
  editMode,
  actions,
}) => {
  const setting = settings?.find((s) => s.key === "defaultLanguage");
  let defaultLanguage: string | null = null;
  if (setting && setting.value) defaultLanguage = setting.value;
  else defaultLanguage = null;

  if (!setting || !appI18n || !("availableLanguages" in appI18n)) return null;

  const { availableLanguages } = appI18n;

  return (
    <>
      <h2>{FDN.I18n.t(`adminSettings.area.language.title`)}</h2>
      <FDN.FormGroup title={FDN.I18n.t(`adminSettings.setting.generalLanguageSettings.title`)}>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "allowLanguageSwitch") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
      </FDN.FormGroup>
      <FDN.FormGroup title={FDN.I18n.t(`adminSettings.setting.defaultLanguage.label`)}>
        {editMode === false ? (
          <FDN.Input
            editable={false}
            editMode={false}
            value={`${availableLanguages[setting.value].icon} ${
              availableLanguages[setting.value].name
            }`}
            label={FDN.I18n.t(`adminSettings.setting.${setting.key}.label`)}
          />
        ) : (
          <LanguageSelector appI18n={appI18n} defaultLanguage={defaultLanguage} actions={actions} />
        )}
      </FDN.FormGroup>
    </>
  );
};

const AreaPr: React.FunctionComponent<IArea> = ({ settings, editMode, actions }) => {
  return (
    <>
      <h2>{FDN.I18n.t(`adminSettings.area.pr.title`)}</h2>
      <FDN.FormGroup title={FDN.I18n.t(`adminSettings.area.pr.title`)}>
        <FDN.SingleRowCell full margin="xy">
          <Setting
            setting={settings?.find((s) => s.key === "prPdfSendMailTo") || null}
            editMode={editMode}
            actions={actions}
          />
        </FDN.SingleRowCell>
      </FDN.FormGroup>
    </>
  );
};

const AreaSignup: React.FunctionComponent<IArea> = () => {
  return (
    <>
      <h2>{FDN.I18n.t(`adminSettings.area.signup.title`)}</h2>
    </>
  );
};

interface ISetting {
  setting?: { [key: string]: any } | null;
  editMode: boolean;
  actions: TActions;
}

const Setting: React.FunctionComponent<ISetting> = ({ setting, editMode, actions }) => {
  if (!setting) return null;

  let inputType: FDN.IInputTypes | "switch" | "squareimage" | "image" = "text";
  let uploadType = "";

  if (setting.type === "date") inputType = "date";
  else inputType = "text";

  if (["allowLanguageSwitch", "showCookieBanner"].includes(setting.key)) inputType = "switch";

  if (["logoUrl", "logoLoginUrl"].includes(setting.key)) {
    inputType = "image";
    uploadType = "logo";
  }

  if (["faviconUrl"].includes(setting.key)) {
    inputType = "squareimage";
    uploadType = "logo";
  }

  if (inputType === "image") {
    return (
      <>
        <FDN.SingleImageUploadWithImageWrapper
          imageUrl={setting.value}
          uploadType={uploadType}
          editMode={editMode}
          onDelete={() => actions.onEdit(setting.key, "")}
          onUpdate={(imageUrl) => actions.onEdit(setting.key, imageUrl)}
        />
      </>
    );
  }

  if (inputType === "squareimage") {
    return (
      <>
        <FDN.SingleImageUploadWithImageWrapper
          cropAspect={1 / 1}
          imageUrl={setting.value}
          uploadType={uploadType}
          editMode={editMode}
          onDelete={() => actions.onEdit(setting.key, "")}
          onUpdate={(imageUrl) => actions.onEdit(setting.key, imageUrl)}
        />
      </>
    );
  }

  if (inputType === "switch") {
    return (
      <div>
        <FDN.Switch
          value={setting.value}
          editMode={editMode}
          label={FDN.I18n.t(`adminSettings.setting.${setting.key}.label`)}
          labelPosition="right"
          size="medium"
          onUpdate={(value) => actions.onEdit(setting.key, value)}
        />
      </div>
    );
  }

  return (
    <>
      <FDN.Input
        type={inputType}
        editable={true}
        editMode={editMode}
        value={setting.value}
        label={FDN.I18n.t(`adminSettings.setting.${setting.key}.label`)}
        placeholder={FDN.I18n.t(`adminSettings.setting.${setting.key}.placeholder`)}
        onUpdate={(value) => actions.onEdit(setting.key, value)}
      />
    </>
  );
};

interface ILanguageSelector {
  appI18n?: { [key: string]: any } | null;
  defaultLanguage: string | null;
  actions: TActions;
}

const LanguageSelector: React.FunctionComponent<ILanguageSelector> = ({
  appI18n,
  defaultLanguage,
  actions,
}) => {
  if (!defaultLanguage || !appI18n || !("availableLanguages" in appI18n)) return null;

  const { availableLanguages } = appI18n;

  return (
    <FDN.SingleRowCell>
      <select
        value={defaultLanguage}
        onChange={(e) => actions.onEdit("defaultLanguage", e.target.value)}
      >
        {Object.keys(availableLanguages).map((languageKey) => {
          return (
            <option value={languageKey} key={languageKey}>
              {availableLanguages[languageKey].icon} {availableLanguages[languageKey].name}
            </option>
          );
        })}
      </select>
    </FDN.SingleRowCell>
  );
};

export default AdminSettings;
