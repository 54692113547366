import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  PrFormSearchProps,
} from "src/types/pr.types";
import { IProductCategories } from "src/types/products.types";
import PrFormProductsList from "./PrFormProductsList";
import PrFormPriceTotal from "./PrFormPriceTotal";
import PrFormOptions from "./PrFormOptions";
import PrFreeItems from "./PrFreeItems";

interface IPrPreviewFormProps {
  pr: IPurchaseRequisition;
  categories?: IProductCategories;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrPreviewForm: React.FunctionComponent<IPrPreviewFormProps> = ({
  pr,
  prFormVisibleColumns,
  categories,
  showSupplier,
  actions,
}) => {
  const editMode = false;

  return (
    <div className="__pr-form">
      {!categories || categories.length === 0 ? (
        <p>
          <em>{FDN.I18n.t("pr.noProducts")}</em>
        </p>
      ) : (
        <>
          <PrFormProductsList
            location="client"
            products={actions.getProductsForList(undefined, true)}
            pr={pr}
            editMode={editMode}
            prFormVisibleColumns={prFormVisibleColumns}
            showSupplier={showSupplier}
            actions={actions}
          />

          <PrFreeItems
            location="client"
            pr={pr}
            editMode={editMode}
            prFormVisibleColumns={prFormVisibleColumns}
            showSupplier={showSupplier}
            actions={actions}
          />

          <PrFormPriceTotal pr={pr} />

          <PrFormOptions pr={pr} editMode={editMode} actions={actions} />
        </>
      )}
    </div>
  );
};

export default PrPreviewForm;
