import * as React from "react";
import * as FDN from "src/core";
import PrLayout from "../../prs/PrLayout";
import AdminHeader from "../../admin/layouts/AdminHeader";
import { userHasPrPermission } from "src/core/AdminService/helpers";
import { Link } from "react-router-dom";
import UrlService from "src/core/UrlService";
import useServicePrs from "src/services/PrsService";
import PrList from "../../prs/parts/PrList";
import useServiceAdminClients from "src/services/admin/AdminClientsService";
import PopupClientEdit from "../../admin/clients/parts/PopupClientEdit";
import PrClientLogo from "../../prs/parts/PrClientLogo";
import PrOverviewFilter from "../../prs/parts/PrOverviewFilter";
import { PurchaseRequisitionsListTabTypes } from "src/types/pr.types";

const initTabs: FDN.ITabs = {
  drafts: {
    label: FDN.I18n.t("adminPr.list.tabs.drafts"),
    icon: <FDN.Icon icon="pencil-square-o" />,
  },
  active: {
    label: FDN.I18n.t("adminPr.list.tabs.active"),
    icon: <FDN.Icon icon="refresh" />,
  },
  completed: {
    label: FDN.I18n.t("adminPr.list.tabs.completed"),
    icon: <FDN.Icon icon="check" />,
  },
  canceled: {
    label: FDN.I18n.t("adminPr.list.tabs.canceled"),
    icon: <FDN.Icon icon="times" />,
  },
};

interface IIndexPrOverviewProps {
  clientIdentifier?: string;
}

const IndexPrOverview: React.FunctionComponent<IIndexPrOverviewProps> = ({ clientIdentifier }) => {
  const { editClient, missingFields, actions } = useServiceAdminClients();
  const { tabs, selectedTab, setSelectedTab, APP, client, prs, filter, updateFilter, getFilteredPRs } = useServicePrs({
    initTabs,
    clientIdentifier,
  });
  const user = APP.getUser();

  if (!getFilteredPRs() || !tabs || !clientIdentifier || !client) return <FDN.Loading box />;

  return (
    <div className="__index-pr-overview">
      <div className="__pr-overview">
        <PrLayout>
          <FDN.Box noPadding>
            <div className="__prs-pr-topline">
              {client.name}, {client.street}, {client.zip} {client.city}
            </div>
            <div className="padding">
              <div className="text-right" style={{ paddingBottom: "20px" }}>
                <PrClientLogo client={client} />
              </div>

              <AdminHeader
                title={FDN.I18n.t("pr.header.title")}
                noMargin
                buttons={[
                  <>
                    {client && client.identifier && userHasPrPermission(user, clientIdentifier, "manager") ? (
                      <>
                        <span className="show-for-small-only">
                          <button className="tiny primary hollow button" onClick={() => actions.showPopupEdit(client)}>
                            <FDN.Icon icon="cogs" left /> {FDN.I18n.t("pr.buttons.access.label")}
                          </button>
                        </span>
                        <span className="hide-for-small-only">
                          <button className="small primary hollow button" onClick={() => actions.showPopupEdit(client)}>
                            <FDN.Icon icon="cogs" left /> {FDN.I18n.t("pr.buttons.access.label")}
                          </button>
                        </span>
                      </>
                    ) : null}
                  </>,
                  <>
                    {userHasPrPermission(user, clientIdentifier, "manager") ||
                    userHasPrPermission(user, clientIdentifier, "employee") ? (
                      <>
                        <span className="show-for-small-only">
                          <Link
                            to={UrlService.url("purchaserequisitions.show", {
                              clientIdentifier: clientIdentifier as string,
                              identifier: "new",
                            })}
                            className="tiny primary button"
                          >
                            <FDN.Icon icon="plus" left /> {FDN.I18n.t("pr.buttons.add.label")}
                          </Link>
                        </span>
                        <span className="hide-for-small-only">
                          <Link
                            to={UrlService.url("purchaserequisitions.show", {
                              clientIdentifier: clientIdentifier as string,
                              identifier: "new",
                            })}
                            className="small primary button"
                          >
                            <FDN.Icon icon="plus" left /> {FDN.I18n.t("pr.buttons.add.label")}
                          </Link>
                        </span>
                      </>
                    ) : null}
                  </>,
                ]}
              />
            </div>
            {/* <div className="padding border-t border-b">
              <PrOverviewFilter filter={filter} updateFilter={updateFilter} />
            </div> */}
            <div className="padding">
              {!prs ? (
                <FDN.Loading box />
              ) : (
                <>
                  <PrList
                    prs={getFilteredPRs()}
                    sortLineLeftContent={<PrOverviewFilter filter={filter} updateFilter={updateFilter} />}
                    tabs={
                      <div className="pb-2">
                        <FDN.Tabs
                          tabs={tabs}
                          selected={selectedTab}
                          onSelect={(tabKey) => setSelectedTab(tabKey as PurchaseRequisitionsListTabTypes)}
                        />
                      </div>
                    }
                  />

                  {getFilteredPRs().length === 0 && (
                    <>
                      <p className="text-center mt-4">
                        <em>{FDN.I18n.t("pr.noPrs")}</em>
                      </p>
                    </>
                  )}
                </>
              )}

              {editClient && (
                <PopupClientEdit
                  client={editClient}
                  missingFields={missingFields}
                  location="client"
                  actions={actions}
                />
              )}
            </div>
          </FDN.Box>
        </PrLayout>
      </div>
    </div>
  );
};

export default IndexPrOverview;
