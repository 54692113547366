import * as React from "react";
import { prStatus } from "src/components/pages/prs/parts/PrStatus";
import * as FDN from "src/core";
import useServiceAdminPr from "src/services/admin/AdminPrService";
import { IPurchaseRequisition, TActionsAdminPr } from "src/types/pr.types";

interface IPrDeliveryDateProps {
  pr: IPurchaseRequisition;
  adminActions: TActionsAdminPr;
}

const PrDeliveryDate: React.FunctionComponent<IPrDeliveryDateProps> = ({ pr, adminActions }) => {
  const { actions } = useServiceAdminPr(pr);

  return (
    <div className="__admin-prs-deliverydate">
      <FDN.Popup
        size="small"
        title={FDN.I18n.t("adminPr.deliverydate.popup.title")}
        onClose={adminActions.hideDeliveryDatePopup}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="date"
                value={pr.scheduledDeliveryDate ? new Date(pr.scheduledDeliveryDate) : undefined}
                showValue={
                  pr.scheduledDeliveryDate
                    ? FDN.formatDate(pr.scheduledDeliveryDate, { useHumanReadableDate: false })
                    : ""
                }
                editMode={true}
                label={FDN.I18n.t("adminPr.deliverydate.popup.title")}
                onUpdate={(value) => adminActions.onEditDeliveryDate(value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        {pr.informClientAboutDeliveryDate === true ? (
          <>
            <div>&nbsp;</div>
            <FDN.CalloutBox type="info">
              {FDN.I18n.t("adminPr.deliverydate.informClient.text")}
            </FDN.CalloutBox>
          </>
        ) : null}
        <div>&nbsp;</div>
        <FDN.FormButtons
          onCancel={adminActions.hideDeliveryDatePopup}
          onSave={adminActions.onUpdatePr}
        />
      </FDN.Popup>
    </div>
  );
};

export default PrDeliveryDate;
