import * as React from "react";
import * as FDN from "src/core";
import { EmailTemplateState, IEmailTemplatePlaceholder, TActions } from "src/types/types";
import EmailTemplatePlaceholders from "./EmailTemplatePlaceholders";

interface IEmailTemplateProps {
  emailTemplate: EmailTemplateState;
  language: string;
  placeholders?: IEmailTemplatePlaceholder;
  editMode: boolean;
  actions: TActions;
}

const EmailTemplate: React.FunctionComponent<IEmailTemplateProps> = ({
  emailTemplate,
  language,
  placeholders,
  editMode,
  actions,
}) => {
  const emailTemplateValue = emailTemplate[language];

  return (
    <div className="__admin-emailtemplates-emailtemplate">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.FormGroup
              title={FDN.I18n.t("adminEmailTemplates.form.formgroups.emailtemplate.title")}
            >
              <FDN.SingleRowCell full>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={emailTemplateValue.name}
                  label={FDN.I18n.t("adminEmailTemplates.form.name.label")}
                  onUpdate={(value) => actions.onEdit("name", language, value)}
                />
              </FDN.SingleRowCell>
            </FDN.FormGroup>
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={15}>
            <FDN.FormGroup title={FDN.I18n.t("adminEmailTemplates.form.formgroups.subject.title")}>
              <FDN.SingleRowCell full>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={emailTemplateValue.rawSubject}
                  label={FDN.I18n.t("adminEmailTemplates.form.subject.label")}
                  onUpdate={(value) => actions.onEdit("rawSubject", language, value)}
                />
              </FDN.SingleRowCell>
            </FDN.FormGroup>
            <FDN.FormGroup title={FDN.I18n.t("adminEmailTemplates.form.formgroups.body.title")}>
              <FDN.SingleRowCell full>
                <FDN.Input
                  type="htmlcode"
                  editable={true}
                  rows={20}
                  editMode={editMode}
                  value={emailTemplateValue.rawHtml}
                  label={FDN.I18n.t("adminEmailTemplates.form.body.label")}
                  onUpdate={(value) => actions.onEdit("rawHtml", language, value)}
                />
              </FDN.SingleRowCell>
            </FDN.FormGroup>
          </FDN.Cell>
          <FDN.Cell md={9}>
            <EmailTemplatePlaceholders
              placeholders={placeholders}
              identifier={emailTemplateValue.identifier}
              language={language}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default EmailTemplate;
