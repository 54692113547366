import React from "react";
import { AppContext } from "../components/context/AppContext/AppContext";
import { NotificationsContext } from "../components/context/NotificationsContext/NotificationsContext";
import Api from "../api/Api";

const useServiceCore = () => {
  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const api = new Api(APP, NOTIFICATIONS);

  return { APP, NOTIFICATIONS, api };
};

export default useServiceCore;
