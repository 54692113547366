import { isArray } from "lodash";
import config from "src/config/config.json";

export default class Config {
  /**
   * Get value from config/config.json
   *
   * @param key - dot notated config key
   * @returns value found in config.json or undefined
   */
  public static get(key: string): unknown {
    return this.resolveDotNotation(config, key);
  }

  /**
   * Helper for get()
   *
   * @param payload - object from config.json
   * @param key - dot notated key
   * @param i - iteration
   * @returns value found in config.json or undefined
   */
  public static resolveDotNotation(payload: { [key: string]: any }, key: string, i = 1): unknown {
    const keyParts: string[] = key.split(".");

    if (i <= keyParts.length && payload[keyParts[i - 1]] !== undefined)
      return this.resolveDotNotation(payload[keyParts[i - 1]], key, i + 1);
    else {
      /* If payload matches dot notated key => return payload, otherwise return undefined */
      if (i > keyParts.length) return payload;
      else return undefined;
    }
  }

  /**
   * Detect if Debug Mode is on
   * Set debug mode in config.json > environments
   *
   * @returns boolean
   */
  public static isDebug(): boolean {
    const environment: string | null = this.detectEnvironment();

    if (!environment) return false;

    const isDebug: boolean = this.get(`environments.${environment}.debug`) as boolean;

    if (isDebug === undefined) return false;

    try {
      return this.get(`environments.${environment}.debug`) as boolean;
    } catch {
      return false;
    }
  }

  /**
   * Detect the current environment (e.g. development, production etc.)
   * Depending on hosts given in config.json > environments
   *
   * @returns
   */
  public static detectEnvironment(): string | null {
    /* All available environments */
    const environments: string[] = ["development", "staging", "production"];

    /* Get current hostname (e.g. localhost or google.com) */
    const currentHost = window.location.hostname;

    /* Search current host in given hosts of environment */
    for (const environment of environments) {
      const hosts = this.get(`environments.${environment}.hosts`);
      if (isArray(hosts) && hosts.includes(currentHost)) return environment;
    }

    return null;
  }
}
