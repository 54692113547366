import * as React from "react";
import * as FDN from "src/core";
import { IPrProductStatusType, IPurchaseRequisition, PrFormVisibleColumns, TActionsPr } from "src/types/pr.types";
import PrFormFreeItemsList from "./PrFormFreeItemsList";

interface IPrFreeItemsProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFreeItems: React.FunctionComponent<IPrFreeItemsProps> = ({
  location,
  showOnlyStatus,
  pr,
  editMode,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-freeitems">
      <PrFormFreeItemsList
        showOnlyStatus={showOnlyStatus}
        location={location}
        freeItems={actions.getFreeItemsForList(showOnlyStatus)}
        pr={pr}
        editMode={editMode}
        prFormVisibleColumns={prFormVisibleColumns}
        hideHead={true}
        showSupplier={showSupplier}
        actions={actions}
      />
      {editMode === true && actions.getFreeItemsForList(showOnlyStatus).length > 0 && (
        <div className="__pr-freeitems-add">
          <button className="clickable-add-button" onClick={() => actions.onSelectFreeItem("new")}>
            <FDN.Icon icon="plus-circle" left /> {FDN.I18n.t("pr.freeitems.buttons.add.label")}
          </button>
        </div>
      )}
    </div>
  );
};

export default PrFreeItems;
