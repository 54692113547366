import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { SearchSort } from "src/types/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import Config from "src/core/Config";
import { IPurchaseRequisitions } from "src/types/pr.types";
import UrlService from "src/core/UrlService";
import PrStatus from "src/components/pages/prs/parts/PrStatus";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 3 },
    textAlign: "left",
    content: FDN.I18n.t("adminPr.list.head.date.title"),
  },
  {
    size: { md: 3 },
    textAlign: "center",
    content: FDN.I18n.t("adminPr.list.head.prNr.title"),
  },
  {
    size: {
      md: 6,
      lg: 6,
    },
    content: FDN.I18n.t("adminPr.list.head.client.title"),
  },
  {
    size: {
      md: 8,
      lg: 8,
    },
    content: FDN.I18n.t("adminPr.list.head.status.title"),
  },
  {
    size: { md: 4 },
    textAlign: "right",
    content: FDN.I18n.t("adminPr.list.head.priceTotal.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (prs: IPurchaseRequisitions) => {
  if (!prs) return;

  const currency = Config.get("currency") as string;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const pr of prs) {
    if (!pr) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url("admin.purchaserequisitions.show", {
      clientIdentifier: pr.clientIdentifier,
      identifier: pr.identifier,
    });

    row.columns[0].content = <>{FDN.formatDateTime(pr.createdAt)}</>;
    row.columns[1].content = <># {pr.identifier}</>;
    row.columns[2].content = (
      <>
        {pr.client ? (
          <>
            {pr.client.clientNr} | {pr.client.name}
          </>
        ) : null}
      </>
    );
    row.columns[3].content = (
      <>
        <PrStatus pr={pr} location="overview" />
      </>
    );

    row.columns[4].content = (
      <>
        {currency} {FDN.formatNumber(pr.priceTotal || 0)}
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IPrListProps {
  prs: IPurchaseRequisitions;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const PrList: React.FunctionComponent<IPrListProps> = ({
  prs,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (prs) {
      setAdminListSchema(buildAdminList(prs as IPurchaseRequisitions));
      setListLoading(false);
    }
  }, [prs]);

  if (!prs) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default PrList;
