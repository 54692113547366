import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminProducts } from "src/services/admin/AdminProductsService";
import { IProductCategory } from "src/types/products.types";

interface IPopupProductCategoryEditProps {
  category: IProductCategory;
  actions: TActionsAdminProducts;
}

const PopupProductCategoryEdit: React.FunctionComponent<IPopupProductCategoryEditProps> = ({
  category,
  actions,
}) => {
  return (
    <div className="__admin-products-popup-edit-category">
      <FDN.Popup
        size="small"
        title={
          category.identifier === "new"
            ? FDN.I18n.t("adminProducts.popup.category.title.new")
            : category.name
        }
        onClose={actions.hidePopupEditProduct}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell md={24}>
              <FDN.Input
                type="text"
                value={category.name}
                label={FDN.I18n.t("adminProducts.popup.category.form.name.label")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEdiCategory("name", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        <div>&nbsp;</div>
        <FDN.FormButtons
          onSave={actions.onSaveCategory}
          onCancel={actions.hidePopupEditCategory}
          onDelete={actions.onDeleteCategory}
        />
      </FDN.Popup>
    </div>
  );
};

export default PopupProductCategoryEdit;
