import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react"; // To avoid "react must be in scope" error in eslint

import "/node_modules/@tschirpke/ptfdn-frontend-components/src/styles.scss";
import "./stylesheets/app.scss";

import { AppContextProvider } from "./components/context/AppContext/AppContext";
import { NotificationsContextProvider } from "./components/context/NotificationsContext/NotificationsContext";

import routes from "./config/routes.json";

import IndexPage from "./components/pages/index/IndexPage";

import LoginPage from "./components/pages/auth/login/LoginPage";
import MyAccountPage from "./components/pages/user/MyAccountPage";
import Error404 from "./components/pages/errors/Error404";
import AdminDashboard from "./components/pages/admin/dashboard/AdminDashboard";
import AdminSettings from "./components/pages/admin/settings/AdminSettings";
import AdminUserRoles from "./components/pages/admin/userroles/AdminUserRoles";
import AdminAccounts from "./components/pages/admin/accounts/AdminAccounts";
import AdminAccount from "./components/pages/admin/accounts/AdminAccount";
import SignupPage from "./components/pages/auth/signup/SignupPage";
import WelcomePage from "./components/pages/auth/welcome/WelcomePage";
import PasswordResetPage from "./components/pages/auth/passwordreset/PasswordResetPage";
import PasswordForgottenPage from "./components/pages/auth/passwordreset/PasswordForgottenPage";
import MySettingsPage from "./components/pages/user/MySettingsPage";
import DataPrivacyPage from "./components/pages/content/dataprivacy/DataPrivacyPage";
import LegalPage from "./components/pages/content/legal/LegalPage";
import { SocketContextProvider } from "./components/context/SocketContext/SocketContext";
import AdminEmailTemplates from "./components/pages/admin/emailtemplates/AdminEmailTemplates";
import AdminEmailTemplate from "./components/pages/admin/emailtemplates/AdminEmailTemplate";
import AdminProducts from "./components/pages/admin/products/AdminProducts";
import PrOverview from "./components/pages/prs/PrOverview";
import PrView from "./components/pages/prs/PrView";
import PrPreview from "./components/pages/prs/PrPreview";
import AdminClients from "./components/pages/admin/clients/AdminClients";
import PrPdf from "./components/pages/prs/PrPdf";
import AdminPrOverview from "./components/pages/admin/prs/AdminPrOverview";
import AdminPrView from "./components/pages/admin/prs/AdminPrView";
import AdminSuppliers from "./components/pages/admin/suppliers/AdminSuppliers";

function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <SocketContextProvider>
          <BrowserRouter>
            <NotificationsContextProvider>
              <Routes>
                <Route path={routes.index} element={<IndexPage />} />

                <Route path={routes.auth.passwordforgotten} element={<PasswordForgottenPage />} />
                <Route path={routes.auth.passwordreset} element={<PasswordResetPage />} />
                <Route path={routes.auth.login} element={<LoginPage />} />

                <Route path={routes.auth.signup} element={<SignupPage />} />

                <Route path={routes.auth.welcome} element={<WelcomePage />} />

                <Route path={routes.user.myaccount} element={<MyAccountPage />} />
                <Route path={routes.user.settings} element={<MySettingsPage />} />

                <Route path={routes.purchaserequisitions.pdf} element={<PrPdf />} />
                <Route path={routes.purchaserequisitions.preview} element={<PrPreview />} />
                <Route path={routes.purchaserequisitions.show} element={<PrView />} />
                {/* <Route path={routes.purchaserequisitions.index} element={<PrOverview />} /> */}

                <Route path={routes.admin.dashboard.index} element={<AdminDashboard />} />

                <Route path={routes.admin.accounts.account} element={<AdminAccount />} />
                <Route path={routes.admin.accounts.index} element={<AdminAccounts />} />

                <Route path={routes.admin.userroles.show} element={<AdminUserRoles />} />

                <Route path={routes.admin.userroles.index} element={<AdminUserRoles />} />

                <Route path={routes.admin.settings.index} element={<AdminSettings />} />

                <Route path={routes.admin.emailtemplates.show} element={<AdminEmailTemplate />} />
                <Route path={routes.admin.emailtemplates.index} element={<AdminEmailTemplates />} />

                <Route path={routes.admin.products.category} element={<AdminProducts />} />
                <Route path={routes.admin.products.index} element={<AdminProducts />} />

                <Route path={routes.admin.suppliers.index} element={<AdminSuppliers />} />

                <Route path={routes.admin.purchaserequisitions.show} element={<AdminPrView />} />

                <Route
                  path={routes.admin.purchaserequisitions.index}
                  element={<AdminPrOverview />}
                />

                <Route path={routes.admin.clients.index} element={<AdminClients />} />

                <Route path={routes.legal} element={<LegalPage />} />
                <Route path={routes.dataprivacy} element={<DataPrivacyPage />} />

                <Route path={"*"} element={<Error404 />} />
              </Routes>
            </NotificationsContextProvider>
          </BrowserRouter>
        </SocketContextProvider>
      </AppContextProvider>
    </div>
  );
}

export default App;
