import * as React from "react";
import CookieBanner from "../dataprivacy/CookieBanner";
import GA4React from "ga-4-react";
import Config from "src/core/Config";
import LocalStorageService from "src/core/LocalStorageService";

interface IMasterProps {
  page: string;
  children?: any;
}

const Master: React.FunctionComponent<IMasterProps> = (props) => {
  React.useEffect(() => {
    let dataprivacyStatsAllowed = false;

    try {
      const savedCookieSettingsStr = LocalStorageService.get("COOKIEBANNER_SETTINGS");
      if (savedCookieSettingsStr) {
        const savedCookieSettings = JSON.parse(savedCookieSettingsStr);

        if (
          savedCookieSettings &&
          savedCookieSettings.stats &&
          savedCookieSettings.stats === true
        ) {
          dataprivacyStatsAllowed = true;
        }
      }
    } catch {
      //
    }

    const ga4id = Config.get("analytics.ga4id") as string;

    if (!Config.isDebug() && dataprivacyStatsAllowed === true && ga4id) {
      //Logger.info("ANALYTICS | Trying to initialize Google Analytics...", ga4id);

      const ga4react = new GA4React(ga4id);
      ga4react.initialize().then(
        (ga4) => {
          ga4.pageview("path");
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, []);

  return (
    <div className={`__page-${props.page}`}>
      {props.children}
      <CookieBanner />
    </div>
  );
};

export default Master;
