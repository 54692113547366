import * as React from "react";
import * as FDN from "src/core";
import Api from "src/api/Api";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { TActions, TFunction } from "src/types/types";
import { FileUploadFile } from "./types";
import StatusCode from "src/config/statuscodes";
import { Crop } from "react-image-crop";
import ImageDropArea from "./ImageDropArea";
import ImageCrop, { CropOptions } from "./ImageCrop";
import FilesApi from "src/api/FilesApi";

interface ISingleImageUploadProps {
  type?: "button" | "droparea";
  button?: React.ReactNode;
  crop?: Crop;
  cropOptions?: CropOptions;
  uploadType: string;
  onUploadFinished: (imageUrl: string) => void;
}

const SingleImageUpload: React.FunctionComponent<ISingleImageUploadProps> = ({
  type,
  button,
  crop,
  cropOptions,
  uploadType,
  onUploadFinished,
}) => {
  if (!type) type = "button";

  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const api = new Api(APP, NOTIFICATIONS);

  const [showUploadPopup, setShowUploadPopup] = React.useState(false);
  const [showCropPopup, setShowCropPopup] = React.useState(false);
  const [showUploadingPopup, setShowUploadingPopup] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<FileUploadFile>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const onShowUploadPopup: TFunction = () => {
    setShowUploadPopup(true);
  };

  const onClosePopup: TFunction = () => {
    if (!unsavedChanges || window.confirm(FDN.I18n.t("main.form.buttons.onCancel.confirm"))) {
      setShowUploadPopup(false);
      setShowCropPopup(false);
    }
  };

  const onSelectFiles: TFunction = (files: FileUploadFile[]) => {
    if (files.length > 0) {
      const selectedFile = files[0];
      if (crop) selectedFile.crop = crop;
      if (cropOptions) selectedFile.cropOptions = cropOptions;
      setSelectedFile(selectedFile);

      setShowUploadPopup(false);
      setShowCropPopup(true);
      setUnsavedChanges(true);
    }
  };

  const onImageCrop: TFunction = (file: FileUploadFile) => {
    setSelectedFile(file);
  };

  const onUploadFile: TFunction = () => {
    if (!selectedFile) return;

    setShowUploadingPopup(true);

    FilesApi.uploadFiles(api, uploadType, [selectedFile])
      .then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS)
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("imageupload.onSave.single.success.title"),
            FDN.I18n.t("imageupload.onSave.single.success.text")
          );
        if (response?.body?.urls) {
          const urls: string[] = response.body.urls as string[];
          if (urls.length > 0) {
            // const imageUrl = api.getEndpoint("files.file", {
            //   identifier: files[0],
            // }) as string;
            const imageUrl = urls[0];

            onUploadFinished(imageUrl);

            setSelectedFile(undefined);
            setShowUploadPopup(false);
            setShowCropPopup(false);
            setShowUploadingPopup(false);
            setUnsavedChanges(false);
          }
        }
      })
      .catch((error) => {
        console.log("Error in SingleImageUpload", error);
      });
  };

  const actions = {
    onShowUploadPopup,
    onClosePopup,
    onSelectFiles,
    onImageCrop,
    onUploadFile,
  };

  if (type === "button") {
    const buttonWrapper = <span onClick={actions.onShowUploadPopup}>{button}</span>;

    return (
      <div className="__imageupload single-imageupload single-imageupload-button">
        {button ? (
          buttonWrapper
        ) : (
          <button className="primary button" onClick={actions.onShowUploadPopup}>
            {FDN.I18n.t("imageupload.button.upload.label")}
          </button>
        )}
        {showUploadPopup && <ImageUploadPopup actions={actions} />}
        {showCropPopup && selectedFile ? <ImageCropPopup file={selectedFile} actions={actions} /> : null}
        {showUploadingPopup && <ImageUploadingPopup />}
      </div>
    );
  }

  return null;
};

interface IImageUploadPopup {
  actions: TActions;
}

const ImageUploadPopup: React.FunctionComponent<IImageUploadPopup> = ({ actions }) => {
  return (
    <FDN.Popup size="small" onClose={actions.onClosePopup}>
      <h3>{FDN.I18n.t("imageupload.upload.popup.title")}</h3>
      <ImageDropArea type="single" accept={{ "image/*": [".png", ".jpg", ".jpeg", ".gif"] }} actions={actions} />
    </FDN.Popup>
  );
};

interface IImageCropPopup {
  file: FileUploadFile;
  actions: TActions;
}

const ImageCropPopup: React.FunctionComponent<IImageCropPopup> = ({ file, actions }) => {
  return (
    <FDN.Popup size="small" onClose={actions.onClosePopup}>
      <h3>{FDN.I18n.t("imageupload.crop.popup.title")}</h3>
      <div style={{ marginBottom: "5px" }}>
        <FDN.FormButtons
          onSave={actions.onUploadFile}
          saveLabel={FDN.I18n.t("imageupload.crop.single.onSave.button.label")}
          saveDisabled={!file.crop?.width || !file.crop?.height}
        />
      </div>
      <ImageCrop image={file} onCrop={actions.onImageCrop} />
      <FDN.FormButtons
        onSave={actions.onUploadFile}
        saveLabel={FDN.I18n.t("imageupload.crop.single.onSave.button.label")}
      />
    </FDN.Popup>
  );
};

const ImageUploadingPopup: React.FunctionComponent = () => {
  return (
    <FDN.Popup size="small" hideCloseButton>
      <div className="__imageupload-uploading-popup-content">
        <div className="__imageupload-uploading-popup-icon">
          <FDN.Icon icon="circle-o-notch fa-spin" />
        </div>
        <h3>{FDN.I18n.t("imageupload.uploading.popup.title")}</h3>
      </div>
    </FDN.Popup>
  );
};

export default SingleImageUpload;
