import React from "react";
import { ISignupInputValues, TActions } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import UrlService from "src/core/UrlService";
import EmailValidator from "email-validator";
import LocalStorageService from "src/core/LocalStorageService";
import AuthApi from "src/api/AuthApi";

const useServiceSignup = () => {
  const [signupEnabled, setSignupEnabled] = React.useState<boolean>();
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [errorInvalidEmail, setErrorInvalidEmail] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<ISignupInputValues>({
    email: "",
    firstname: "",
    lastname: "",
  });
  const [signupSuccess, setSignupSuccess] = React.useState(false);

  const { APP, api } = useServiceCore();

  const logoLoginUrl = APP.getSetting("logoLoginUrl");
  const settingSignupLastname = APP.getSetting("signupLastname");

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (signupEnabled === false) window.location.href = UrlService.url("auth.login");
  }, [signupEnabled]);

  const init = () => {
    api.get("hello").then((response) => {
      const settings = response?.app?.settings as { [key: string]: any };
      if (settings && "signupEnabled" in settings) {
        setSignupEnabled(settings.signupEnabled as boolean);
      }
    });
  };

  const onInput = (property: string, value: any): void => {
    setInputValues({ ...inputValues, [property]: value });

    if (property === "email") LocalStorageService.set("LOGIN_EMAIL", value);
  };

  const checkInput = () => {
    if (!inputValues.email || !inputValues.firstname) {
      setErrorEmpty(true);
      return false;
    } else setErrorEmpty(false);

    if (settingSignupLastname === "required" && !inputValues.lastname) {
      setErrorEmpty(true);
      return false;
    }

    if (!EmailValidator.validate(inputValues.email)) {
      setErrorInvalidEmail(true);
      return false;
    } else setErrorInvalidEmail(false);

    return true;
  };

  const attemptSignup = () => {
    if (!checkInput()) return false;

    AuthApi.attemptSignup(api, inputValues).then((response) => {
      if (response?.statusCode === StatusCode.EMAIL_EXISTS) {
        window.location.href = `${UrlService.url("auth.login")}?msg=emailexists`;
        return;
      }

      if (response?.statusCode === StatusCode.USER_CREATED) {
        setSignupSuccess(true);
      }
    });
  };

  const actions: TActions = {
    onInput,
    checkInput,
    attemptSignup,
  };

  return {
    actions,
    logoLoginUrl,
    settingSignupLastname,
    signupEnabled,
    errorEmpty,
    errorInvalidEmail,
    signupSuccess,
    inputValues,
  };
};

export default useServiceSignup;
