import * as React from "react";
import * as FDN from "src/core";
import {
  IAccountState,
  IUserPermissionsStatusState,
  TFunction,
  IUserrolesState,
} from "src/types/types";
import UserRolesList from "../../userroles/parts/UserRolesList";
import { cloneDeep } from "lodash";
import useServiceCore from "src/services/CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";

interface IAccountPermissionsPopupProps {
  account: IAccountState;
  userPermissionsStatus: IUserPermissionsStatusState;
  userroles: IUserrolesState;
  onClose: TFunction;
}

const AccountPermissionsPopup: React.FunctionComponent<IAccountPermissionsPopupProps> = ({
  account,
  userPermissionsStatus,
  userroles,
  onClose,
}) => {
  const [selectedUserroles, setSelectedUserroles] = React.useState<string[]>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const { api, NOTIFICATIONS } = useServiceCore();

  React.useEffect(() => {
    if (!selectedUserroles) setSelectedUserroles(cloneDeep(account.roles));
  }, [account]);

  const selectUserrole = (userroleKey: string) => {
    setSelectedUserroles(FDN.toggleArrayItem(userroleKey, selectedUserroles));
    setUnsavedChanges(true);
  };

  const onCancel = () => {
    const doCancel = () => {
      onClose();
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    AdminApi.updateAccountUserroles(api, account.identifier, selectedUserroles || []).then(
      (response) => {
        const statusCode = response?.statusCode as string;

        if (statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminAccounts.permissions.onSave.success.title"),
            FDN.I18n.t("adminAccounts.permissions.onSave.success.text")
          );

          window.location.reload();
        }
      }
    );
  };

  const actions = {
    selectUserrole,
    onCancel,
    onSave,
  };

  if (!account || !userPermissionsStatus || !userroles || !onClose || !selectedUserroles)
    return <FDN.Loading />;

  return (
    <FDN.Popup size="medium" onClose={onClose}>
      <div className="pretitle">{account.displayname}</div>
      <h3>{FDN.I18n.t("adminAccounts.permissions.popup.title")}</h3>

      <UserRolesList
        editable={true}
        selectable={true}
        selected={selectedUserroles}
        userroles={userroles}
        actions={actions}
      />
      <div>&nbsp;</div>
      <FDN.FormButtons smallButtons onCancel={actions.onCancel} onSave={actions.onSave} />
    </FDN.Popup>
  );
};

export default AccountPermissionsPopup;
