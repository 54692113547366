import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { getTotalPositions } from "src/core/helpers/pr.helpers";
import { IPurchaseRequisition } from "src/types/pr.types";

interface IPrFormPriceTotalProps {
  pr: IPurchaseRequisition;
}

const PrFormPriceTotal: React.FunctionComponent<IPrFormPriceTotalProps> = ({ pr }) => {
  const currency = Config.get("currency") as string;

  return (
    <div className="__pr-form-pricetotal">
      <FDN.Grid full>
        <FDN.Row margin="x">
          <FDN.Cell sm={12} md={6} mdo={12}>
            <div className="price-total">
              {FDN.I18n.t("pr.form.positions.total")}: {getTotalPositions(pr)}
            </div>
          </FDN.Cell>
          <FDN.Cell sm={12} md={6}>
            <div className="price-total">
              {FDN.I18n.t("pr.form.priceTotal.total")} {currency}{" "}
              {FDN.formatNumber(pr.priceTotal || 0)}
            </div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default PrFormPriceTotal;
