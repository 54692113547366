import React, { useEffect, useState } from "react";
import * as FDN from "src/core";
import {
  IPurchaseRequisition,
  PurchaseRequisitionStatus,
  TActionsAdminPr,
} from "src/types/pr.types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { cloneDeep } from "lodash";

export const prStatusOrder = [
  "NONE",
  "CREATED",
  "SENT",
  "PROCESS",
  "PLACED",
  "DELIVERY",
  "COMPLETED",
];

const useServiceAdminPr = (initialPr?: IPurchaseRequisition) => {
  const [popupStatusChangeVisible, setPopupStatusChangeVisible] = React.useState(false);
  const [popupDeliveryDateVisible, setPopupDeliveryDateVisible] = React.useState(false);

  const [pr, setPr] = useState<IPurchaseRequisition>();

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  useEffect(() => {
    if (initialPr) setPr(cloneDeep(initialPr));
  }, [initialPr]);

  const changeStatus = (newStatus: string, goingBack: boolean) => {
    if (!pr) return;

    const doChangeStatus = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      AdminApi.prChangeStatus(api, pr?.identifier, newStatus, goingBack).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          window.location.reload();
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminPr.statuschange.confirm", {
        newStatus: FDN.I18n.t(`pr.status.${newStatus}.label`),
      }),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            doChangeStatus();
          },
        },
      ],
    });
  };

  const getStatusBeforeCancelation = (): PurchaseRequisitionStatus => {
    if (!pr) return "CREATED";

    let statusBeforeCancelation: PurchaseRequisitionStatus = "CREATED";

    if (pr.statusDateSENT) statusBeforeCancelation = "SENT";
    if (pr.statusDatePROCESS) statusBeforeCancelation = "PROCESS";
    if (pr.statusDatePLACED) statusBeforeCancelation = "PLACED";
    if (pr.statusDateDELIVERY) statusBeforeCancelation = "DELIVERY";
    if (pr.statusDateCOMPLETED) statusBeforeCancelation = "COMPLETED";

    return statusBeforeCancelation;
  };

  const getCurrentStatus = () => {
    if (!pr) return "NONE";

    return pr.status;
  };

  const getPrevStatus = () => {
    if (!pr || pr.status === "CREATED") return "NONE";

    const indexOfCurrentStatus = prStatusOrder.indexOf(getCurrentStatus());
    if (
      indexOfCurrentStatus &&
      indexOfCurrentStatus > 0 &&
      prStatusOrder[indexOfCurrentStatus - 1]
    ) {
      return prStatusOrder[indexOfCurrentStatus - 1];
    } else return "NONE";
  };

  const getNextStatus = () => {
    if (!pr || pr.status === "COMPLETED") return "NONE";

    const indexOfCurrentStatus = prStatusOrder.indexOf(getCurrentStatus());
    if (
      indexOfCurrentStatus ||
      (indexOfCurrentStatus === 0 && prStatusOrder[indexOfCurrentStatus + 1])
    ) {
      return prStatusOrder[indexOfCurrentStatus + 1];
    } else return "NONE";
  };

  const showStatusChangePopup = () => {
    setPopupStatusChangeVisible(true);
  };

  const hideStatusChangePopup = () => {
    setPopupStatusChangeVisible(false);
  };

  const showDeliveryDatePopup = () => {
    setPopupDeliveryDateVisible(true);
  };

  const hideDeliveryDatePopup = () => {
    setPopupDeliveryDateVisible(false);
  };

  const onEditDeliveryDate = (value: Date | undefined) => {
    if (!pr) return;
    if (value) value.setHours(12);
    pr.scheduledDeliveryDate = value;
    setPr(cloneDeep(pr));
  };

  const onUpdatePr = () => {
    if (!pr) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.prUpdate(api, pr.identifier, pr).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const pr = response?.body?.pr as IPurchaseRequisition;
        setPr(pr);

        hideDeliveryDatePopup();

        NOTIFICATIONS.hideSaving();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminPr.onUpdate.success.title"),
          FDN.I18n.t("adminPr.onUpdate.success.text")
        );
      }
    });
  };

  const onCancelPr = () => {
    if (!pr) return;

    changeStatus("CANCELED", false);
  };

  const onUndoCancelPr = () => {
    if (!pr) return;

    const doUndoCancelPr = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      AdminApi.prChangeStatus(api, pr?.identifier, getStatusBeforeCancelation(), true).then(
        (response) => {
          if (response?.statusCode === StatusCode.SUCCESS) {
            window.location.reload();
          }
        }
      );
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminPr.onUndoCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            doUndoCancelPr();
          },
        },
      ],
    });
  };

  const actions: TActionsAdminPr = {
    showStatusChangePopup,
    hideStatusChangePopup,
    changeStatus,
    getCurrentStatus,
    getPrevStatus,
    getNextStatus,
    showDeliveryDatePopup,
    hideDeliveryDatePopup,
    onEditDeliveryDate,
    onUpdatePr,
    onCancelPr,
    onUndoCancelPr,
  };

  return { pr, popupStatusChangeVisible, popupDeliveryDateVisible, actions };
};

export default useServiceAdminPr;
