import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";

const Footer: React.FunctionComponent = () => {
  return (
    <footer>
      <FDN.SingleRowCell margin="xy">
        <span
          dangerouslySetInnerHTML={{
            __html: Config.get("footer.copyright") as string,
          }}
        />{" "}
        &middot;{" "}
        <a href="https://smaply.de/impressum/" target="_blank" rel="noopener noreferrer">
          {FDN.I18n.t("main.legal")}
        </a>{" "}
        &middot;{" "}
        <a href="https://smaply.de/datenschutz/" target="_blank" rel="noopener noreferrer">
          {FDN.I18n.t("main.dataprivacy")}
        </a>
      </FDN.SingleRowCell>
    </footer>
  );
};

export default Footer;
