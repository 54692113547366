import * as React from "react";
import * as FDN from "src/core";
import Api from "src/api/Api";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { IClients } from "src/types/clients.types";
import IndexPrOverview from "./parts/IndexPrOverview";
import UrlService from "src/core/UrlService";

const IndexPage: React.FunctionComponent = () => {
  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const [pageLoaded, setPageLoaded] = React.useState(false);

  const user = APP.getUser();

  React.useEffect(() => {
    /*const redirectFromIndexToLogin = Config.get("auth.redirectFromIndexToLogin") as boolean;
    if (redirectFromIndexToLogin === true) {
      window.location.href = UrlService.url("auth.login");
      return;
    }*/

    const api = new Api(APP, NOTIFICATIONS);
    api.get("hello").then((response) => {
      const user = response?.user;

      if (!user || !user.email) window.location.href = UrlService.url("auth.login");
      else setPageLoaded(true);
    });
  }, []);

  if (!pageLoaded || !user) return <FDN.Loading />;

  const userClients = user.clients as IClients;

  return (
    <FDN.LayoutWithTopbar page="index">
      <FDN.PageContent>
        <div className="index-welcome">
          {FDN.I18n.t("pr.index.welcome.text", { name: user.displayname })}
        </div>
        <div className="index-prs">
          {userClients.map((client) => {
            return (
              <React.Fragment key={client.identifier}>
                <IndexPrOverview clientIdentifier={client.identifier} />
              </React.Fragment>
            );
          })}
        </div>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default IndexPage;
