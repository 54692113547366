import * as React from "react";
import * as FDN from "src/core";
import { AccountsSearch, TActions } from "src/types/types";

interface IAccountSearchProps {
  search: AccountsSearch;
  actions: TActions;
}

const AccountSearch: React.FunctionComponent<IAccountSearchProps> = ({ search, actions }) => {
  return (
    <div className="__admin-accounts-search">
      <FDN.Box>
        <h3>{FDN.I18n.t("adminAccounts.search.title")}</h3>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                editable={true}
                editMode={true}
                value={search.text}
                label={FDN.I18n.t("adminAccounts.search.text.label")}
                placeholder={FDN.I18n.t("adminAccounts.search.text.placeholder")}
                onUpdate={(value) => actions.updateSearch("text", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        <FDN.FormButtons
          cancelIcon="times"
          cancelLabel={FDN.I18n.t("adminAccounts.search.resetButton.label")}
          saveIcon="search"
          saveLabel={FDN.I18n.t("adminAccounts.search.searchButton.label")}
          onCancel={actions.doReset}
          onSave={actions.doSearch}
        />
      </FDN.Box>
    </div>
  );
};

export default AccountSearch;
