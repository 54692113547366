import * as React from "react";
import { prStatus } from "src/components/pages/prs/parts/PrStatus";
import * as FDN from "src/core";
import useServiceAdminPr from "src/services/admin/AdminPrService";
import { IPurchaseRequisition, TActionsAdminPr } from "src/types/pr.types";

interface IPrStatusChangeProps {
  pr: IPurchaseRequisition;
  adminActions: TActionsAdminPr;
}

const PrStatusChange: React.FunctionComponent<IPrStatusChangeProps> = ({ pr, adminActions }) => {
  const { actions } = useServiceAdminPr(pr);

  const currentStatus = actions.getCurrentStatus();
  const currentStatusIcon = Object.keys(prStatus).includes(currentStatus)
    ? prStatus[currentStatus as keyof typeof prStatus].icon
    : "question";
  const currentLabel = `${FDN.I18n.t(`pr.status.${currentStatus}.label`)}`;

  const prevStatus = actions.getPrevStatus();
  //   const prevStatusIcon = Object.keys(prStatus).includes(prevStatus)
  //     ? prStatus[prevStatus as keyof typeof prStatus].icon
  //     : "question";
  const prevLabel = `${FDN.I18n.t("adminPr.statuschange.prev.label", {
    status: `<strong>${FDN.I18n.t(`pr.status.${prevStatus}.label`)}</strong>`,
  })}`;

  const nextStatus = actions.getNextStatus();
  //   const nextStatusIcon = Object.keys(prStatus).includes(nextStatus)
  //     ? prStatus[nextStatus as keyof typeof prStatus].icon
  //     : "question";
  const nextLabel = `${FDN.I18n.t("adminPr.statuschange.next.label", {
    status: `<strong>${FDN.I18n.t(`pr.status.${nextStatus}.label`)}</strong>`,
  })}`;

  return (
    <div className="__admin-prs-changestatus">
      <FDN.Popup
        size="medium"
        title={FDN.I18n.t("adminPr.statuschange.popup.title")}
        onClose={adminActions.hideStatusChangePopup}
      >
        <div className="__admin-prs-changestatus-currentstatus">
          <div className="__admin-prs-changestatus-currentstatus-title">
            {FDN.I18n.t("adminPr.statuschange.current.title")}:
          </div>
          <div className={`__pr-status-status-container status-${currentStatus}  done`}>
            <div className="__admin-prs-changestatus-currentstatus-status">
              <div className="__pr-status-status-bubble">
                <FDN.Icon icon={currentStatusIcon} />
              </div>
              <div className="__pr-status-status-label">{currentLabel}</div>
            </div>
          </div>
        </div>
        <div className="__admin-prs-changestatus-buttons">
          <div className="__admin-prs-changestatus-buttons-prevbutton">
            {prevStatus !== "NONE" && (
              <button
                className="primary hollow button"
                onClick={() => adminActions.changeStatus(prevStatus, true)}
              >
                <FDN.Icon icon="angle-double-left" left />
                <span dangerouslySetInnerHTML={{ __html: prevLabel }} />
              </button>
            )}
          </div>
          <div className="__admin-prs-changestatus-buttons-nextbutton">
            {nextStatus !== "NONE" && (
              <button
                className="primary button"
                onClick={() => adminActions.changeStatus(nextStatus, false)}
              >
                <span dangerouslySetInnerHTML={{ __html: nextLabel }} />
                <FDN.Icon icon="angle-double-right" right />
              </button>
            )}
          </div>
        </div>
      </FDN.Popup>
    </div>
  );
};

export default PrStatusChange;
