import * as React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";
import UrlService from "src/core/UrlService";
import UserButton from "./UserButton";
//import LanguageSwitchButton from "./LanguageSwitchButton";
//import NotificationsButton from "./NotificationsButton";
import * as FDN from "src/core";

interface ITopbarProps {
  clientLogoUrl?: string;
}

const Topbar: React.FunctionComponent<ITopbarProps> = ({ clientLogoUrl }) => {
  const APP = React.useContext(AppContext);

  return (
    <div className="__topbar">
      <div className="__topbar-content">
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={6} md={6} lg={6}>
              {clientLogoUrl ? (
                <div className="__topbar-logo block md:hidden">
                  <Link to={UrlService.url("index")}>
                    <img src={clientLogoUrl} alt="" style={{ marginTop: "3px" }} />
                  </Link>
                </div>
              ) : (
                <>&nbsp;</>
              )}
            </FDN.Cell>
            <FDN.Cell sm={12} md={12} lg={12}>
              <div className="__topbar-logo">
                <Logo imageUrl={APP.getSetting("logoUrl") as string} />
              </div>
            </FDN.Cell>
            <FDN.Cell sm={6} md={6} lg={6}>
              <div className="__topbar-right-side">
                <UserButton />
                {/*<LanguageSwitchButton />*/}
                {/*<NotificationsButton />*/}
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </div>
    </div>
  );
};

interface ILogoProps {
  imageUrl?: string;
}

const Logo: React.FunctionComponent<ILogoProps> = ({ imageUrl }) => {
  if (!imageUrl) return <>[LOGO]</>;
  return (
    <Link to={UrlService.url("index")}>
      <img src={imageUrl} alt="" />
    </Link>
  );
};

export default Topbar;
