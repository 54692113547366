import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminProducts } from "src/services/admin/AdminProductsService";
import { PrFormSearchProps, PrFormSortBy } from "src/types/pr.types";
import { IProductCategories } from "src/types/products.types";

const sortByValues: PrFormSortBy[] = [
  "unit_asc",
  "unit_desc",
  "productId_asc",
  "productId_desc",
  "productName_asc",
  "productName_desc",
  "productCategory_asc",
  "productCategory_desc",
  "priceUnit_asc",
  "priceUnit_desc",
];

interface IProductsSearchProps {
  search: PrFormSearchProps;
  categories: IProductCategories;
  actions: TActionsAdminProducts;
}

const ProductsSearch: React.FunctionComponent<IProductsSearchProps> = ({
  search,
  categories,
  actions,
}) => {
  return (
    <div className="__pr-form-search admin-products-search">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <input
              type="text"
              value={search.text || ""}
              onChange={(e) => actions.updateSearch("text", e.target.value)}
              placeholder={FDN.I18n.t("pr.search.text.placeholder")}
            />
          </FDN.Cell>

          <FDN.Cell sm={24} md={6}>
            <button
              className="primary hollow button"
              style={{ width: "100%" }}
              onClick={actions.resetSearch}
            >
              <FDN.Icon icon="refresh" left /> {FDN.I18n.t("pr.search.reset.label")}
            </button>
          </FDN.Cell>
          <FDN.Cell sm={24} md={6}>
            <select
              value={search.sortBy}
              onChange={(e) => {
                const value = e.target.value as PrFormSortBy | "none";
                if (value !== "none") actions.updateSearch("sortBy", value);
              }}
            >
              <option value="none" disabled>
                {FDN.I18n.t("pr.search.sort.label")}
              </option>
              {sortByValues.map((sortByValue) => (
                <option key={sortByValue} value={sortByValue}>
                  {FDN.I18n.t(`pr.search.sort.option.${sortByValue}.label`)}
                </option>
              ))}
            </select>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default ProductsSearch;
