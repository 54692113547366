import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { SearchSort } from "src/types/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { ISuppliers } from "src/types/products.types";
import { TActionsAdminSuppliers } from "src/services/admin/AdminSuppliersService";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 10 },
    content: FDN.I18n.t("adminSuppliers.list.head.name.title"),
  },
  {
    size: {
      md: 14,
    },
    content: FDN.I18n.t("adminSuppliers.list.head.contact.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (suppliers: ISuppliers, actions: TActionsAdminSuppliers) => {
  if (!suppliers) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const supplier of suppliers) {
    if (!supplier) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => actions.showPopupEditSupplier(supplier);

    row.columns[0].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">
            <strong>{supplier.name}</strong>
          </div>
          <div className="__adminlist-name-subtitle">
            {supplier.zip} {supplier.city}
          </div>
        </div>
      </>
    );

    row.columns[1].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">{supplier.contactName}</div>
          <div className="__adminlist-name-subtitle">
            <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
              {supplier.contactPhone && (
                <span>
                  <FDN.Icon icon="phone" left /> {supplier.contactPhone}
                </span>
              )}
              {supplier.contactEmail && (
                <span>
                  <FDN.Icon icon="envelope-o" left /> {supplier.contactEmail}
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface ISuppliersListProps {
  suppliers: ISuppliers;
  actions: TActionsAdminSuppliers;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const SuppliersList: React.FunctionComponent<ISuppliersListProps> = ({
  suppliers,
  actions,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (suppliers) {
      setAdminListSchema(buildAdminList(suppliers as ISuppliers, actions));
      setListLoading(false);
    }
  }, [suppliers]);

  if (!suppliers) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default SuppliersList;
