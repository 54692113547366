import * as React from "react";
import ImageDropArea from "src/components/main/ImageUpload/ImageDropArea";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IFreeItem, TActionsPr } from "src/types/pr.types";
import { IProductUnits } from "src/types/products.types";
import FreeItemImagesPopup from "./FreeItemImagesPopup";
import useServiceCore from "src/services/CoreService";

interface IPrFreeItemsPopupProps {
  freeItem: IFreeItem;
  missingFieldsFreeItem?: string[];
  productUnits: IProductUnits;
  actions: TActionsPr;
}

const PrFreeItemsPopup: React.FunctionComponent<IPrFreeItemsPopupProps> = ({
  freeItem,
  missingFieldsFreeItem,
  productUnits,
  actions,
}) => {
  const [imagesPopupVisible, setImagesPopupVisible] = React.useState<boolean>(false);
  const [newUnit, setNewUnit] = React.useState<string>();

  const { api } = useServiceCore();

  const selectValuesUnits: { [key: string]: string } = {
    none: "",
  };

  for (const productUnit of productUnits) {
    selectValuesUnits[`unit_${productUnit}`] = productUnit;
  }
  selectValuesUnits.add = FDN.I18n.t("adminProducts.popup.product.form.addUnit.label");

  return (
    <div className="__admin-products-popup-edit-product">
      <FDN.Popup
        size="small"
        title={freeItem.identifier === "new" ? FDN.I18n.t("pr.freeitems.title.new") : freeItem.name}
        onClose={actions.onCancelFreeItem}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24}>
              <FDN.Input
                type="text"
                value={freeItem.name}
                label={FDN.I18n.t("pr.freeitems.form.name.label")}
                isMissing={missingFieldsFreeItem?.includes("name")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditFreeItem("name", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell md={24}>
              <FDN.Input
                type="textarea"
                rows={4}
                value={freeItem.description}
                label={FDN.I18n.t("pr.freeitems.form.description.label")}
                isMissing={missingFieldsFreeItem?.includes("description")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditFreeItem("description", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={8} md={8}>
              <FDN.Input
                type="number"
                value={freeItem.amount ? freeItem.amount : "0"}
                label={FDN.I18n.t("pr.freeitems.form.amount.label")}
                isMissing={missingFieldsFreeItem?.includes("amount")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditFreeItem("amount", value);
                }}
              />
            </FDN.Cell>
            <FDN.Cell sm={16} md={16}>
              {newUnit === undefined ? (
                <FDN.Input
                  type="select"
                  selectValues={selectValuesUnits}
                  value={`unit_${freeItem.unit}`}
                  label={FDN.I18n.t("pr.freeitems.form.unit.label")}
                  isMissing={missingFieldsFreeItem?.includes("unit")}
                  editMode={true}
                  onUpdate={(value) => {
                    if (value === "add") setNewUnit("");
                    else actions.onEditFreeItem("unit", value.replace("unit_", ""));
                  }}
                />
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                  <div style={{ flexGrow: 1 }}>
                    <FDN.Input
                      type="text"
                      value={newUnit}
                      label={FDN.I18n.t("pr.freeitems.form.unit.label")}
                      isMissing={missingFieldsFreeItem?.includes("unit")}
                      editMode={true}
                      onUpdate={(value) => {
                        setNewUnit(value);
                        actions.onEditFreeItem("unit", value);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="clickable-text"
                      onClick={() => {
                        setNewUnit(undefined);
                        actions.onEditFreeItem("unit", "");
                      }}
                      style={{ lineHeight: "44px" }}
                    >
                      <FDN.Icon icon="times" />
                    </button>
                  </div>
                </div>
              )}
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="currency"
                options={{ currency: Config.get("currency") as string }}
                value={freeItem.priceUnit}
                label={FDN.I18n.t("pr.freeitems.form.priceUnit.label")}
                isMissing={missingFieldsFreeItem?.includes("priceUnit")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditFreeItem("priceUnit", value);
                }}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="currency"
                options={{ currency: Config.get("currency") as string }}
                value={freeItem.priceUnit && freeItem.amount ? freeItem.priceUnit * freeItem.amount : "0"}
                label={FDN.I18n.t("pr.freeitems.form.priceTotal.label")}
                isMissing={missingFieldsFreeItem?.includes("priceUnit")}
                editMode={false}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        <div>
          <div className="text-primary text-lg font-semibold mt-6 pb-2">
            {FDN.I18n.t("pr.freeitems.form.images.title")}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-4">
            {freeItem.files?.map((file, index) => {
              const style: React.CSSProperties = {};
              let label: string | null = null;

              if (file.filetype?.startsWith("image")) {
                style.backgroundImage = `url("${
                  api.getEndpoint("files.file", {
                    identifier: file.identifier,
                  }) as string
                }")`;
              } else if (file.filetype?.startsWith("application/pdf")) {
                label = file.filename || null;
              }

              return (
                <div key={index} className="relative">
                  <button
                    className="absolute -top-1.5 -right-1.5 bg-white ring-1 rounded-full w-6 h-6 text-red-500 cursor-pointer duration-200 hover:scale-110 z-20"
                    onClick={() => actions.onFreeItemRemoveFile(index)}
                  >
                    <FDN.Icon icon="times" />
                  </button>
                  <button
                    className="w-full h-24 mb-1 rounded-lg bg-cover bg-center bg-no-repeat cursor-zoom-in duration-200 z-10 hover:scale-105"
                    style={style}
                    onClick={() => setImagesPopupVisible(true)}
                  >
                    <div className="w-full h-full rounded-lg border border-gray-400 hover:border-primary flex items-center justify-center text-xs px-2">
                      {label}
                    </div>
                  </button>
                </div>
              );
            })}
            {freeItem.addedImages?.map((image, index) => {
              return (
                <div key={index} className="relative w-full h-24 mb-1">
                  <div className="w-full h-full grid grid-cols-1 text-center items-center rounded-lg bg-cover bg-center bg-no-repeat duration-200 border border-gray-400 text-gray-400 text-xl">
                    <FDN.Icon icon="circle-o-notch fa-spin" />
                  </div>
                </div>
              );
            })}
            <div className="w-full h-24">
              <ImageDropArea
                type="freeitems"
                accept={{ "image/*": [".png", ".jpg", ".jpeg", ".gif"], "application/*": [".pdf"] }}
                maxFiles={10}
                actions={{ onSelectFiles: actions.onFreeItemAddFile }}
              />
            </div>
          </div>
        </div>
        {imagesPopupVisible && <FreeItemImagesPopup freeItem={freeItem} onClose={() => setImagesPopupVisible(false)} />}
        <div>&nbsp;</div>
        <FDN.FormButtons
          onSave={actions.onSaveFreeItem}
          onCancel={actions.onCancelFreeItem}
          onDelete={freeItem.identifier === "new" ? undefined : () => actions.onDeleteFreeItem(freeItem.identifier)}
        />
      </FDN.Popup>
    </div>
  );
};

export default PrFreeItemsPopup;
