import * as React from "react";
import * as FDN from "src/core";
import AdminNavigation from "../navigation/AdminNavigation";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import EditLockMessage from "../editlock/EditLockMessage";

interface IAdminLayoutProps {
  page: string;
  selectedNav?: string;
  hideEditLockMessages?: boolean;
  children: any;
}

interface IAdminContentProps {
  children: any;
}

const AdminLayout: React.FunctionComponent<IAdminLayoutProps> = ({
  page,
  selectedNav,
  hideEditLockMessages,
  children,
}) => {
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  return (
    <FDN.LayoutWithTopbar page={page}>
      <main>
        <div className="__admin-wrapper">
          <AdminNavigation selected={selectedNav} />
          <AdminContent>
            {NOTIFICATIONS.editLock && !hideEditLockMessages ? (
              <EditLockMessage editLockStatus={NOTIFICATIONS.editLock} />
            ) : null}
            {children}
          </AdminContent>
        </div>
      </main>
    </FDN.LayoutWithTopbar>
  );
};

const AdminContent: React.FunctionComponent<IAdminContentProps> = ({ children }) => (
  <div className="__admin-content">{children}</div>
);

export default AdminLayout;
