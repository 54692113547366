import React, { useEffect, useState } from "react";
import * as FDN from "src/core";
import useServiceCore from "./CoreService";
import PrApi from "src/api/PrApi";
import StatusCode from "src/config/statuscodes";
import { I18n } from "src/core";
import { useParams, useSearchParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  IFreeItems,
  IPrProductStatus,
  IPrProductStatusType,
  IPurchaseRequisition,
  PrFormProductsView,
  PrFormSearchProps,
  PrFormVisibleColumns,
  TActionsPr,
} from "src/types/pr.types";
import { IProductCategories, IProducts } from "src/types/products.types";
import { IClient } from "src/types/clients.types";

const defaultPrFormVisibleColumns = [
  "amount",
  "productName",
  "unit",
  "productCategory",
  "supplier",
  "priceUnit",
  "priceTotal",
];

const useServicePrPdf = () => {
  const [searchParams] = useSearchParams();

  const [pr, setPr] = useState<IPurchaseRequisition>();
  const [categories, setCategories] = useState<IProductCategories>();

  const [client, setClient] = useState<IClient>();

  const [formProductsView, setFormProductsView] = useState<PrFormProductsView>("all");
  const [prFormVisibleColumns, setPrFormVisibleColumns] = useState<PrFormVisibleColumns>(defaultPrFormVisibleColumns);

  const [showSupplier, setShowSupplier] = useState(searchParams.get("suppliers") === "1");

  const params = useParams();
  const { identifier, clientIdentifier } = params;

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (!identifier) return;

    PrApi.getPrPdf(api, identifier, clientIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const pr = response?.body?.pr as IPurchaseRequisition;

        if (pr) {
          APP.setPageTitle(`${FDN.I18n.t("pr.pr")} # ${pr.identifier}`);

          setPr(pr);
        }

        const client = response?.body?.client as IClient;
        if (client) setClient(client);

        const categories = response?.body?.categories as IProductCategories;
        if (categories) setCategories(categories);
      }
    });
  };

  const getProductsForList = (showOnlyStatus?: IPrProductStatusType, showOnlySelected = false): IProducts => {
    if (showOnlyStatus) showOnlySelected = true;

    let tmpProducts: IProducts = [];

    if (!pr || !categories || !formProductsView) return tmpProducts;

    for (const category of categories) {
      if (category.products) {
        for (const product of category.products) {
          product.categoryName = category.name;
          if (formProductsView === "selected" || showOnlySelected === true) {
            if (pr.products?.find((p) => p.productIdentifier === product.identifier && p.amount > 0))
              tmpProducts.push(product);
          } else if (formProductsView === "all") tmpProducts.push(product);
        }
      }
    }

    tmpProducts = tmpProducts.filter((p) => {
      const prProduct = pr.products?.find((prp) => prp.productIdentifier === p.identifier);

      if (!prProduct) {
        if (formProductsView === "selected" || showOnlySelected === true) return false;
        else return true;
      }

      if (showOnlyStatus) {
        if (
          prProduct.status &&
          Object.keys(prProduct.status || {}).includes(showOnlyStatus) &&
          prProduct.status[showOnlyStatus] !== 0
        )
          return true;
      } else {
        if (prProduct.perfectAmount === 0) return false;
        if (!prProduct.status || (prProduct.status && prProduct.perfectAmount <= prProduct.amount)) return true;
      }
    });

    return tmpProducts;
  };

  const calculatePriceTotal = () => {
    if (!pr) return;

    let priceTotal = 0.0;

    if (pr.products) {
      for (const prProduct of pr.products) {
        priceTotal += prProduct.priceTotal;
      }
    }

    pr.priceTotal = priceTotal;
    setPr(cloneDeep(pr));
  };

  const getFreeItemsForList = (showOnlyStatus?: IPrProductStatusType): IFreeItems => {
    if (!pr || !pr.freeItems) return [];

    let tmpFreeItems: IFreeItems = [];

    tmpFreeItems = pr.freeItems.filter((f) => {
      if (showOnlyStatus) {
        if (f.status && Object.keys(f.status || {}).includes(showOnlyStatus) && f.status[showOnlyStatus] !== 0)
          return true;
      } else {
        if (f.perfectAmount === 0) return false;
        if (!f.status || (f.status && (f.perfectAmount || 0) <= (f.amount || 0))) return true;
      }
    });

    return tmpFreeItems;
  };

  const actions: TActionsPr = {
    getProductsForList,
    switchFormProductsView: (view: PrFormProductsView) => {
      //
    },
    updatePrProduct: (productIdentifier: string, amount: number | undefined | null) => {
      //
    },
    updateSearch: <P extends keyof PrFormSearchProps, V extends PrFormSearchProps[P]>(property: P, value: V) => {
      //
    },
    resetSearch: () => {
      //
    },
    updatePr: <P extends keyof IPurchaseRequisition, V extends IPurchaseRequisition[P]>(property: P, value: V) => {
      //
    },
    onDuplicate: () => {
      //
    },
    onArrived: () => {
      //
    },
    onStartEditing: () => {
      //
    },
    getFreeItemsForList,
    onSelectFreeItem: () => {
      //
    },
    onEditFreeItem: () => {
      //
    },
    onEditFreeItemAmount: () => {
      //
    },
    onFreeItemAddFile: () => {
      //
    },
    onFreeItemRemoveFile: () => {
      //
    },
    onCancelFreeItem: () => {
      //
    },
    onSaveFreeItem: () => {
      //
    },
    onDeleteFreeItem: () => {
      //
    },
    onCancel: () => {
      //
    },
    onSave: () => {
      //
    },
    onContinue: () => {
      //
    },
    onSend: () => {
      //
    },
    onCancelPr: () => {
      //
    },
  };

  return {
    APP,
    client,
    pr,
    categories,
    prFormVisibleColumns,
    showSupplier,
    actions,
  };
};

export default useServicePrPdf;
