import React from "react";
import * as FDN from "src/core";
import { IAccountState, EditedProperties } from "src/types/types";
import useServiceCore from "./CoreService";
import UserApi from "src/api/UserApi";
import StatusCode from "src/config/statuscodes";
import UrlService from "src/core/UrlService";

const useServiceMyAccount = () => {
  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const [account, setAccount] = React.useState<IAccountState | null>(null);
  const [editMode] = React.useState(true);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [editedProperties, setEditedProperties] = React.useState<EditedProperties>([]);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    UserApi.myAccount(api).then((response) => {
      APP.setPageTitle(FDN.I18n.t("myaccount.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setAccount(response?.body?.account as IAccountState);
      }
    });
  };

  const onEdit = (property: string, value: any) => {
    setAccount({ ...account, [property]: value });
    setUnsavedChanges(true);
    if (!editedProperties.includes(property)) setEditedProperties([...editedProperties, property]);
  };

  const onEditAddress = (identifier: string, property: string, value: any) => {
    if (!account) return;

    const address = account.addresses.find(
      (a: { [key: string]: any }) => a.identifier === identifier
    );

    address[property] = value;
    setAccount({ ...account });
  };

  const onSave = () => {
    if (!account) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    UserApi.saveMyAccount(api, account).then((response) => {
      if (response && response.statusCode) {
        if (response.statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("myaccount.onSave.success.title"),
            FDN.I18n.t("myaccount.onSave.success.text")
          );

          NOTIFICATIONS.hideSaving();

          setAccount(response?.body?.account as IAccountState);
        }
      }
    });
  };

  const onCancel = () => {
    const doCancel = () => (window.location.href = UrlService.url("index"));

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const actions = {
    init,
    onCancel,
    onEdit,
    onEditAddress,
    onSave,
  };

  return { actions, account, editMode, unsavedChanges, editedProperties };
};

export default useServiceMyAccount;
