import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import { userHasPermission } from "src/core/AdminService/helpers";
import useServiceAdminSuppliers from "src/services/admin/AdminSuppliersService";
import PopupSupplierEdit from "./parts/PopupSupplierEdit";
import SuppliersList from "./parts/SuppliersList";
import SuppliersSearch from "./parts/SuppliersSearch";

const AdminSuppliers: React.FunctionComponent = () => {
  const { suppliers, editSupplier, missingFields, search, actions, APP } =
    useServiceAdminSuppliers();
  const user = APP.getUser();

  if (!suppliers) return <FDN.Loading box />;

  return (
    <div className="__admin-suppliers">
      <AdminLayout page="admin-suppliers" selectedNav="suppliers">
        <AdminHeader
          title={FDN.I18n.t("adminSuppliers.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button
                  className="primary button"
                  onClick={() => actions.showPopupEditSupplier("new")}
                >
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminSuppliers.buttons.add.label")}
                </button>
              )}
            </>,
          ]}
        />
        <SuppliersSearch suppliers={suppliers} search={search} actions={actions} />

        <div className="__box-padding">
          <SuppliersList suppliers={actions.getFilteredSuppliers()} actions={actions} />
        </div>

        {editSupplier && (
          <PopupSupplierEdit
            supplier={editSupplier}
            missingFields={missingFields}
            actions={actions}
          />
        )}
      </AdminLayout>
    </div>
  );
};

export default AdminSuppliers;
