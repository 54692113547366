import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { formatDate, formatDateTime } from "src/core/helpers/helpers";
import UrlService from "src/core/UrlService";
import { IAccountsState, SearchSort } from "src/types/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 12,
    },
    content: FDN.I18n.t("adminAccounts.list.head.name.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="unlock-alt" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.access.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="sliders" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.admin.title"),
  },
  {
    size: {
      md: 5,
    },
    content: FDN.I18n.t("adminAccounts.list.head.lastvisit.title"),
    textAlign: "center",
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: "xy",
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (accounts: IAccountsState) => {
  if (!accounts) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const account of accounts) {
    if (!account) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url("admin.accounts.account", { identifier: account.identifier });

    row.columns[0].content = (
      <>
        <div className="__adminlist-avatar float-left">
          <FDN.Avatar user={account} size={38} />
        </div>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">{account.displayname}</div>
          <div className="__adminlist-name-subtitle">
            <div className="__adminlist-name-subtitle">
              <FDN.ToolTip tooltip={`${FDN.I18n.t("adminAccounts.list.userRoles")}`}>
                <FDN.Icon icon="list-alt" left />
                <AccountUserRolesNameList userRoleNames={account.userRoleNames} />
              </FDN.ToolTip>
            </div>
            <FDN.ToolTip
              tooltip={`${FDN.I18n.t("main.createdAt")} ${formatDateTime(account.createdAt)}`}
            >
              <FDN.Icon icon="plus-square-o" /> {formatDate(account.createdAt)}
            </FDN.ToolTip>{" "}
            {/*&nbsp;{" "}
            <ToolTip tooltip={`${FDN.I18n.t("main.updatedAt")} ${formatDateTime(account.updatedAt)}`}>
              <Icon icon="pencil-square-o" /> {formatDate(account.updatedAt)}
    </ToolTip>*/}
          </div>
        </div>
      </>
    );
    row.columns[1].content = (
      <FDN.StatusIndicator type="trafficlight" status={account.hasAccess ? "yes" : "no"} />
    );
    row.columns[2].content = (
      <FDN.StatusIndicator type="trafficlight" status={account.hasAdminAccess ? "yes" : "no"} />
    );
    row.columns[3].content = (
      <div className="center-vertically-horizontally">
        {account.lastVisit ? formatDate(account.lastVisit) : `-`}
      </div>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IAccountsListProps {
  accounts: IAccountsState;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const AccountsList: React.FunctionComponent<IAccountsListProps> = ({
  accounts,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (accounts) {
      setAdminListSchema(buildAdminList(accounts as IAccountsState));
      setListLoading(false);
    }
  }, [accounts]);

  if (!accounts) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

type TAccountUserRoleName = { [key: string]: any };
interface IAccountUserRolesNameListProps {
  userRoleNames: TAccountUserRoleName[];
}

const AccountUserRolesNameList: React.FunctionComponent<IAccountUserRolesNameListProps> = ({
  userRoleNames,
}) => {
  const language = FDN.I18n.getLanguage() as string;

  return (
    <>
      {userRoleNames &&
        userRoleNames.map((userRoleName, index) => {
          return (
            <React.Fragment key={index}>
              {userRoleName.name[language] || ""}
              {index + 1 < userRoleNames.length ? `, ` : ``}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default AccountsList;
