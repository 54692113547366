import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { PrFormVisibleColumns, TActionsPr, IPurchaseRequisition, IPrProductStatusType } from "src/types/pr.types";
import { IProducts } from "src/types/products.types";
import InputNumber from "../InputNumber";
import PrProductDropdownMenu from "../PrProductDropdownMenu";

interface IPrFormProductsListProps {
  showOnlyStatus?: IPrProductStatusType;
  location: "admin" | "client";
  pr: IPurchaseRequisition;
  products?: IProducts;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFormProductsListMobile: React.FunctionComponent<IPrFormProductsListProps> = ({
  showOnlyStatus,
  location,
  pr,
  products,
  editMode,
  showSupplier,
  actions,
}) => {
  const currency = Config.get("currency") as string;

  return (
    <div className="__pr-mobile-form-products">
      {products?.map((product, index) => {
        const prProduct = pr.products?.find((p) => p.productIdentifier === product.identifier);

        return (
          <div className="border rounded mb-3 bg-white" key={product.identifier}>
            <div className={`flex justify-between px-2 py-1 bg-slate-200 border-b ${showOnlyStatus && "opacity-50"}`}>
              <div className="flex-1">
                #{index + 1} <strong className="pl-1">{product.name}</strong>
              </div>
              <div className="w-24 text-right">{product.productId}</div>
            </div>
            <div className="px-2 py-1.5">
              <div className="text-right">
                {prProduct && location === "admin" && pr.status === "PROCESS" ? (
                  <>
                    <PrProductDropdownMenu
                      pr={pr}
                      product={prProduct}
                      productName={product.name || ""}
                      location={location}
                      menuItems={["NOT_IN_STOCK"]}
                    >
                      <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                        <FDN.Icon icon="ellipsis-h" />
                      </div>
                    </PrProductDropdownMenu>
                  </>
                ) : null}
                {prProduct &&
                location === "client" &&
                showOnlyStatus !== "NOT_IN_STOCK" &&
                (pr.status === "DELIVERY" || pr.status === "COMPLETED") ? (
                  <>
                    <PrProductDropdownMenu
                      pr={pr}
                      product={prProduct}
                      productName={product.name || ""}
                      location={location}
                      menuItems={["SHORTAGE", "QUALITY"]}
                    >
                      <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                        <FDN.Icon icon="ellipsis-h" />
                      </div>
                    </PrProductDropdownMenu>
                  </>
                ) : null}
              </div>
              <div className={`flex justify-between gap-2 ${showOnlyStatus && "opacity-50"}`}>
                <div className={`w-1/3`}>
                  {editMode ? (
                    <div className="is-editmode">
                      <InputNumber
                        view="input"
                        value={prProduct ? prProduct.amount : 0}
                        onUpdate={(value) => actions.updatePrProduct(product.identifier, value)}
                      />
                    </div>
                  ) : (
                    <div className="is-not-editmode text-center font-bold text-base pt-1">
                      {showOnlyStatus ? (
                        <>
                          {prProduct?.status && (
                            <>
                              {prProduct?.status && prProduct?.status[showOnlyStatus]
                                ? prProduct?.status[showOnlyStatus] || 0
                                : 0}{" "}
                              /{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {prProduct?.status && prProduct?.perfectAmount < prProduct?.amount && (
                            <>{prProduct?.perfectAmount || 0} / </>
                          )}
                        </>
                      )}
                      {prProduct?.amount || ""}
                    </div>
                  )}
                  <div className="py-1 text-center">
                    {product.amount} {product.unit}
                  </div>
                </div>
                <div className={`${editMode ? "w-1/3" : "hidden"}`}>
                  {editMode ? (
                    <div className="is-editmode">
                      <InputNumber
                        view="buttons"
                        value={prProduct ? prProduct.amount : 0}
                        onUpdate={(value) => actions.updatePrProduct(product.identifier, value)}
                      />
                    </div>
                  ) : (
                    <div className="is-not-editmode">&nbsp;</div>
                  )}
                </div>
                <div className={`${editMode ? "w-1/3" : "w-2/3"}`}>
                  <div className={`${editMode ? "py-1.5" : "py-1"} text-right ${showOnlyStatus && "hidden"}`}>
                    {FDN.I18n.t(`pr.form.list.head.priceUnit${editMode ? ".short" : ""}.label`)}:{" "}
                    {product.priceIsVariable ? (
                      <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
                        <span style={{ cursor: "help" }}>
                          {editMode ? "" : currency} {FDN.formatNumber(product.priceUnit || 0)} *
                        </span>
                      </FDN.ToolTip>
                    ) : (
                      <>
                        {editMode ? "" : currency}
                        {FDN.formatNumber(product.priceUnit || 0)}
                      </>
                    )}
                  </div>
                  <div
                    className={`${editMode ? "py-1.5" : "py-1"} text-right text-primary font-semibold ${
                      showOnlyStatus && "hidden"
                    }`}
                  >
                    {FDN.I18n.t(`pr.form.list.head.priceTotal${editMode ? ".short" : ""}.label`)}:{" "}
                    {product.priceIsVariable ? (
                      <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
                        <span style={{ cursor: "help" }}>
                          {editMode ? "" : currency}{" "}
                          {prProduct?.priceTotal ? FDN.formatNumber(prProduct?.priceTotal || 0) : "0,00"} *
                        </span>
                      </FDN.ToolTip>
                    ) : (
                      <>
                        {editMode ? "" : currency}{" "}
                        {prProduct?.priceTotal ? FDN.formatNumber(prProduct?.priceTotal || 0) : "0,00"}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showSupplier && product.suppliers && product.suppliers[0] ? (
              <div className={`px-2 py-1.5 bg-slate-50 border-t ${showOnlyStatus && "opacity-50"}`}>
                <div className="text-center text-xs">
                  {FDN.I18n.t("pr.form.list.head.supplier.label")}: {product.suppliers[0].name}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default PrFormProductsListMobile;
