import React, { useState } from "react";
import * as FDN from "src/core";
import { IAccountState, EditedProperties, TActions } from "src/types/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { showDefaultCancelDialog } from "src/components/main/Dialog/service";
import { userHasPermission } from "src/core/AdminService/helpers";

const editLockType = "account";

const getMenuItems = (account: IAccountState, actions: TActions, user: IAccountState) => {
  const MENUITEM_CONFIRMATION_MAIL = {
    label: FDN.I18n.t("adminAccounts.menu.resendconfirmationmail.label"),
    icon: "envelope-o",
    onClick: actions.confirmResendConfirmationMail,
  };

  const MENUITEM_PASSWORDFORGOTTEN = {
    label: FDN.I18n.t("adminAccounts.menu.passwordforgotten.label"),
    icon: "envelope-o",
    onClick: actions.confirmSendPasswordForgottenMail,
  };

  const MENUITEM_DELETE = {
    label: FDN.I18n.t("adminAccounts.menu.delete.label"),
    icon: "trash",
    onClick: actions.onDelete,
  };

  const menuItems: FDN.IDropdownButtonMenuItems = [];

  // Add confirmation mail resend button if user still has a signup token
  // otherwise add password forgotten button
  if (account.signupToken) menuItems.push(MENUITEM_CONFIRMATION_MAIL);
  else menuItems.push(MENUITEM_PASSWORDFORGOTTEN);

  // Add delete button if it's not the system user
  if (userHasPermission(user, "adminAccountsDelete") && !["system"].includes(account.username))
    menuItems.push(MENUITEM_DELETE);

  if (menuItems.length === 0) return undefined;

  return menuItems;
};

const useServiceAdminAccountService = () => {
  const [account, setAccount] = React.useState<IAccountState>();
  const [sendConfirmationMailAfterCreate, setSendConfirmationMailAfterCreate] = useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [editedProperties, setEditedProperties] = React.useState<EditedProperties>([]);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [menuItems, setMenuItems] = useState<FDN.IDropdownButtonMenuItems>();

  const params = useParams();
  const accountIdentifier = params.identifier as string;

  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (!account || !user) return;
    setMenuItems(getMenuItems(account, actions, user));
  }, [account]);

  const init = () => {
    AdminApi.adminAccount(api, accountIdentifier).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminAccounts.pageTitles.overview"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        const account = response?.body?.account as IAccountState;
        if (!account) return;

        setAccount(account);
        if (account.identifier === "new") {
          setEditMode(true);
          APP.setPageTitle(FDN.I18n.t("adminAccounts.addAccount.title"));
        } else {
          APP.setPageTitle(account.displayname);
        }
      }
    });
  };

  const confirmResendConfirmationMail = () => {
    if (window.confirm(FDN.I18n.t("adminAccounts.menu.resendconfirmationmail.confirm"))) {
      if (account)
        AdminApi.adminAccountResendConfirmationMail(api, account.identifier).then(() => {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminAccounts.menu.resendconfirmationmail.success.title"),
            FDN.I18n.t("adminAccounts.menu.resendconfirmationmail.success.text")
          );
        });
    }
  };

  const confirmSendPasswordForgottenMail = () => {
    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminAccounts.menu.passwordforgotten.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            if (account)
              AdminApi.adminAccountSendPasswordForgottenMail(api, account.identifier).then(() => {
                NOTIFICATIONS.showNotification(
                  "success",
                  FDN.I18n.t("adminAccounts.menu.passwordforgotten.success.title"),
                  FDN.I18n.t("adminAccounts.menu.passwordforgotten.success.text")
                );
              });
          },
        },
      ],
    });
  };

  const toggleEditMode = () => {
    if (editMode === true) {
      if (account && account.identifier)
        AdminApi.deleteEditLock(api, editLockType, account.identifier);
      setEditMode(false);
    } else {
      if (account && account.identifier) {
        AdminApi.requestEditLock(api, NOTIFICATIONS, editLockType, account.identifier).then(
          (result) => {
            if (result === StatusCode.EDITLOCK_OWN) setEditMode(true);
          }
        );
      }
    }
  };

  const onEdit = (property: string, value: any) => {
    if (!account) return;

    account[property] = value;
    if (!editedProperties.includes(property)) setEditedProperties([...editedProperties, property]);

    onUpdate(account);
  };

  const onEditAddress = (identifier: string, property: string, value: any) => {
    if (!account) return;

    const address = account.addresses.find(
      (a: { [key: string]: any }) => a.identifier === identifier
    );

    address[property] = value;
    onEdit("addresses", account.addresses);
  };

  const onUpdate = (account: IAccountState) => {
    setAccount(cloneDeep(account));
    setUnsavedChanges(true);
  };

  const onCancel = () => {
    const doCancel = () =>
      AdminApi.deleteEditLock(api, editLockType, account?.identifier).then(() =>
        window.location.reload()
      );

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onCancelNew = () => {
    const doConfirm = () => (window.location.href = UrlService.url("admin.accounts.index"));
    showDefaultCancelDialog(NOTIFICATIONS, unsavedChanges, doConfirm);
  };

  const onSave = () => {
    if (!account) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.updateAdminAccount(api, account, sendConfirmationMailAfterCreate).then((response) => {
      if (response && response.statusCode) {
        if (response.statusCode === StatusCode.SUCCESS) {
          if (account.identifier === "new") {
            const account = response?.body?.account as IAccountState;
            if (account && account.identifier)
              window.location.href = UrlService.url("admin.accounts.account", {
                identifier: account.identifier,
              });
            return;
          }
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminAccounts.onSave.success.title"),
            FDN.I18n.t("adminAccounts.onSave.success.text")
          );

          setAccount(response?.body?.account as IAccountState);
          setEditMode(false);
          setUnsavedChanges(false);
          NOTIFICATIONS.hideSaving();
        }
      }
    });
  };

  const onDelete = () => {
    if (window.confirm(FDN.I18n.t("adminAccounts.menu.delete.confirm"))) {
      AdminApi.adminDeleteAccount(api, accountIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          window.location.href = UrlService.url("admin.accounts.index");
        }
      });
    }
  };

  const actions: TActions = {
    confirmResendConfirmationMail,
    confirmSendPasswordForgottenMail,
    onEdit,
    onEditAddress,
    onUpdate,
    onCancel,
    onCancelNew,
    onSave,
    onDelete,
    toggleEditMode,
    setSendConfirmationMailAfterCreate,
  };

  return {
    actions,
    account,
    sendConfirmationMailAfterCreate,
    editedProperties,
    editMode,
    unsavedChanges,
    menuItems,
  };
};

export default useServiceAdminAccountService;
