import React, { useState } from "react";
import { IPurchaseRequisitions, PurchaseRequisitionsListTabTypes } from "../types/pr.types";
import useServiceCore from "./CoreService";
import PrApi from "src/api/PrApi";
import StatusCode from "src/config/statuscodes";
import { I18n, ITabs } from "src/core";
import { IClient } from "src/types/clients.types";
import { cloneDeep } from "lodash";

export interface PrsFilter {
  text: string;
}

interface IPrServiceProps {
  initTabs?: ITabs;
  clientIdentifier?: string;
}

const useServicePrs = ({ initTabs, clientIdentifier }: IPrServiceProps) => {
  const [prs, setPrs] = React.useState<IPurchaseRequisitions>();
  const [client, setClient] = useState<IClient>();

  const [badges, setBadges] = useState<Record<PurchaseRequisitionsListTabTypes, number>>({
    drafts: 0,
    active: 0,
    completed: 0,
    canceled: 0,
  });

  const [tabs, setTabs] = useState<ITabs>();
  const [selectedTab, setSelectedTab] = useState<PurchaseRequisitionsListTabTypes>("active");

  const [filter, setFilter] = useState<PrsFilter>({ text: "" });

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    fetchPrs(selectedTab);
  }, [selectedTab]);

  React.useEffect(() => {
    const newTabs = cloneDeep(initTabs);

    for (const key in newTabs) {
      newTabs[key].badge = badges[key as PurchaseRequisitionsListTabTypes];
    }

    setTabs(newTabs);
  }, [badges]);

  const fetchPrs = (selectedTab: PurchaseRequisitionsListTabTypes) => {
    setPrs(undefined);
    PrApi.getPrs(api, selectedTab, clientIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const prs = response?.body?.prs as IPurchaseRequisitions;
        if (prs) setPrs(prs);

        if (response?.body?.badges) setBadges(response.body.badges);

        const client = response?.body?.client as IClient;
        if (client) setClient(client);
      }

      APP.setPageTitle(I18n.t("pr.pageTitle"));
    });
  };

  const updateFilter = (property: string, value: any) => {
    setFilter({ ...filter, [property]: value });
  };

  const getFilteredPRs = (): IPurchaseRequisitions => {
    const filteredPRs: IPurchaseRequisitions = [];
    if (!prs) return filteredPRs;

    if (!filter || !filter.text) return prs;

    for (const pr of prs) {
      if (pr.products) {
        const product = pr.products.find(
          (p) =>
            p.name?.toLowerCase().includes(filter.text.toLowerCase()) ||
            p.productId?.toLowerCase().includes(filter.text.toLowerCase())
        );
        if (product && product.amount > 0 && !filteredPRs.includes(pr)) filteredPRs.push(pr);
      }

      if (pr.freeItems) {
        for (const freeItem of pr.freeItems) {
          if (freeItem.name?.toLowerCase().includes(filter.text.toLowerCase())) {
            if (freeItem.amount && freeItem.amount > 0 && !filteredPRs.includes(pr)) filteredPRs.push(pr);
            break;
          }
        }
      }
    }

    return filteredPRs;
  };

  return { tabs, selectedTab, setSelectedTab, APP, prs, client, filter, updateFilter, getFilteredPRs };
};

export default useServicePrs;
