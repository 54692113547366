import React from "react";
import { ILoginInputValues, TActions } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import LocalStorageService from "src/core/LocalStorageService";
import AuthApi from "src/api/AuthApi";
import UrlService from "src/core/UrlService";
import EmailValidator from "email-validator";
import Config from "src/core/Config";

const useServiceLogin = () => {
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [errorInvalidEmail, setErrorInvalidEmail] = React.useState(false);
  const [errorInvalidLoginData, setErrorInvalidLoginData] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<ILoginInputValues>({
    email: "",
    password: "",
  });

  const { APP, api } = useServiceCore();

  const logoLoginUrl = APP.getSetting("logoLoginUrl");
  const signupEnabled = APP.getSetting("signupEnabled") as boolean;

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    api.get("hello");

    if (LocalStorageService.get("LOGIN_EMAIL"))
      onInput("email", LocalStorageService.get("LOGIN_EMAIL"));
  };

  const onInput = (property: string, value: any): void => {
    setInputValues({ ...inputValues, [property]: value });

    if (property === "email") LocalStorageService.set("LOGIN_EMAIL", value);
  };

  const checkInput = () => {
    setErrorInvalidLoginData(false);

    if (!inputValues.email || !inputValues.password) {
      setErrorEmpty(true);
      return false;
    } else setErrorEmpty(false);

    if (!EmailValidator.validate(inputValues.email)) {
      setErrorInvalidEmail(true);
      return false;
    } else setErrorInvalidEmail(false);

    return true;
  };

  const attemptLogin = () => {
    if (!checkInput()) return false;

    AuthApi.attemptLogin(api, inputValues.email, inputValues.password).then((response) => {
      if (response?.statusCode === StatusCode.INVALID_LOGIN_DATA) {
        setErrorInvalidLoginData(true);
        return false;
      } else setErrorInvalidLoginData(false);

      if (response?.statusCode === StatusCode.LOGIN_SUCCESSFUL && response?.body?.token) {
        LocalStorageService.set("ACCESS_TOKEN", response.body.token);
        window.location.href = UrlService.url(
          Config.get("auth.redirectAfterLogin") as string
        ) as string;
      }
    });
  };

  const actions: TActions = {
    onInput,
    checkInput,
    attemptLogin,
  };

  return {
    actions,
    logoLoginUrl,
    signupEnabled,
    inputValues,
    errorEmpty,
    errorInvalidEmail,
    errorInvalidLoginData,
  };
};

export default useServiceLogin;
