import * as React from "react";
import * as FDN from "src/core";
import { IAccountState, EditedProperties, IMyAccountFormProps } from "src/types/types";
import { formatDateTime } from "src/core/helpers/helpers";
import AccountLoginAttempts from "../parts/AccountLoginAttempts";

interface IAccountInfoProps {
  account: IAccountState;
  editMode: boolean;
  onUpdate: (account: IAccountState) => void;
}

const AccountInfo: React.FunctionComponent<IAccountInfoProps> = ({
  account,
  editMode,
  onUpdate,
}) => {
  const [editedProperties, setEditedProperties] = React.useState<EditedProperties>([]);

  const onEdit = (property: string, value: any) => {
    if (!account) return;

    account[property] = value;
    if (!editedProperties.includes(property)) setEditedProperties([...editedProperties, property]);
    onUpdate(account);
  };

  const actions = {
    onEdit,
  };

  if (!account) return <FDN.Loading />;

  return (
    <div>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <FormGroupAccountInfo
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={12}>
            <FormGroupLoginAttempts
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

const FormGroupAccountInfo: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.accountinfo.title")}>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={false}
          editMode={editMode}
          type="text"
          value={formatDateTime(account.createdAt)}
          label={FDN.I18n.t("adminAccounts.form.createdAt.label")}
        />
      </FDN.SingleRowCell>
      {/*<SingleRowCell full margin="xy">
        <Input
          editable={false}
          editMode={editMode}
          type="text"
          value={formatDateTime(account.updatedAt)}
          label={FDN.I18n.t("adminAccounts.form.updatedAt.label")}
  />
  </SingleRowCell>*/}
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={false}
          editMode={editMode}
          type="text"
          value={formatDateTime(account.lastVisit)}
          label={FDN.I18n.t("adminAccounts.form.lastVisit.label")}
        />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupLoginAttempts: React.FunctionComponent<IMyAccountFormProps> = ({ account }) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.loginattempts.title")}>
      <FDN.SingleRowCell full margin="xy">
        <AccountLoginAttempts account={account} />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

export default AccountInfo;
