import * as React from "react";
import * as FDN from "src/core";

interface IAvatarUploadProps {
  account: { [key: string]: any };
  location: "myaccount" | "adminAccounts";
  size?: number;
  vertical?: boolean;
  onUpdate: (imageUrl: string) => void;
  onDelete: () => void;
}

const AvatarUpload: React.FunctionComponent<IAvatarUploadProps> = ({
  account,
  location,
  size,
  vertical,
  onUpdate,
  onDelete,
}) => {
  if (!size) size = 100;

  const onUploadFinished = (imageUrl: string) => {
    if (imageUrl && onUpdate) onUpdate(imageUrl);
  };

  const onConfirmDelete = () => {
    if (window.confirm(FDN.I18n.t(`${location}.avatarupload.onDelete.confirm`))) onDelete();
  };

  return (
    <div className={`__myaccount-avatar-upload ${vertical === true ? `vertical` : ``}`}>
      <FDN.GridRow full margin="xy">
        <FDN.Cell sm={24} md={vertical === true ? 24 : 12} className="__myaccount-avatar-upload-cell-upload">
          <FDN.SingleImageUpload
            button={
              <button className="small primary button">
                {FDN.I18n.t(`${location}.avatarupload.button.upload.label`)}
              </button>
            }
            crop={{
              unit: "px",
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            }}
            cropOptions={{
              aspect: 1 / 1,
              keepSelection: true,
            }}
            uploadType="avatar"
            onUploadFinished={onUploadFinished}
          />
          {account.avatar && (
            <button onClick={onConfirmDelete} className="clickable-text __myaccount-avatar-delete-button">
              {FDN.I18n.t(`${location}.avatarupload.button.delete.label`)}
            </button>
          )}
        </FDN.Cell>
        <FDN.Cell sm={24} md={vertical === true ? 24 : 12} className="__myaccount-avatar-upload-cell-avatar">
          <FDN.Avatar user={account} size={size} hideOnlineStatus={true} />
        </FDN.Cell>
      </FDN.GridRow>
    </div>
  );
};

export default AvatarUpload;
